import "../GlobalStyle.css";
import "../PCStyle.css";
import "../TabletStyle.css";
import "../MobileStyle.css";
import React from "react";
import { observer } from "mobx-react";
import axios from "axios";
import { ConvertKeysToLowercase } from "../Utils/ConvertStringFormat";
import { AddUnitYYYYMMDD, AddDotYYYYMMDD, AddHyphenTelNumber, AddCommaAmountNumber } from "../Utils/ConvertNumberFormat";
import { RemoveEmptyObjects } from "../Utils/OnChangeDocumentData";
import { HandleSalaryDocumentData } from "../Utils/OnChangeDocumentData";
import ContractDataTable from "../ChartTable/ContractDataTable";
import ContractSignTable from "../ChartTable/ContractSignTable";
import SalaryCompositionTable from "../ChartTable/SalaryCompositionTable";
import Icon from "../Assets/Icons/Icon";

const AnnualSalaryContract = observer(
  ({
    setAnnualSalaryContractInfo,
    inputCompanyName,
    companyRepresentName,
    companyAddress,
    setInputCompanyName,
    setCompanyRepresentName,
    setCompanyAddress,
    selectedEmployeeData,
    setSelectedEmployeeData,
    setSelectedEmployees,
    companyEmail,
    setIsLoading,
  }) => {
    const allInputFilled = () => {
      if (
        inputCompanyName.length > 0 &&
        companyRepresentName.length > 0 &&
        companyAddress.length > 0 &&
        selectedEmployeeData?.Birthday?.length === 8 &&
        selectedEmployeeData?.Address.length > 0 &&
        selectedEmployeeData?.Documents?.AnnualSalaryContract?.AnnualSalaryContractStartDate?.length === 8 &&
        selectedEmployeeData?.Documents?.AnnualSalaryContract?.AnnualSalaryContractEndDate?.length === 8 &&
        selectedEmployeeData?.Documents?.AnnualSalaryContract?.AnnualSalary?.length > 0 &&
        selectedEmployeeData?.Documents?.AnnualSalaryContract?.SalaryDay?.length > 0 &&
        selectedEmployeeData?.Documents?.AnnualSalaryContract?.ContractDate?.length === 8 &&
        selectedEmployeeData?.Documents?.AnnualSalaryContract?.SalaryInformation[0]?.SalaryCompositionTableTitle?.length > 0 &&
        selectedEmployeeData?.Documents?.AnnualSalaryContract?.SalaryInformation[0]?.SalaryCompositionTablePaymentAmount?.length > 0 &&
        selectedEmployeeData?.Documents?.AnnualSalaryContract?.SalaryInformation[0]?.SalaryCompositionTablePaymentStandard?.length > 0
      ) {
        return true;
      } else {
        return false;
      }
    };

    const getErrorMessage = () => {
      let errorMessage = "";
      if (!inputCompanyName.length > 0) {
        errorMessage += "- 상호명을 입력해주세요.";
      }
      if (!companyRepresentName.length > 0) {
        errorMessage += errorMessage ? "\n- 대표자명을 입력해주세요." : "- 대표자명을 입력해주세요.";
      }
      if (!companyAddress.length > 0) {
        errorMessage += errorMessage ? "\n- 기업/기관 주소를 입력해주세요." : "- 기업/기관 주소를 입력해주세요.";
      }
      if (selectedEmployeeData.Birthday.length !== 8) {
        errorMessage += errorMessage
          ? `\n- ${selectedEmployeeData?.Name} 님의 생년월일을 입력해주세요.`
          : `- ${selectedEmployeeData?.Name} 님의 생년월일을 입력해주세요.`;
      }
      if (!selectedEmployeeData?.Address?.length > 0) {
        errorMessage += errorMessage
          ? `\n- ${selectedEmployeeData?.Name} 님의 주소를 입력해주세요.`
          : `- ${selectedEmployeeData?.Name} 님의 주소를 입력해주세요.`;
      }
      if (selectedEmployeeData?.Documents?.AnnualSalaryContract?.AnnualSalaryContractStartDate?.length !== 8) {
        errorMessage += errorMessage ? "\n- 연봉계약 시작일을 입력해주세요." : "- 연봉계약 시작일을 입력해주세요.";
      }
      if (selectedEmployeeData?.Documents?.AnnualSalaryContract?.AnnualSalaryContractEndDate?.length !== 8) {
        errorMessage += errorMessage ? "\n- 연봉계약 종료일을 입력해주세요." : "- 연봉계약 종료일을 입력해주세요.";
      }
      if (!selectedEmployeeData?.Documents?.AnnualSalaryContract?.AnnualSalary?.length > 0) {
        errorMessage += errorMessage ? "\n- 연봉을 입력해주세요." : "- 연봉을 입력해주세요.";
      }

      if (!selectedEmployeeData?.Documents?.AnnualSalaryContract?.SalaryDay?.length > 0) {
        errorMessage += errorMessage ? "\n- 급여지급일을 입력해주세요." : "- 급여지급일을 입력해주세요.";
      }
      if (selectedEmployeeData?.Documents?.AnnualSalaryContract?.ContractDate?.length !== 8) {
        errorMessage += errorMessage ? "\n- 계약일을 입력해주세요." : "- 계약일을 입력해주세요.";
      }
      if (!selectedEmployeeData?.Documents?.AnnualSalaryContract?.SalaryInformation[0]?.SalaryCompositionTableTitle?.length > 0) {
        errorMessage += errorMessage
          ? "\n- 제 2 조의 임금구성표 '구분' 필수값을 입력해주세요. (표에 직접 입력)"
          : "- 제 2 조의 임금구성표 '구분' 필수값을 입력해주세요. (표에 직접 입력)";
      }
      if (!selectedEmployeeData?.Documents?.AnnualSalaryContract?.SalaryInformation[0]?.SalaryCompositionTablePaymentAmount?.length > 0) {
        errorMessage += errorMessage
          ? "\n- 제 2 조의 임금구성표 '월 지급액' 필수값을 입력해주세요. (표에 직접 입력)"
          : "- 제 2 조의 임금구성표 '월 지급액' 필수값을 입력해주세요. (표에 직접 입력)";
      }
      if (!selectedEmployeeData?.Documents?.AnnualSalaryContract?.SalaryInformation[0]?.SalaryCompositionTablePaymentStandard?.length > 0) {
        errorMessage += errorMessage
          ? "\n- 제 2 조의 임금구성표 '지급기준' 필수값을 입력해주세요. (표에 직접 입력)"
          : "- 제 2 조의 임금구성표 '지급기준' 필수값을 입력해주세요. (표에 직접 입력)";
      }
      return errorMessage;
    };

    const onChangeStateCompanyNameData = (value) => {
      setInputCompanyName(value);
      HandleSalaryDocumentData("Documents", value, "InputCompanyName", "AnnualSalaryContract", setSelectedEmployeeData, setSelectedEmployees);
    };

    const onChangeStateCompanyRepresentNameData = (value) => {
      setCompanyRepresentName(value);
      HandleSalaryDocumentData("Documents", value, "CompanyRepresentName", "AnnualSalaryContract", setSelectedEmployeeData, setSelectedEmployees);
    };

    const onChangeStateCompanyAddressData = (value) => {
      setCompanyAddress(value);
      HandleSalaryDocumentData("Documents", value, "CompanyAddress", "AnnualSalaryContract", setSelectedEmployeeData, setSelectedEmployees);
    };

    const onChangeStateBirthdayData = (value) => {
      HandleSalaryDocumentData("Birthday", value, null, "AnnualSalaryContract", setSelectedEmployeeData, setSelectedEmployees);
      HandleSalaryDocumentData("Documents", value, "Birthday", "AnnualSalaryContract", setSelectedEmployeeData, setSelectedEmployees);
    };

    const onChangeStateAddressData = (value) => {
      HandleSalaryDocumentData("Address", value, null, "AnnualSalaryContract", setSelectedEmployeeData, setSelectedEmployees);
      HandleSalaryDocumentData("Documents", value, "Address", "AnnualSalaryContract", setSelectedEmployeeData, setSelectedEmployees);
    };

    const onChangeContractDateData = (value) => {
      HandleSalaryDocumentData("Documents", value, "ContractDate", "AnnualSalaryContract", setSelectedEmployeeData, setSelectedEmployees);
      HandleSalaryDocumentData(
        "Documents",
        inputCompanyName,
        "InputCompanyName",
        "AnnualSalaryContract",
        setSelectedEmployeeData,
        setSelectedEmployees
      );
      HandleSalaryDocumentData(
        "Documents",
        companyRepresentName,
        "CompanyRepresentName",
        "AnnualSalaryContract",
        setSelectedEmployeeData,
        setSelectedEmployees
      );
      HandleSalaryDocumentData("Documents", companyAddress, "CompanyAddress", "AnnualSalaryContract", setSelectedEmployeeData, setSelectedEmployees);
      HandleSalaryDocumentData(
        "Documents",
        selectedEmployeeData?.Name,
        "Name",
        "AnnualSalaryContract",
        setSelectedEmployeeData,
        setSelectedEmployees
      );
      HandleSalaryDocumentData(
        "Documents",
        selectedEmployeeData?.Birthday,
        "Birthday",
        "AnnualSalaryContract",
        setSelectedEmployeeData,
        setSelectedEmployees
      );
      HandleSalaryDocumentData(
        "Documents",
        selectedEmployeeData?.TelNumber,
        "TelNumber",
        "AnnualSalaryContract",
        setSelectedEmployeeData,
        setSelectedEmployees
      );
      HandleSalaryDocumentData(
        "Documents",
        selectedEmployeeData?.Address,
        "Address",
        "AnnualSalaryContract",
        setSelectedEmployeeData,
        setSelectedEmployees
      );
    };

    const onClickConfirmInput = async () => {
      if (allInputFilled()) {
        setIsLoading(true);

        const selectedEmployeeDataCopy = { ...selectedEmployeeData };

        selectedEmployeeDataCopy.TelNumber = AddHyphenTelNumber(selectedEmployeeDataCopy?.TelNumber);
        selectedEmployeeDataCopy.Documents.AnnualSalaryContract.TelNumber = AddHyphenTelNumber(
          selectedEmployeeDataCopy?.Documents?.AnnualSalaryContract?.TelNumber
        );
        selectedEmployeeDataCopy.Documents.AnnualSalaryContract.Birthday = AddDotYYYYMMDD(
          selectedEmployeeDataCopy?.Documents?.AnnualSalaryContract?.Birthday
        );
        selectedEmployeeDataCopy.Documents.AnnualSalaryContract.AnnualSalary = AddCommaAmountNumber(
          selectedEmployeeDataCopy?.Documents?.AnnualSalaryContract?.AnnualSalary
        );
        selectedEmployeeDataCopy.Documents.AnnualSalaryContract.SalaryInformation[0].SalaryCompositionTablePaymentAmount = AddCommaAmountNumber(
          selectedEmployeeDataCopy?.Documents?.AnnualSalaryContract?.SalaryInformation[0]?.SalaryCompositionTablePaymentAmount
        );
        if (
          selectedEmployeeDataCopy?.Documents?.AnnualSalaryContract?.SalaryInformation[1]?.SalaryCompositionTablePaymentAmount &&
          selectedEmployeeDataCopy?.Documents?.AnnualSalaryContract?.SalaryInformation[1]?.SalaryCompositionTablePaymentAmount.length > 0
        ) {
          selectedEmployeeDataCopy.Documents.AnnualSalaryContract.SalaryInformation[1].SalaryCompositionTablePaymentAmount = AddCommaAmountNumber(
            selectedEmployeeDataCopy?.Documents?.AnnualSalaryContract?.SalaryInformation[1]?.SalaryCompositionTablePaymentAmount
          );
        }
        if (
          selectedEmployeeDataCopy?.Documents?.AnnualSalaryContract?.SalaryInformation[2]?.SalaryCompositionTablePaymentAmount &&
          selectedEmployeeDataCopy?.Documents?.AnnualSalaryContract?.SalaryInformation[2]?.SalaryCompositionTablePaymentAmount.length > 0
        ) {
          selectedEmployeeDataCopy.Documents.AnnualSalaryContract.SalaryInformation[2].SalaryCompositionTablePaymentAmount = AddCommaAmountNumber(
            selectedEmployeeDataCopy?.Documents?.AnnualSalaryContract?.SalaryInformation[2]?.SalaryCompositionTablePaymentAmount
          );
        }
        if (
          selectedEmployeeDataCopy?.Documents?.AnnualSalaryContract?.SalaryInformation[3]?.SalaryCompositionTablePaymentAmount &&
          selectedEmployeeDataCopy?.Documents?.AnnualSalaryContract?.SalaryInformation[3]?.SalaryCompositionTablePaymentAmount.length > 0
        ) {
          selectedEmployeeDataCopy.Documents.AnnualSalaryContract.SalaryInformation[3].SalaryCompositionTablePaymentAmount = AddCommaAmountNumber(
            selectedEmployeeDataCopy?.Documents?.AnnualSalaryContract?.SalaryInformation[3]?.SalaryCompositionTablePaymentAmount
          );
        }
        if (
          selectedEmployeeDataCopy?.Documents?.AnnualSalaryContract?.SalaryInformation[4]?.SalaryCompositionTablePaymentAmount &&
          selectedEmployeeDataCopy?.Documents?.AnnualSalaryContract?.SalaryInformation[4]?.SalaryCompositionTablePaymentAmount.length > 0
        ) {
          selectedEmployeeDataCopy.Documents.AnnualSalaryContract.SalaryInformation[4].SalaryCompositionTablePaymentAmount = AddCommaAmountNumber(
            selectedEmployeeDataCopy?.Documents?.AnnualSalaryContract?.SalaryInformation[4]?.SalaryCompositionTablePaymentAmount
          );
        }
        selectedEmployeeDataCopy.Documents.AnnualSalaryContract.SalaryInformation = RemoveEmptyObjects(
          selectedEmployeeDataCopy?.Documents?.AnnualSalaryContract?.SalaryInformation
        );

        setTimeout(async () => {
          try {
            const endPoint = "https://dml1u35aa3.execute-api.ap-northeast-2.amazonaws.com/product/pdf/salary-contract";
            const response = await axios.post(endPoint, {
              email: companyEmail,
              telNumber: selectedEmployeeDataCopy?.TelNumber,
              name: selectedEmployeeData?.Name,
              data: ConvertKeysToLowercase(selectedEmployeeDataCopy?.Documents?.AnnualSalaryContract),
              ...(selectedEmployeeData.NameDone ? {} : { generalDocument: true }),
            });
            if (response.data.message === "연봉계약서 생성 완료") {
              HandleSalaryDocumentData("Documents", response.data.url, "Url", "AnnualSalaryContract", setSelectedEmployeeData, setSelectedEmployees);
              setTimeout(async () => {
                setIsLoading(false);
                setAnnualSalaryContractInfo(false);
                alert("연봉 계약서 작성을 완료했습니다.");
              }, 100);
            } else {
              setIsLoading(false);
              setAnnualSalaryContractInfo(false);
              alert("연봉 계약서 작성에 실패하였습니다. 다시 한번 시도해 주세요.");
            }
          } catch (error) {
            console.log("AnnualSalaryContract ERROR : ", error);
            setIsLoading(false);
            setAnnualSalaryContractInfo(false);
            alert("연봉 계약서 작성에 실패하였습니다. 다시 한번 시도해 주세요.");
          }
        }, 100);
      } else {
        alert(`${getErrorMessage()}`);
      }
    };

    return (
      <div className="Signin_CompleteModal_BG">
        <div className="ContractInput_Modal_CT">
          <div className="ContractInput_Close_CT">
            <div className="CheckList_InfoModal_Title_Copy">연봉계약서</div>
            <div className="CheckList_InfoModal_Icon_CT">
              <button className="CheckList_Close_Button" onClick={() => setAnnualSalaryContractInfo(false)}>
                <Icon name={"Close"} size={"24"} />
              </button>
            </div>
          </div>
          <div className="ContractInput_Contents_CT">
            <div className="ContractInput_Left_CT">
              <div className="ContractInput_Left_Content_CT">
                <ContractDataTable
                  inputCompanyName={inputCompanyName}
                  companyRepresentName={companyRepresentName}
                  companyAddress={companyAddress}
                  employeeName={selectedEmployeeData?.Name}
                  employeeBirthday={selectedEmployeeData?.Birthday}
                  employeeAddress={selectedEmployeeData?.Address}
                  employeeTelNumber={selectedEmployeeData?.TelNumber}
                />

                <div className="ContractInput_Body_Copy" style={{ marginTop: "20px" }}>
                  당사자는 다음과 같이 연봉계약을 체결하고, 성실히 이행할 것을 약정한다.
                </div>
                <div className="ContractInput_Label_Copy" style={{ marginTop: "12px" }}>
                  제 1조 (연봉계약기간)
                </div>
                <div className="ContractInput_Body_Copy" style={{ marginTop: "4px" }}>
                  ① 연봉계약기간은{" "}
                  {selectedEmployeeData?.Documents?.AnnualSalaryContract?.AnnualSalaryContractStartDate &&
                  selectedEmployeeData?.Documents?.AnnualSalaryContract?.AnnualSalaryContractStartDate?.length === 8 ? (
                    <span style={{ color: "rgb(55, 105, 252)" }}>
                      {AddUnitYYYYMMDD(selectedEmployeeData.Documents.AnnualSalaryContract.AnnualSalaryContractStartDate)}
                    </span>
                  ) : (
                    <span
                      style={{
                        color: "rgb(216, 44, 44)",
                        backgroundColor: "rgb(255, 239, 190)",
                      }}
                    >
                      ____ 년 __ 월 __ 일
                    </span>
                  )}{" "}
                  부터{" "}
                  {selectedEmployeeData?.Documents?.AnnualSalaryContract?.AnnualSalaryContractEndDate &&
                  selectedEmployeeData?.Documents?.AnnualSalaryContract?.AnnualSalaryContractEndDate.length === 8 ? (
                    <span style={{ color: "rgb(55, 105, 252)" }}>
                      {AddUnitYYYYMMDD(selectedEmployeeData.Documents.AnnualSalaryContract.AnnualSalaryContractEndDate)}
                    </span>
                  ) : (
                    <span
                      style={{
                        color: "rgb(216, 44, 44)",
                        backgroundColor: "rgb(255, 239, 190)",
                      }}
                    >
                      ____ 년 __ 월 __ 일
                    </span>
                  )}{" "}
                  까지로 한다.
                  <br />② 연봉계약기간 중 회사 규정에 따른 연봉의 증감사유가 발생한 때에는 제1항의 계약기간 중이라도 연봉을 조정할 수 있다.
                </div>
                <div className="ContractInput_Label_Copy" style={{ marginTop: "12px" }}>
                  제 2 조 (연봉의 구성)
                </div>
                <div className="ContractInput_Body_Copy" style={{ marginTop: "4px" }}>
                  ① 연봉총액은 금{" "}
                  {selectedEmployeeData?.Documents?.AnnualSalaryContract?.AnnualSalary &&
                  selectedEmployeeData?.Documents?.AnnualSalaryContract?.AnnualSalary?.length > 0 ? (
                    <span style={{ color: "rgb(55, 105, 252)" }}>
                      {AddCommaAmountNumber(selectedEmployeeData.Documents.AnnualSalaryContract.AnnualSalary)}원
                    </span>
                  ) : (
                    <span
                      style={{
                        color: "rgb(216, 44, 44)",
                        backgroundColor: "rgb(255, 239, 190)",
                      }}
                    >
                      ____________ 원
                    </span>
                  )}
                  (으)로 한다.
                  <br />② 제1항의 연봉을 12개월로 균분한 월급여 형태를 기준으로 한 임금구성은 다음과 같다.
                </div>

                <div
                  className="ContractInput_Body_Copy"
                  style={{
                    marginBottom: "0px",
                    marginTop: "4px",
                    color: "rgb(55, 105, 252)",
                  }}
                >
                  ※ 아래 임금 구성표에 정보를 직접 입력해 주세요. <span style={{ color: "red" }}>붉은 글자</span>는 필수 입력값 입니다.
                </div>
                <SalaryCompositionTable
                  selectedEmployeeData={selectedEmployeeData}
                  documentName={"AnnualSalaryContract"}
                  setSelectedEmployeeData={setSelectedEmployeeData}
                  setSelectedEmployees={setSelectedEmployees}
                />

                <div className="ContractInput_Body_Copy" style={{ marginTop: "4px" }}>
                  ③ 사용자는 개인 및 조직성과에 따라 제1항의 연봉계약서에 규정하지 아니한 상여금 또는 성과급 및 복리후생비를 별도로 지급할 수 있다.
                </div>
                <div className="ContractInput_Label_Copy" style={{ marginTop: "12px" }}>
                  제 3 조 (지급시기 및 지급방법)
                </div>
                <div className="ContractInput_Body_Copy" style={{ marginTop: "4px" }}>
                  ① 제2조 제1항의 연봉은 12개월로 균등 분할하여 매월{" "}
                  {selectedEmployeeData?.Documents?.AnnualSalaryContract?.SalaryDay &&
                  selectedEmployeeData?.Documents?.AnnualSalaryContract?.SalaryDay.length > 0 ? (
                    <span style={{ color: "rgb(55, 105, 252)" }}>{selectedEmployeeData.Documents.AnnualSalaryContract.SalaryDay}일</span>
                  ) : (
                    <span
                      style={{
                        color: "rgb(216, 44, 44)",
                        backgroundColor: "rgb(255, 239, 190)",
                      }}
                    >
                      __ 일
                    </span>
                  )}
                  에 지급한다.
                  <br />② 연봉은 제1항의 지급기일에 근로자 명의의 계좌로 지급하며 지급일이 휴일인 경우에는 순차적으로 그 전일에 지급함을 원칙으로
                  한다. 단, 근로자가 원하는 경우 직접 지급할 수 있다.
                </div>

                <div className="ContractInput_Label_Copy" style={{ marginTop: "12px" }}>
                  제 4 조 (기밀유지)
                </div>
                <div className="ContractInput_Body_Copy" style={{ marginTop: "4px" }}>
                  ① 근로자는 본 연봉계약의 내용뿐만 아니라 타 직원의 연봉에 관한 사항에 대하여 기밀을 유지하여야 하며 이를 타인에게 누설하여서는
                  아니된다.
                  <br />② 근로자가 제1항의 의무를 위반한 경우 모든 불이익을 감수한다.
                </div>
                <div className="ContractInput_Label_Copy" style={{ marginTop: "12px" }}>
                  제 5 조 (기타)
                </div>
                <div className="ContractInput_Body_Copy" style={{ marginTop: "4px" }}>
                  ① 본 연봉계약은 제1항의 계약기간 동안 임금에 대해서만 발생하며, 기타 근로조건과 관련된 제반 사항은 입사 시 체결한 근로계약서에
                  의한다.
                  <br />② 기타 본 연봉계약에 명시되지 않은 사항은 근로기준법 등 노동관계법령, 회사 제규정이 정하는 바에 따른다.
                </div>
                <div className="ContractInput_Label_Copy" style={{ marginTop: "12px" }}>
                  제 6 조 (연봉계약서의 교부)
                </div>
                <div className="ContractInput_Body_Copy" style={{ marginTop: "4px" }}>
                  사용자는 근로자와 본 연봉계약을 체결함과 동시에 연봉계약서 2부를 작성하여 1부를 근로자에게 교부한다.
                </div>

                <div className="ContractInput_Label_Copy" style={{ marginTop: "12px", color: "rgb(170,170,170)" }}>
                  (근로자{" "}
                  {selectedEmployeeData?.Name && selectedEmployeeData?.Name.length > 0 ? (
                    <span>{selectedEmployeeData.Name}</span>
                  ) : (
                    <span
                      style={{
                        color: "rgb(216, 44, 44)",
                        backgroundColor: "rgb(255, 239, 190)",
                      }}
                    >
                      ______
                    </span>
                  )}
                  은(는) 본 근로계약서를 교부받았음을 확인합니다.)
                </div>
                <div className="ContractInput_Date_Copy_CT">
                  <div className="ContractInput_Date_Copy">
                    {selectedEmployeeData?.Documents?.AnnualSalaryContract?.ContractDate &&
                    selectedEmployeeData?.Documents?.AnnualSalaryContract?.ContractDate?.length === 8 ? (
                      <span>{AddUnitYYYYMMDD(selectedEmployeeData.Documents.AnnualSalaryContract.ContractDate)}</span>
                    ) : (
                      <span
                        style={{
                          color: "rgb(216, 44, 44)",
                          backgroundColor: "rgb(255, 239, 190)",
                        }}
                      >
                        ____ 년 __ 월 __ 일
                      </span>
                    )}
                  </div>
                </div>
                <ContractSignTable
                  inputCompanyName={inputCompanyName}
                  companyRepresentName={companyRepresentName}
                  employeeName={selectedEmployeeData?.Name}
                />
              </div>
            </div>
            <div className="ContractInput_VerticalDivider" />
            <div className="ContractInput_Right_CT">
              <div className="Contract_UserData_CT">
                <div className="Contract_Body_Copy" style={{ marginBottom: "8px" }}>
                  <div className="Contract_Label_Copy">이름</div> | {selectedEmployeeData?.Name}
                </div>
                <div className="Contract_Body_Copy" style={{ marginBottom: "8px" }}>
                  <div className="Contract_Label_Copy">생년월일</div> | {AddUnitYYYYMMDD(selectedEmployeeData?.Birthday || "")}
                </div>
                <div className="Contract_Body_Copy">
                  <div className="Contract_Label_Copy">전화번호</div> | {AddHyphenTelNumber(selectedEmployeeData?.TelNumber)}
                </div>
                <div className="Contract_Divider"> </div>
              </div>

              <div className="ContractInput_InputField">
                <div className="ContractInput_InputField_Label_CT">
                  <div className="Profile_Label_Copy">상호명</div>
                  <div className={inputCompanyName.length > 0 ? "Required_Dot_Blue" : "Required_Dot_Red"} />
                </div>
                <input
                  className="ContractInput_LongInput_BG"
                  value={inputCompanyName}
                  onChange={(e) => onChangeStateCompanyNameData(e.target.value)}
                  placeholder="상호명을 입력해주세요."
                  name="CompanyName"
                  required
                />
              </div>
              <div className="ContractInput_InputField">
                <div className="ContractInput_InputField_Label_CT">
                  <div className="Profile_Label_Copy">대표자명</div>
                  <div className={companyRepresentName.length > 0 ? "Required_Dot_Blue" : "Required_Dot_Red"} />
                </div>
                <input
                  className="ContractInput_LongInput_BG"
                  value={companyRepresentName}
                  onChange={(e) => onChangeStateCompanyRepresentNameData(e.target.value)}
                  placeholder="대표자명을 입력해주세요."
                  name="CompanyRepresentName"
                  required
                />
              </div>
              <div className="ContractInput_InputField">
                <div className="ContractInput_InputField_Label_CT">
                  <div className="Profile_Label_Copy">기업/기관 주소</div>
                  <div className={companyAddress.length > 0 ? "Required_Dot_Blue" : "Required_Dot_Red"} />
                </div>
                <input
                  className="ContractInput_LongInput_BG"
                  value={companyAddress}
                  onChange={(e) => onChangeStateCompanyAddressData(e.target.value)}
                  placeholder="주소를 입력해주세요."
                  name="CompanyAddress"
                  required
                />
              </div>
              {selectedEmployeeData?.NameDone ? (
                <div className="ContractInput_InputField">
                  <div className="ContractInput_InputField_Label_CT">
                    <div className="Profile_Label_Copy">{selectedEmployeeData?.Name} 님의 생년월일</div>
                    <div
                      className={
                        (selectedEmployeeData?.Birthday && selectedEmployeeData?.Birthday?.length === 8) ||
                        (selectedEmployeeData?.Documents?.AnnualSalaryContract?.Birthday &&
                          selectedEmployeeData?.Documents?.AnnualSalaryContract?.Birthday?.length === 8)
                          ? "Required_Dot_Blue"
                          : "Required_Dot_Red"
                      }
                    />
                  </div>
                  <input
                    className="ContractInput_LongInput_BG"
                    value={selectedEmployeeData?.Documents?.AnnualSalaryContract?.Birthday || selectedEmployeeData?.Birthday || ""}
                    onChange={(e) => onChangeStateBirthdayData(e.target.value)}
                    placeholder="YYYYMMDD 형식으로 입력해주세요."
                    name="NewEmployeeBirthday"
                    required
                  />
                </div>
              ) : null}
              <div className="ContractInput_InputField">
                <div className="ContractInput_InputField_Label_CT">
                  <div className="Profile_Label_Copy">{selectedEmployeeData?.Name} 님의 주소</div>
                  <div
                    className={
                      (selectedEmployeeData?.Address && selectedEmployeeData?.Address?.length > 0) ||
                      (selectedEmployeeData?.Documents?.AnnualSalaryContract?.Address &&
                        selectedEmployeeData?.Documents?.AnnualSalaryContract?.Address?.length > 0)
                        ? "Required_Dot_Blue"
                        : "Required_Dot_Red"
                    }
                  />
                </div>
                <input
                  className="ContractInput_LongInput_BG"
                  value={selectedEmployeeData?.Documents?.AnnualSalaryContract?.Address || selectedEmployeeData?.Address || ""}
                  onChange={(e) => onChangeStateAddressData(e.target.value)}
                  placeholder="주소를 입력해주세요."
                  name="NewEmployeeAddress"
                  required
                />
              </div>
              <div className="ContractInput_InputField">
                <div className="ContractInput_InputField_Label_CT">
                  <div className="Profile_Label_Copy">연봉계약기간</div>

                  <div
                    className={
                      selectedEmployeeData?.Documents?.AnnualSalaryContract?.AnnualSalaryContractStartDate &&
                      selectedEmployeeData?.Documents?.AnnualSalaryContract?.AnnualSalaryContractStartDate?.length === 8 &&
                      selectedEmployeeData?.Documents?.AnnualSalaryContract?.AnnualSalaryContractEndDate &&
                      selectedEmployeeData?.Documents?.AnnualSalaryContract?.AnnualSalaryContractEndDate?.length === 8
                        ? "Required_Dot_Blue"
                        : "Required_Dot_Red"
                    }
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    width: "420px",
                  }}
                >
                  <input
                    className="ContractInput_ShortInput_BG"
                    value={selectedEmployeeData?.Documents?.AnnualSalaryContract?.AnnualSalaryContractStartDate || ""}
                    onChange={(e) =>
                      HandleSalaryDocumentData(
                        "Documents",
                        e.target.value,
                        "AnnualSalaryContractStartDate",
                        "AnnualSalaryContract",
                        setSelectedEmployeeData,
                        setSelectedEmployees
                      )
                    }
                    placeholder="YYYYMMDD"
                    name="AnnualSalaryContractStartDate"
                    required
                  />
                  <div className="ContractInput_Guide_Copy" style={{ marginRight: "12px" }}>
                    부터
                  </div>
                  <input
                    className="ContractInput_ShortInput_BG"
                    value={selectedEmployeeData?.Documents?.AnnualSalaryContract?.AnnualSalaryContractEndDate || ""}
                    onChange={(e) =>
                      HandleSalaryDocumentData(
                        "Documents",
                        e.target.value,
                        "AnnualSalaryContractEndDate",
                        "AnnualSalaryContract",
                        setSelectedEmployeeData,
                        setSelectedEmployees
                      )
                    }
                    placeholder="YYYYMMDD"
                    name="AnnualSalaryContractEndDate"
                    required
                  />
                  <div className="ContractInput_Guide_Copy">까지</div>
                </div>
              </div>

              <div className="ContractInput_InputField">
                <div className="ContractInput_InputField_Label_CT">
                  <div className="Profile_Label_Copy">연봉</div>
                  <div
                    className={
                      selectedEmployeeData?.Documents?.AnnualSalaryContract?.AnnualSalary &&
                      selectedEmployeeData?.Documents?.AnnualSalaryContract?.AnnualSalary?.length > 0
                        ? "Required_Dot_Blue"
                        : "Required_Dot_Red"
                    }
                  />
                </div>
                <input
                  className="ContractInput_LongInput_BG"
                  value={selectedEmployeeData?.Documents?.AnnualSalaryContract?.AnnualSalary || ""}
                  onChange={(e) =>
                    HandleSalaryDocumentData(
                      "Documents",
                      e.target.value,
                      "AnnualSalary",
                      "AnnualSalaryContract",
                      setSelectedEmployeeData,
                      setSelectedEmployees
                    )
                  }
                  placeholder="숫자만 입력해주세요."
                  name="AnnualSalary"
                  required
                />
              </div>
              <div className="ContractInput_InputField">
                <div className="ContractInput_InputField_Label_CT">
                  <div className="Profile_Label_Copy">급여지급일</div>
                  <div
                    className={
                      selectedEmployeeData?.Documents?.AnnualSalaryContract?.SalaryDay?.length > 0 ? "Required_Dot_Blue" : "Required_Dot_Red"
                    }
                  />
                </div>
                <input
                  className="ContractInput_LongInput_BG"
                  value={selectedEmployeeData?.Documents?.AnnualSalaryContract?.SalaryDay || ""}
                  onChange={(e) =>
                    HandleSalaryDocumentData(
                      "Documents",
                      e.target.value,
                      "SalaryDay",
                      "AnnualSalaryContract",
                      setSelectedEmployeeData,
                      setSelectedEmployees
                    )
                  }
                  placeholder="숫자만 입력해주세요."
                  name="SalaryDay"
                  required
                />
              </div>
              <div className="ContractInput_InputField">
                <div className="ContractInput_InputField_Label_CT">
                  <div className="Profile_Label_Copy">계약일</div>
                  <div
                    className={
                      selectedEmployeeData?.Documents?.AnnualSalaryContract?.ContractDate &&
                      selectedEmployeeData?.Documents?.AnnualSalaryContract?.ContractDate?.length === 8
                        ? "Required_Dot_Blue"
                        : "Required_Dot_Red"
                    }
                  />
                </div>
                <input
                  className="ContractInput_LongInput_BG"
                  value={selectedEmployeeData?.Documents?.AnnualSalaryContract?.ContractDate || ""}
                  onChange={(e) => onChangeContractDateData(e.target.value)}
                  placeholder="YYYYMMDD 형식으로 입력해주세요."
                  name="ContractDate"
                  required
                />
              </div>

              <button className="ContractInput_ConfirmButton_BG" type="button" onClick={onClickConfirmInput}>
                작성 완료
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
);

export default AnnualSalaryContract;
