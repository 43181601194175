import "../GlobalStyle.css";
import "../PCStyle.css";
import "../TabletStyle.css";
import "../MobileStyle.css";
import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import * as amplitude from "@amplitude/analytics-browser";
import axios from "axios";
import ReferCheckList from "../Components/ReferCheckList";
import NavigationPanel from "../Components/NavigationPanel";
import WidgetList from "../Components/Organism/WidgetList";

const WB51ConsentDocumentSub1 = observer(
  ({ setDocument1, setDocument2, setDocument3, selectedDocuments, setSelectedDocuments, setIsLoading, userData }) => {
    const [documentsData, setDocumentsData] = useState([]);
    const [joinKitDocumentsData, setJoinKitDocumentsData] = useState([]);

    const [isFirstTab, setIsFirstTab] = useState(true);
    const [isSecondTab, setIsSecondTab] = useState(false);

    const onClickFirstTab = () => {
      setSelectedDocuments([]);
      setIsSecondTab(false);
      setIsFirstTab(true);
    };

    const onClickSecondTab = () => {
      setSelectedDocuments([]);
      setIsFirstTab(false);
      setIsSecondTab(true);
    };

    const onClickNext = () => {
      if (selectedDocuments.length > 0 && userData.paymentsStatus === true) {
        amplitude.track("동의 받기2 - 동의 문서 전송 메시지 확인 스크린 진입");
        setDocument1(false);
        setDocument3(true);
      } else if (selectedDocuments.length > 0 && !userData.paymentsStatus) {
        amplitude.track("동의 받기 - 결제 안내 스크린 진입");
        setDocument1(false);
        setDocument2(true);
      } else {
        alert("동의를 받을 문서를 선택해주세요.");
      }
    };

    useEffect(() => {
      if (!userData.companyEmail) return;
      setIsLoading(true);
      const loadEmployeeData = async () => {
        const endPoint = "https://dml1u35aa3.execute-api.ap-northeast-2.amazonaws.com/product/contrack";
        try {
          const response = await axios.get(endPoint, {
            params: {
              email: userData.companyEmail,
            },
          });

          if (response.data.message === "문서 조회에 성공했습니다.") {
            const rawDocumentsData = response.data.data;
            rawDocumentsData.sort((a, b) => {
              return a.CreationDate.localeCompare(b.CreationDate);
            });
            const filteredDocumentsData = rawDocumentsData.filter((document) => document.DocumentStatus === "작성" && !document.JoinKit);
            const filteredJoinKitDocumentsData = rawDocumentsData.filter(
              (document) => document.DocumentStatus === "작성" && document.JoinKit === true
            );
            setDocumentsData(filteredDocumentsData);
            setJoinKitDocumentsData(filteredJoinKitDocumentsData);
            setIsLoading(false);
          } else {
            alert("직원 정보 조회에 실패하였습니다. 다시 시도해주세요.");
            setIsLoading(false);
          }
        } catch (error) {
          console.error("Error : ", error);
          alert("직원 정보 조회 통신에 실패하였습니다. 다시 시도해주세요.");
          setIsLoading(false);
        }
      };

      loadEmployeeData();
    }, [userData.companyEmail, setIsLoading]);

    const handleCheckBoxChange = (employee) => {
      setSelectedDocuments((prevSelected) => {
        if (prevSelected.includes(employee)) {
          return prevSelected.filter((e) => e !== employee);
        } else {
          return [...prevSelected, employee];
        }
      });
    };

    return (
      <div className="AllContent_Default_V2">
        <div className="Leave_LeftArea_CT">
          <div className="Leave_Main_Copy">직원의 동의를 받을 문서를 선택해주세요.</div>
          <div className="Leave_Sub_Copy">선택하신 문서는 수령인들에게 발송되어 앱에서 동의를 진행 할 수 있습니다.</div>
          <div className="TabBar_Short_CT">
            <div className="TabBar_Labels_CT">
              <div className="TabBar_Label_CT">
                <button className="TabBar_Label_Underline" onClick={onClickFirstTab}>
                  <div className={`TabBar_Label_Copy_${isFirstTab ? "A" : "B"}`}>일반문서</div>
                </button>
              </div>
              <div className="TabBar_Label_CT">
                <button className="TabBar_Label_Underline" onClick={onClickSecondTab}>
                  <div className={`TabBar_Label_Copy_${isSecondTab ? "A" : "B"}`}>입사키트</div>
                </button>
              </div>
            </div>
            <div className="TabBar_Line" />
          </div>

          {isFirstTab && documentsData.length > 0 ? (
            // <ReferCheckList
            //   selectedList={selectedDocuments}
            //   setSelectedList={setSelectedDocuments}
            //   mappedList={documentsData}
            //   handleCheckBoxChange={handleCheckBoxChange}
            //   labelList={["수령인", "문서명", "작성일"]}
            //   showDetailData
            // />
            <WidgetList
              type={"multiCheckbox"}
              selectedListItemArray={selectedDocuments}
              setSelectedListItemArray={setSelectedDocuments}
              listDataArray={documentsData}
              onClickCheckbox={handleCheckBoxChange}
              itemsPerPage={15}
              height={"586px"}
              fieldList={["수령인", "문서명", "작성일"]}
              showDetailData
            />
          ) : isFirstTab && documentsData.length === 0 ? (
            <div className="Wait_NoneItem_Copy">작성된 일반 문서가 없습니다.</div>
          ) : null}
          {isSecondTab && joinKitDocumentsData.length > 0 ? (
            <ReferCheckList
              selectedList={selectedDocuments}
              setSelectedList={setSelectedDocuments}
              mappedList={joinKitDocumentsData}
              handleCheckBoxChange={handleCheckBoxChange}
              labelList={["수령인", "문서명", "작성일"]}
              showDetailData
            />
          ) : isSecondTab && joinKitDocumentsData.length === 0 ? (
            <div className="Wait_NoneItem_Copy">작성된 입사 키트가 없습니다.</div>
          ) : null}
        </div>
        <div className="Leave_Vertical_Divider" />
        <div className="Leave_RightArea_CT">
          <NavigationPanel nextButtonText={"다음"} onClickNext={onClickNext} />
          {userData?.paymentsStatus === true ? <div className="Leave_PageCount_Copy">1/2</div> : <div className="Leave_PageCount_Copy">1/3</div>}
        </div>
      </div>
    );
  }
);

export default WB51ConsentDocumentSub1;
