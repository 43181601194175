import "../GlobalStyle.css";
import "../PCStyle.css";
import React from "react";
import { observer } from "mobx-react";
import WidgetNavigation from "../Components/Organism/WidgetNavigation";
import WidgetHeader from "../Components/Organism/WidgetHeader";
import Icon from "../Assets/Icons/Icon";
import axios from "axios";
import { LoadComapanyData } from "../Utils/LoadCompanyData";

const WBd1SendTabSub5 = observer(
  ({
    firstTabMoveToPage4,
    firstTabMoveToPage6,
    link,
    setLink,
    setIsLoading,
    certificate,
    resume,
    identityCardImage,
    diploma,
    recentTwoYearIncomeReceipt,
    recentThreeMonthSalaryStatement,
    sendDocument,
    selectedGroup,
  }) => {
    const userData = LoadComapanyData("userData");

    const createLink = async () => {
      setIsLoading(true);
      try {
        const endPoint = "https://dml1u35aa3.execute-api.ap-northeast-2.amazonaws.com/product/recipient/link";
        if (certificate && sendDocument.length < 6) {
          sendDocument.push({ documentType: "certificate" });
        }
        if (resume && sendDocument.length < 6) {
          sendDocument.push({ documentType: "resume" });
        }
        if (identityCardImage && sendDocument.length < 6) {
          sendDocument.push({ documentType: "identityCardImage" });
        }
        if (diploma && sendDocument.length < 6) {
          sendDocument.push({ documentType: "diploma" });
        }
        if (recentTwoYearIncomeReceipt && sendDocument.length < 6) {
          sendDocument.push({ documentType: "recentTwoYearIncomeReceipt" });
        }
        if (recentThreeMonthSalaryStatement && sendDocument.length < 6) {
          sendDocument.push({ documentType: "recentThreeMonthSalaryStatement" });
        }
        const response = await axios.post(endPoint, {
          email: userData.companyEmail,
          documents: sendDocument,
          groupName: selectedGroup.groupName,
          groupId: selectedGroup.id,
        });
        if (response.data.status === "링크 생성 성공") {
          setLink(response.data.data.url);
          setIsLoading(false);
        } else {
          setIsLoading(false);
          alert("링크 생성을 실패했습니다. 다시 시도해 주세요.");
        }
      } catch (error) {
        setIsLoading(false);
        console.log(error);
        alert("링크 생성 통신을 실패했습니다. 다시 시도해 주세요.");
      }
    };

    const copyLink = () => {
      navigator.clipboard
        .writeText(link)
        .then(() => {
          alert("링크가 클립보드에 복사되었습니다.");
        })
        .catch((error) => {
          alert("링크가 클립보드에 복사되지 않았습니다. 다시 시도해 주세요.");
          console.error("클립보드 복사 실패:", error);
        });
    };

    const moveToPage4 = () => {
      setLink("");
      firstTabMoveToPage4();
    };

    const moveToPage6 = () => {
      if (link.length > 0) {
        firstTabMoveToPage6();
      } else {
        alert("링크를 생성해주세요.");
      }
    };

    return (
      <div className="Widget_Contents_CT">
        <div className="Widget_Contents_Upper">
          <WidgetNavigation
            previousButtonLabel={"이전"}
            onClickPreviousButton={moveToPage4}
            nextButtonLabel={"다음"}
            onClickNextButton={moveToPage6}
          />
          <WidgetHeader
            titleText={"필수 서류 요청 링크를 생성해주세요."}
            bodyText={"대상자는 생성된 링크에 접속한 뒤 본인의 이름과 전화번호를 입력하여\n필수 서류 요청 메시지를 전송할 수 있습니다."}
            pageNumber={"4 / 5"}
          />
          <div className="Widget_UnScroll_Contents">
            <div className="WidgetList_Link_Container">
              <div className="WidgetList_Link_BG">
                <div className="WidgetList_Link_Body" style={{ color: link.length > 0 ? "#464646" : "#AAAAAA" }}>
                  {link.length > 0 ? link : "링크를 생성해 주세요."}
                </div>
              </div>
              <button
                className="WidgetList_Link_Button_Container"
                onClick={link.length > 0 ? copyLink : null}
                style={{ backgroundColor: link.length > 0 ? "#E7EFFF" : "#E3E3E3", cursor: link.length > 0 ? "pointer" : "default" }}
              >
                <Icon name={"Copy"} size={"20"} color={link.length > 0 ? "#3769fc" : "#AAAAAA"} />
              </button>
            </div>
          </div>
        </div>
        <div className="Widget_Contents_Lower">
          <button className="Widget_CTA_Button" type="button" onClick={createLink}>
            <div className="Widget_CTA_Button_Label" style={{ marginLeft: "4px" }}>
              링크 생성
            </div>
          </button>
        </div>
      </div>
    );
  }
);

export default WBd1SendTabSub5;
