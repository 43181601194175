import "../GlobalStyle.css";
import "../PCStyle.css";
import "../TabletStyle.css";
import "../MobileStyle.css";
import React from "react";
import { useState } from "react";
import { observer } from "mobx-react";
import Footer from "../Components/Footer";
import WB01SigninSub1 from "../SubPages/WB01SigninSub1";
import WB01SigninSub2 from "../SubPages/WB01SigninSub2";
import WB01SigninSub3 from "../SubPages/WB01SigninSub3";
import WB01SigninSub4 from "../SubPages/WB01SigninSub4";
import WB01SigninSub5 from "../SubPages/WB01SigninSub5";

const WB01Signin = observer(() => {
  const screenWidth = window.innerWidth;

  const [signin1, setSignin1] = useState(true);
  const [signin2, setSignin2] = useState(false);
  const [signin3, setSignin3] = useState(false);
  const [signin4, setSignin4] = useState(false);
  const [signin5, setSignin5] = useState(false);

  // 약관 useState
  const [isRadioAllCheck, setIsRadioAllCheck] = useState(false);
  const [isRadio1Check, setIsRadio1Check] = useState(false);
  const [isRadio2Check, setIsRadio2Check] = useState(false);
  const [isRadio3Check, setIsRadio3Check] = useState(false);
  const [isRadio4Check, setIsRadio4Check] = useState(false);
  const [isRadio5Check, setIsRadio5Check] = useState(false);
  const [termsDone, setTermsDone] = useState(false);

  // 이메일 useState
  const [companyIdEmail, setCompanyIdEmail] = useState("");
  const [companyIdEmailNum, setCompanyIdEmailNum] = useState("");
  const [companyRandomNum, setCompanyRandomNum] = useState("");
  const [companyIdEmailNumDone, setCompanyIdEmailNumDone] = useState(false);
  const [isSendIdEmailNum, setIsSendIdEmailNum] = useState(false);
  const [remainingTime, setRemainingTime] = useState(180);
  const [clickEmailValid, setClickEmailValid] = useState(false);

  // 비밀번호 useState
  const [companyPassword, setCompanyPassword] = useState("");
  const [companyPasswordCheck, setCompanyPasswordCheck] = useState("");
  const [passwordValid, setPasswordValid] = useState(false);
  const [passwordMatch, setPasswordMatch] = useState(false);
  const [readPassword, setReadPassword] = useState(false);
  const [readPasswordCheck, setReadPasswordCheck] = useState(false);

  // 기업명 useState
  const [companyName, setCompanyName] = useState("");
  const [companyNameDone, setCompanyNameDone] = useState(false);

  // 대표자명 useState
  const [representativeName, setRepresentativeName] = useState("");
  const [representativeNameDone, setRepresentativeNameDone] = useState(false);

  // 직인 useState
  const [sealImageSrc, setSealImageSrc] = useState(null);
  const [sealImageSrcDone, setSealImageSrcDone] = useState(false);

  // 사업자등록증 useState
  const [lisenceSrc, setLisenceSrc] = useState(null);
  const [lisenceDone, setLisenceDone] = useState(false);

  // 사업자등록번호 useState
  const [resistNumber, setResistNumber] = useState("");
  const [resistNumberDone, setResistNumberDone] = useState(false);
  const [cleanedResistNumber, setCleanedResistNumber] = useState("");

  // 업종 useState
  const [businessType, setBusinessType] = useState("");
  const [businessTypeDone, setBusinessTypeDone] = useState(false);
  const [isVisibleEtc, setIsVisibleEtc] = useState(false);
  const [etcBusinessType, setEtcBusinessType] = useState("");

  // 직원수 useState
  const [employeesNumber, setEmployeesNumber] = useState("");
  const [employeesNumberDone, setEmployeesNumberDone] = useState(false);

  const [isModalVisible, setIsModalVisible] = useState(false);
  // 담당자 이름 useState
  const [managerName, setManagerName] = useState("");
  const [managerNameDone, setManagerNameDone] = useState(false);

  // 담당자 전화번호 useState
  const [managerTelNumber, setManagerTelNumber] = useState("");
  const [managerTelNumberDone, setManagerTelNumberDone] = useState(false);
  const [cleanedTelNumber, setCleanedTelNumber] = useState("");

  // 담당자 포지션 useState
  const [managerPosition, setManagerPosition] = useState("");
  const [managerPositionDone, setManagerPositionDone] = useState(false);
  const [isVisibleManegerEtc, setIsVisibleManegerEtc] = useState(false);
  const [etcManagerPosition, setEtcManagerPosition] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  // 추천인 이름 useState
  const [recommenderName, setRecommenderName] = useState("");

  return (
    <div className="FlexContent_Other_V" style={{ alignItems: "center" }}>
      <div className="AllContent_Default_V">
        {signin1 ? (
          <WB01SigninSub1
            isRadioAllCheck={isRadioAllCheck}
            setIsRadioAllCheck={setIsRadioAllCheck}
            isRadio1Check={isRadio1Check}
            setIsRadio1Check={setIsRadio1Check}
            isRadio2Check={isRadio2Check}
            setIsRadio2Check={setIsRadio2Check}
            isRadio3Check={isRadio3Check}
            setIsRadio3Check={setIsRadio3Check}
            isRadio4Check={isRadio4Check}
            setIsRadio4Check={setIsRadio4Check}
            isRadio5Check={isRadio5Check}
            setIsRadio5Check={setIsRadio5Check}
            termsDone={termsDone}
            setTermsDone={setTermsDone}
            setSignin1={setSignin1}
            setSignin2={setSignin2}
          />
        ) : null}
        {signin2 ? (
          <WB01SigninSub2
            companyIdEmail={companyIdEmail}
            setCompanyIdEmail={setCompanyIdEmail}
            companyIdEmailNum={companyIdEmailNum}
            setCompanyIdEmailNum={setCompanyIdEmailNum}
            companyRandomNum={companyRandomNum}
            setCompanyRandomNum={setCompanyRandomNum}
            companyIdEmailNumDone={companyIdEmailNumDone}
            setCompanyIdEmailNumDone={setCompanyIdEmailNumDone}
            isSendIdEmailNum={isSendIdEmailNum}
            setIsSendIdEmailNum={setIsSendIdEmailNum}
            remainingTime={remainingTime}
            setRemainingTime={setRemainingTime}
            companyPassword={companyPassword}
            setCompanyPassword={setCompanyPassword}
            companyPasswordCheck={companyPasswordCheck}
            setCompanyPasswordCheck={setCompanyPasswordCheck}
            passwordValid={passwordValid}
            setPasswordValid={setPasswordValid}
            passwordMatch={passwordMatch}
            setPasswordMatch={setPasswordMatch}
            readPassword={readPassword}
            setReadPassword={setReadPassword}
            readPasswordCheck={readPasswordCheck}
            setReadPasswordCheck={setReadPasswordCheck}
            clickEmailValid={clickEmailValid}
            setClickEmailValid={setClickEmailValid}
            setSignin1={setSignin1}
            setSignin2={setSignin2}
            setSignin3={setSignin3}
          />
        ) : null}
        {signin3 ? (
          <WB01SigninSub3
            companyName={companyName}
            setCompanyName={setCompanyName}
            companyNameDone={companyNameDone}
            setCompanyNameDone={setCompanyNameDone}
            representativeName={representativeName}
            setRepresentativeName={setRepresentativeName}
            representativeNameDone={representativeNameDone}
            setRepresentativeNameDone={setRepresentativeNameDone}
            sealImageSrc={sealImageSrc}
            setSealImageSrc={setSealImageSrc}
            lisenceSrc={lisenceSrc}
            setLisenceSrc={setLisenceSrc}
            lisenceDone={lisenceDone}
            setLisenceDone={setLisenceDone}
            resistNumber={resistNumber}
            setResistNumber={setResistNumber}
            resistNumberDone={resistNumberDone}
            setResistNumberDone={setResistNumberDone}
            cleanedResistNumber={cleanedResistNumber}
            setCleanedResistNumber={setCleanedResistNumber}
            sealImageSrcDone={sealImageSrcDone}
            setSealImageSrcDone={setSealImageSrcDone}
            recommenderName={recommenderName}
            setRecommenderName={setRecommenderName}
            setSignin2={setSignin2}
            setSignin3={setSignin3}
            setSignin4={setSignin4}
          />
        ) : null}
        {signin4 ? (
          <WB01SigninSub4
            businessType={businessType}
            setBusinessType={setBusinessType}
            businessTypeDone={businessTypeDone}
            setBusinessTypeDone={setBusinessTypeDone}
            isVisibleEtc={isVisibleEtc}
            setIsVisibleEtc={setIsVisibleEtc}
            etcBusinessType={etcBusinessType}
            setEtcBusinessType={setEtcBusinessType}
            employeesNumber={employeesNumber}
            setEmployeesNumber={setEmployeesNumber}
            employeesNumberDone={employeesNumberDone}
            setEmployeesNumberDone={setEmployeesNumberDone}
            setSignin3={setSignin3}
            setSignin4={setSignin4}
            setSignin5={setSignin5}
          />
        ) : null}
        {signin5 ? (
          <WB01SigninSub5
            termsDone={termsDone}
            companyIdEmail={companyIdEmail}
            companyPasswordCheck={companyPasswordCheck}
            companyNameDone={companyNameDone}
            representativeNameDone={representativeNameDone}
            businessTypeDone={businessTypeDone}
            employeesNumber={employeesNumber}
            companyName={companyName}
            employeesNumberDone={employeesNumberDone}
            isRadio4Check={isRadio4Check}
            isRadio5Check={isRadio5Check}
            setSignin4={setSignin4}
            setSignin5={setSignin5}
            representativeName={representativeName}
            businessType={businessType}
            sealImageSrc={sealImageSrc}
            lisenceSrc={lisenceSrc}
            isModalVisible={isModalVisible}
            setIsModalVisible={setIsModalVisible}
            managerNameDone={managerNameDone}
            setManagerNameDone={setManagerNameDone}
            managerTelNumber={managerTelNumber}
            managerName={managerName}
            setManagerTelNumber={setManagerTelNumber}
            setManagerName={setManagerName}
            managerTelNumberDone={managerTelNumberDone}
            setManagerTelNumberDone={setManagerTelNumberDone}
            cleanedTelNumber={cleanedTelNumber}
            setCleanedTelNumber={setCleanedTelNumber}
            managerPosition={managerPosition}
            setManagerPosition={setManagerPosition}
            managerPositionDone={managerPositionDone}
            setManagerPositionDone={setManagerPositionDone}
            isVisibleManegerEtc={isVisibleManegerEtc}
            setIsVisibleManegerEtc={setIsVisibleManegerEtc}
            etcManagerPosition={etcManagerPosition}
            setEtcManagerPosition={setEtcManagerPosition}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            cleanedResistNumber={cleanedResistNumber}
            etcBusinessType={etcBusinessType}
            recommenderName={recommenderName}
          />
        ) : null}
      </div>
      {screenWidth > 1023 && <Footer />}
    </div>
  );
});

export default WB01Signin;
