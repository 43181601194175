import "../GlobalStyle.css";
import "../PCStyle.css";
import React from "react";
import { observer } from "mobx-react";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import WidgetNavigation from "../Components/Organism/WidgetNavigation";
import WidgetHeader from "../Components/Organism/WidgetHeader";
import { LoadComapanyData } from "../Utils/LoadCompanyData";

const WBd1SendTabSub6 = observer(
  ({
    setIsLoading,
    setGroupList,
    setRequestList,
    firstTabMoveToPage1,
    firstTabMoveToPage4,
    firstTabMoveToPage5,
    certificate,
    setCertificate,
    resume,
    setResume,
    identityCardImage,
    setIdentityCardImage,
    diploma,
    setDiploma,
    recentTwoYearIncomeReceipt,
    setRecentTwoYearIncomeReceipt,
    recentThreeMonthSalaryStatement,
    setRecentThreeMonthSalaryStatement,
    selectedGroup,
    setSelectedGroup,
    resistEmployees,
    setResistEmployees,
    sendDocument,
    setSendDocument,
    firstTabDesignate,
    firstTabLink,
  }) => {
    const userData = LoadComapanyData("userData");

    const getGroupList = async () => {
      const endPoint = "https://dml1u35aa3.execute-api.ap-northeast-2.amazonaws.com/product/recipient/group";
      try {
        const response = await axios.get(endPoint, {
          params: {
            email: userData.companyEmail,
          },
        });
        if (response.data.status === "그룹 조회 성공") {
          setGroupList(response.data.data);
        } else {
          alert("필수 서류 요청 발신 조회에 실패하였습니다. 다시 시도해주세요.");
          setIsLoading(false);
        }
      } catch (error) {
        console.error("Error : ", error);
        alert("필수 서류 요청 발신 조회 통신을 실패하였습니다. 다시 시도해주세요.");
        setIsLoading(false);
      }
    };

    const getRequestList = async () => {
      const endPoint = "https://dml1u35aa3.execute-api.ap-northeast-2.amazonaws.com/product/recipient";
      try {
        const response = await axios.get(endPoint, {
          params: {
            email: userData.companyEmail,
          },
        });
        if (response.data.status === "대상자 확인 완료") {
          setRequestList(response.data.data);
          setIsLoading(false);
        } else {
          alert("필수 서류 요청 발신 조회에 실패하였습니다. 다시 시도해주세요.");
          setIsLoading(false);
        }
      } catch (error) {
        console.error("Error : ", error);
        alert("필수 서류 요청 발신 조회 통신을 실패하였습니다. 다시 시도해주세요.");
        setIsLoading(false);
      }
    };

    const getRequiredDocuments = () => {
      const documents = [];
      if (certificate) documents.push("- 경력증명서");
      if (resume) documents.push("- 이력서");
      if (identityCardImage) documents.push("- 주민등록증 이미지");
      if (diploma) documents.push("- 졸업증명서");
      if (recentTwoYearIncomeReceipt) documents.push("- 최근 2년간 근로소득 원천징수 영수증");
      if (recentThreeMonthSalaryStatement) documents.push("- 최근 3개월간 급여명세서");

      return documents.join("\n");
    };

    const moveToPage4Designate = () => {
      firstTabMoveToPage4();
    };

    const moveToPage5Link = () => {
      firstTabMoveToPage5();
    };

    const firstTabConfirmSendMessege = async () => {
      setIsLoading(true);

      const endPoint = "https://dml1u35aa3.execute-api.ap-northeast-2.amazonaws.com/product/recipient/documents";
      try {
        if (certificate && sendDocument.length < 6) {
          sendDocument.push({ documentType: "certificate" });
        }
        if (resume && sendDocument.length < 6) {
          sendDocument.push({ documentType: "resume" });
        }
        if (identityCardImage && sendDocument.length < 6) {
          sendDocument.push({ documentType: "identityCardImage" });
        }
        if (diploma && sendDocument.length < 6) {
          sendDocument.push({ documentType: "diploma" });
        }
        if (recentTwoYearIncomeReceipt && sendDocument.length < 6) {
          sendDocument.push({ documentType: "recentTwoYearIncomeReceipt" });
        }
        if (recentThreeMonthSalaryStatement && sendDocument.length < 6) {
          sendDocument.push({ documentType: "recentThreeMonthSalaryStatement" });
        }

        const recipientsData = {
          groupName: selectedGroup.groupName,
          groupId: selectedGroup.id,
          requestList: resistEmployees.map((employee) => ({
            recipientName: employee.name,
            recipientTelNumber: employee.telNumber,
            documents: sendDocument,
          })),
        };

        const response = await axios.post(endPoint, {
          email: userData.companyEmail,
          companyName: userData.companyName,
          recipientsData: recipientsData,
        });

        if (response.data.status === "필수 서류 요청 성공") {
          alert("필수 서류 요청 메시지를 전송했습니다.");
          setIsLoading(false);
          setCertificate(false);
          setResume(false);
          setIdentityCardImage(false);
          setDiploma(false);
          setRecentTwoYearIncomeReceipt(false);
          setRecentThreeMonthSalaryStatement(false);
          setSelectedGroup(null);
          setResistEmployees([{ name: "", telNumber: "", nameDone: false, telNumberDone: false, ID: uuidv4() }]);
          setSendDocument([]);
          await getGroupList();
          await getRequestList();
          firstTabMoveToPage1();
        } else {
          alert("필수 서류 요청 메시지 전송에 실패하였습니다. 다시 시도해주세요.");
          setSendDocument([]);
          setIsLoading(false);
        }
      } catch (error) {
        console.error("Error : ", error);
        alert("필수 서류 요청 메시지 전송 통신을 실패하였습니다. 다시 시도해주세요.");
        setSendDocument([]);
        setIsLoading(false);
      }
    };

    const firstTabConfirmMessege = async () => {
      setIsLoading(false);
      setCertificate(false);
      setResume(false);
      setIdentityCardImage(false);
      setDiploma(false);
      setRecentTwoYearIncomeReceipt(false);
      setRecentThreeMonthSalaryStatement(false);
      setSelectedGroup(null);
      setResistEmployees([{ name: "", telNumber: "", nameDone: false, telNumberDone: false, ID: uuidv4() }]);
      setSendDocument([]);
      firstTabMoveToPage1();
    };

    return (
      <div className="Widget_Contents_CT">
        <div className="Widget_Contents_Upper">
          <WidgetNavigation
            previousButtonLabel={"이전"}
            onClickPreviousButton={firstTabDesignate && !firstTabLink ? moveToPage4Designate : moveToPage5Link}
            nextButtonLabel={firstTabDesignate && !firstTabLink ? "전송" : "확인"}
            onClickNextButton={firstTabDesignate && !firstTabLink ? firstTabConfirmSendMessege : firstTabConfirmMessege}
          />

          <WidgetHeader
            titleText={
              firstTabDesignate && !firstTabLink && resistEmployees.length > 1
                ? `${resistEmployees[0].name}님 외 ${resistEmployees.length - 1} 명 에게는\n이렇게 메시지가 전달돼요.`
                : firstTabDesignate && !firstTabLink && resistEmployees.length === 1
                ? `${resistEmployees[0].name}님 에게는\n이렇게 메시지가 전달돼요.`
                : "지원자에게는 이렇게 메시지가 전달돼요."
            }
            pageNumber={"5 / 5"}
          />
          <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "flex-start", width: "100%" }}>
            <div className="Widget_Messege_Label">문자메시지 내용</div>
            <div className="Widget_Messege_BG">
              <div className="Widget_Messege_Copy">
                {firstTabDesignate && !firstTabLink
                  ? `안녕하세요 ${resistEmployees[0].name}님, (주) 지디피스튜디오입니다.`
                  : `안녕하세요, (주) 지디피스튜디오입니다.`}
                <br />
                아래 문서를 써티드 앱을 통해 전송해주세요. 감사합니다.
                <br />
                -(주) 지디피스튜디오 드림-
                <br />
                <br />
                [필수 서류 목록]
                <br />
                {getRequiredDocuments()
                  .split("\n")
                  .map((item, index) => (
                    <div key={index}>{item}</div>
                  ))}
                <br />
                [다운로드 링크]
                <br />
                https://certifie.onelink.me/2vIN/landing01
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
);

export default WBd1SendTabSub6;
