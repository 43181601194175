import "../GlobalStyle.css";
import "../PCStyle.css";
import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import axios from "axios";
import { AddDotYYYYMMDD, ConvertISOFormatDate } from "../Utils/ConvertNumberFormat";
import Icon from "../Assets/Icons/Icon";
import WidgetNavigation from "../Components/Organism/WidgetNavigation";
import WidgetHeader from "../Components/Organism/WidgetHeader";
import Pagination from "../Components/Pagination";
import { LoadComapanyData } from "../Utils/LoadCompanyData";

const WBd1SendTabSub1 = observer(
  ({
    setIsLoading,
    selectedGroup,
    setSelectedGroup,
    setIsVisibleAddGroup,
    setRequestList,
    currentRequestGroupListData,
    setCurrentRequestGroupListData,
    firstTabMoveToPage2,
  }) => {
    const requestGroupItemsPerPage = 6;
    const [groupList, setGroupList] = useState([]);
    const requestGroupPageCount = Math.ceil(groupList?.length / requestGroupItemsPerPage);
    const userData = LoadComapanyData("userData");

    useEffect(() => {
      setIsLoading(true);
      const getGroupList = async () => {
        const endPoint = "https://dml1u35aa3.execute-api.ap-northeast-2.amazonaws.com/product/recipient/group";
        try {
          const response = await axios.get(endPoint, {
            params: {
              email: userData.companyEmail,
            },
          });
          if (response.data.status === "그룹 조회 성공") {
            setGroupList(response.data.data);
          } else {
            alert("필수 서류 요청 발신 조회에 실패하였습니다. 다시 시도해주세요.");
            setIsLoading(false);
          }
        } catch (error) {
          console.error("Error : ", error);
          alert("필수 서류 요청 발신 조회 통신을 실패하였습니다. 다시 시도해주세요.");
          setIsLoading(false);
        }
      };

      const getRequestList = async () => {
        const endPoint = "https://dml1u35aa3.execute-api.ap-northeast-2.amazonaws.com/product/recipient";
        try {
          const response = await axios.get(endPoint, {
            params: {
              email: userData.companyEmail,
            },
          });
          if (response.data.status === "대상자 확인 완료") {
            console.log("response.data.data : ", response.data.data);
            setRequestList(response.data.data);
            setIsLoading(false);
          } else {
            alert("필수 서류 요청 발신 조회에 실패하였습니다. 다시 시도해주세요.");
            setIsLoading(false);
          }
        } catch (error) {
          console.error("Error : ", error);
          alert("필수 서류 요청 발신 조회 통신을 실패하였습니다. 다시 시도해주세요.");
          setIsLoading(false);
        }
      };

      getGroupList();
      getRequestList();
    }, [setIsLoading, setRequestList, userData.companyEmail]);

    const onSelectGroup = (groupData) => {
      if (selectedGroup && selectedGroup.id === groupData.id) {
        setSelectedGroup(null); // Deselect
      } else {
        setSelectedGroup(groupData); // Select
      }
    };

    // useEffect(() => {
    //   setCurrentRequestGroupListData(groupList.slice(0, requestGroupItemsPerPage));
    //   setCurrentInquiryGroupListData(groupList.slice(0, inquiryGroupItemsPerPage));
    // }, [groupList]);

    const handleRequestGroupListPageChange = (page) => {
      const start = (page - 1) * requestGroupItemsPerPage;
      const end = start + requestGroupItemsPerPage;
      setCurrentRequestGroupListData(groupList.slice(start, end));
    };

    // const firstTabMoveToPage2 = () => {
    //   if (!selectedGroup) {
    //     alert("필수 서류를 분류할 그룹을 선택해주세요.");
    //   } else {
    //     setFirstTabPage1(false);
    //     setFirstTabPage2(true);
    //     setFirstTabPage3(false);
    //     setFirstTabPage4(false);
    //     setFirstTabPage5(false);
    //     setFirstTabPage6(false);
    //   }
    // };

    return (
      <div className="Widget_Contents_CT">
        <div className="Widget_Contents_Upper">
          <WidgetNavigation nextButtonLabel={"다음"} onClickNextButton={firstTabMoveToPage2} />
          <WidgetHeader
            titleText={"필수 서류를 분류할 그룹을 선택해주세요."}
            bodyText={"필수 서류 전송 요청을 그룹별로 분류하여 관리할 수 있습니다."}
            pageNumber={"1 / 5"}
          />
          {currentRequestGroupListData.map((listItem) => (
            <React.Fragment key={listItem.id}>
              <div className="Widget_Checkbox_Card_CT" style={{ marginBottom: "12px" }}>
                <div className="Widget_Checkbox_Card_Left">
                  <div className="Widget_Checkbox_Card_Title_Copy">{listItem.groupName}</div>
                </div>
                <div className="Widget_Checkbox_Card_Right">
                  {listItem.creationDate && (
                    <div className="Widget_Checkbox_Card_Sub_Copy" style={{ marginRight: "16px" }}>
                      {AddDotYYYYMMDD(ConvertISOFormatDate(listItem.creationDate))} 생성
                    </div>
                  )}
                  <button className="Widget_Checkbox_Card_Icon" onClick={() => onSelectGroup(listItem)}>
                    <Icon
                      name={selectedGroup && selectedGroup.id === listItem.id ? "CheckBoxFill" : "CheckBoxBlank"}
                      size={"24"}
                      color={selectedGroup && selectedGroup.id === listItem.id ? "#3769fc" : "#aaaaaa"}
                    />
                  </button>
                </div>
              </div>
            </React.Fragment>
          ))}
        </div>
        <div className="Widget_Contents_Lower">
          {requestGroupPageCount > 1 ? (
            <Pagination totalItems={groupList.length} itemsPerPage={requestGroupItemsPerPage} onPageChange={handleRequestGroupListPageChange} />
          ) : null}
          <div className="Widget_Divider" style={{ marginBottom: "24px", marginTop: "24px" }} />
          <button className="Widget_Solid_Button" onClick={() => setIsVisibleAddGroup(true)}>
            <Icon name={"Add"} size={"24"} color={"#3769fc"} />
            <div className="Widget_Solid_Button_Label">그룹 추가</div>
          </button>
        </div>
      </div>
    );
  }
);

export default WBd1SendTabSub1;
