import "../GlobalStyle.css";
import "../PCStyle.css";
import "../TabletStyle.css";
import "../MobileStyle.css";
import React from "react";
import { observer } from "mobx-react";
import axios from "axios";
import { ConvertKeysToLowercase } from "../Utils/ConvertStringFormat";
import { AddUnitYYYYMMDD, AddDotYYYYMMDD, AddHyphenTelNumber } from "../Utils/ConvertNumberFormat";
import ParentalAuthorityConsentTable from "../ChartTable/ParentalAuthorityConsentTable";
import Icon from "../Assets/Icons/Icon";

const ParentalAuthorityConsent = observer(
  ({
    inputCompanyName,
    setInputCompanyName,
    setParentalAuthorityConsentInfo,
    companyTelNumber,
    setCompanyTelNumber,
    companyAddress,
    companyRepresentName,
    setCompanyAddress,
    setCompanyRepresentName,
    selectedEmployeeData,
    setSelectedEmployeeData,
    setSelectedEmployees,
    companyEmail,
    guardianBirthday,
    setGuardianBirthday,
    guardianTelNumber,
    setGuardianTelNumber,
    setIsLoading,
  }) => {
    const allInputFilled = () => {
      if (
        selectedEmployeeData?.Documents?.ParentalAuthorityConsent?.GuardianName?.length > 0 &&
        selectedEmployeeData?.Documents?.ParentalAuthorityConsent?.GuardianBirthday?.length === 8 &&
        selectedEmployeeData?.Documents?.ParentalAuthorityConsent?.GuardianAddress?.length > 0 &&
        (selectedEmployeeData?.Documents?.ParentalAuthorityConsent?.GuardianTelNumber?.length === 10 ||
          selectedEmployeeData?.Documents?.ParentalAuthorityConsent?.GuardianTelNumber?.length === 11) &&
        selectedEmployeeData?.Documents?.ParentalAuthorityConsent?.GuardianRelations?.length > 0 &&
        selectedEmployeeData?.Birthday?.length === 8 &&
        selectedEmployeeData?.Address?.length > 0 &&
        inputCompanyName.length > 0 &&
        companyAddress.length > 0 &&
        companyRepresentName.length > 0 &&
        companyTelNumber.length > 0 &&
        selectedEmployeeData?.Documents?.ParentalAuthorityConsent?.ContractDate?.length === 8
      ) {
        return true;
      } else {
        return false;
      }
    };

    const getErrorMessage = () => {
      let errorMessage = "";
      if (!selectedEmployeeData?.Documents?.ParentalAuthorityConsent?.GuardianName?.length > 0) {
        errorMessage += "- 친권자(후견인) 성명을 입력해주세요.";
      }
      if (selectedEmployeeData?.Documents?.ParentalAuthorityConsent?.GuardianBirthday?.length !== 8) {
        errorMessage += errorMessage ? "\n- 친권자(후견인) 생년월일을 입력해주세요." : "- 친권자(후견인) 생년월일을 입력해주세요.";
      }
      if (!selectedEmployeeData?.Documents?.ParentalAuthorityConsent?.GuardianAddress?.length > 0) {
        errorMessage += errorMessage ? "\n- 친권자(후견인) 주소를 입력해주세요." : "- 친권자(후견인) 주소를 입력해주세요.";
      }
      if (
        selectedEmployeeData?.Documents?.ParentalAuthorityConsent?.GuardianTelNumber?.length !== 10 ||
        selectedEmployeeData?.Documents?.ParentalAuthorityConsent?.GuardianTelNumber?.length !== 11
      ) {
        errorMessage += errorMessage ? "\n- 친권자(후견인) 연락처를 입력해주세요." : "- 친권자(후견인) 연락처를 입력해주세요.";
      }
      if (!selectedEmployeeData?.Documents?.ParentalAuthorityConsent?.GuardianRelations?.length > 0) {
        errorMessage += errorMessage ? "\n- 연소근로자와의 관계를 입력해주세요." : "- 연소근로자와의 관계를 입력해주세요.";
      }
      if (selectedEmployeeData.Birthday.length !== 8) {
        errorMessage += errorMessage
          ? `\n- ${selectedEmployeeData?.Name} 님의 생년월일을 입력해주세요.`
          : `- ${selectedEmployeeData?.Name} 님의 생년월일을 입력해주세요.`;
      }
      if (!selectedEmployeeData?.Address?.length > 0) {
        errorMessage += errorMessage
          ? `\n- ${selectedEmployeeData?.Name} 님의 주소를 입력해주세요.`
          : `- ${selectedEmployeeData?.Name} 님의 주소를 입력해주세요.`;
      }
      if (!inputCompanyName.length > 0) {
        errorMessage += errorMessage ? "\n- 사업장명을 입력해주세요." : "- 사업장명을 입력해주세요.";
      }
      if (!companyAddress.length > 0) {
        errorMessage += errorMessage ? "\n- 사업장 주소를 입력해주세요." : "- 사업장 주소를 입력해주세요.";
      }
      if (!companyRepresentName.length > 0) {
        errorMessage += errorMessage ? "\n- 대표자명을 입력해주세요." : "- 대표자명을 입력해주세요.";
      }
      if (!companyTelNumber.length > 0) {
        errorMessage += errorMessage ? "\n- 사업장 전화번호를 입력해주세요." : "- 사업장 전화번호를 입력해주세요.";
      }

      if (selectedEmployeeData?.Documents?.ParentalAuthorityConsent?.ContractDate?.length !== 8) {
        errorMessage += errorMessage ? "\n- 계약일을 입력해주세요." : "- 계약일을 입력해주세요.";
      }
      return errorMessage;
    };

    const handleSelectedEmployeeDataChange = (field, value, nestedField) => {
      setSelectedEmployeeData((prevData) => {
        const updatedData = { ...prevData };

        if (nestedField) {
          if (!updatedData.Documents) {
            updatedData.Documents = {};
          }
          if (!updatedData.Documents.ParentalAuthorityConsent) {
            updatedData.Documents.ParentalAuthorityConsent = {};
          }
          updatedData.Documents.ParentalAuthorityConsent[nestedField] = value;
        } else {
          updatedData[field] = value;
        }

        // selectedEmployees 배열을 업데이트
        setSelectedEmployees((prevEmployees) => prevEmployees.map((employee) => (employee.ID === updatedData.ID ? updatedData : employee)));

        return updatedData;
      });
    };

    const onChangeStateCompanyNameData = (value) => {
      setInputCompanyName(value);
      handleSelectedEmployeeDataChange("Documents", value, "InputCompanyName");
    };

    const onChangeStateCompanyAddressData = (value) => {
      setCompanyAddress(value);
      handleSelectedEmployeeDataChange("Documents", value, "CompanyAddress");
    };

    const onChangeStateCompanyRepresentNameData = (value) => {
      setCompanyRepresentName(value);
      handleSelectedEmployeeDataChange("Documents", value, "CompanyRepresentName");
    };

    const onChangeStateCompanyTelNumberData = (value) => {
      setCompanyTelNumber(value);
      handleSelectedEmployeeDataChange("Documents", value, "CompanyTelNumber");
    };

    const onChangeStateAddressData = (value) => {
      handleSelectedEmployeeDataChange("Documents", value, "Address");
      handleSelectedEmployeeDataChange("Address", value);
    };

    const onChangeStateBirthdayData = (value) => {
      handleSelectedEmployeeDataChange("Documents", value, "Birthday");
      handleSelectedEmployeeDataChange("Birthday", value);
    };

    const onChangeStateGuardianBirthdayData = (value) => {
      setGuardianBirthday(value);
      handleSelectedEmployeeDataChange("Documents", value, "GuardianBirthday");
    };

    const onChangeStateGuardianTelNumberData = (value) => {
      setGuardianTelNumber(value);
      handleSelectedEmployeeDataChange("Documents", value, "GuardianTelNumber");
    };

    const onChangeContractDateData = (value) => {
      handleSelectedEmployeeDataChange("Documents", value, "ContractDate");
      handleSelectedEmployeeDataChange("Documents", inputCompanyName, "InputCompanyName");
      handleSelectedEmployeeDataChange("Documents", companyRepresentName, "CompanyRepresentName");
      handleSelectedEmployeeDataChange("Documents", companyAddress, "CompanyAddress");
      handleSelectedEmployeeDataChange("Documents", guardianBirthday, "GuardianBirthday");
      handleSelectedEmployeeDataChange("Documents", guardianTelNumber, "GuardianTelNumber");
      handleSelectedEmployeeDataChange("Documents", selectedEmployeeData?.Name, "Name");
      handleSelectedEmployeeDataChange("Documents", selectedEmployeeData?.Birthday, "Birthday");
      handleSelectedEmployeeDataChange("Documents", selectedEmployeeData?.Address, "Address");
      handleSelectedEmployeeDataChange("Documents", selectedEmployeeData?.TelNumber, "TelNumber");
    };

    const onClickConfirmInput = async () => {
      if (allInputFilled()) {
        setIsLoading(true);

        const selectedEmployeeDataCopy = { ...selectedEmployeeData };

        selectedEmployeeDataCopy.TelNumber = AddHyphenTelNumber(selectedEmployeeDataCopy?.TelNumber);
        selectedEmployeeDataCopy.Documents.ParentalAuthorityConsent.TelNumber = AddHyphenTelNumber(
          selectedEmployeeDataCopy?.Documents?.ParentalAuthorityConsent?.TelNumber
        );
        selectedEmployeeDataCopy.Documents.ParentalAuthorityConsent.Birthday = AddDotYYYYMMDD(
          selectedEmployeeDataCopy?.Documents?.ParentalAuthorityConsent?.Birthday
        );
        selectedEmployeeDataCopy.Documents.ParentalAuthorityConsent.GuardianBirthday = AddDotYYYYMMDD(
          selectedEmployeeDataCopy?.Documents?.ParentalAuthorityConsent?.GuardianBirthday
        );
        selectedEmployeeDataCopy.Documents.ParentalAuthorityConsent.GuardianTelNumber = AddHyphenTelNumber(
          selectedEmployeeDataCopy?.Documents?.ParentalAuthorityConsent?.GuardianTelNumber
        );

        try {
          const endPoint = "https://dml1u35aa3.execute-api.ap-northeast-2.amazonaws.com/product/pdf/parental-consent";
          const response = await axios.post(endPoint, {
            email: companyEmail,
            telNumber: selectedEmployeeDataCopy?.TelNumber,
            name: selectedEmployeeData?.Name,
            data: ConvertKeysToLowercase(selectedEmployeeDataCopy?.Documents?.ParentalAuthorityConsent),
            ...(selectedEmployeeData.NameDone ? {} : { generalDocument: true }),
          });

          if (response.data.message === "친권자동의서 생성 완료") {
            handleSelectedEmployeeDataChange("Documents", response.data.url, "Url");
            setTimeout(async () => {
              setIsLoading(false);
              setParentalAuthorityConsentInfo(false);
              alert("친권자(후견인) 동의서 작성을 완료했습니다.");
            }, 100);
          } else {
            setIsLoading(false);
            setParentalAuthorityConsentInfo(false);
            alert("친권자(후견인) 동의서 작성에 실패하였습니다. 다시 한번 시도해 주세요.");
          }
        } catch (error) {
          console.log("ParentalAuthorityConsent ERROR : ", error);
          setIsLoading(false);
          setParentalAuthorityConsentInfo(false);
          alert("친권자(후견인) 동의서 작성에 실패하였습니다. 다시 한번 시도해 주세요.");
        }
      } else {
        alert(`${getErrorMessage()}`);
      }
    };

    return (
      <div className="Signin_CompleteModal_BG">
        <div className="ContractInput_Modal_CT">
          <div className="ContractInput_Close_CT">
            <div className="CheckList_InfoModal_Title_Copy">친권자(후견인) 동의서</div>
            <div className="CheckList_InfoModal_Icon_CT">
              <button className="CheckList_Close_Button" onClick={() => setParentalAuthorityConsentInfo(false)}>
                <Icon name={"Close"} size={"24"} />
              </button>
            </div>
          </div>
          <div className="ContractInput_Contents_CT">
            <div className="ContractInput_Left_CT">
              <div className="ContractInput_Left_Content_CT">
                <ParentalAuthorityConsentTable
                  type={"parent"}
                  guardianName={selectedEmployeeData?.Documents?.ParentalAuthorityConsent?.GuardianName}
                  guardianBirthday={selectedEmployeeData?.Documents?.ParentalAuthorityConsent?.GuardianBirthday}
                  guardianAddress={selectedEmployeeData?.Documents?.ParentalAuthorityConsent?.GuardianAddress}
                  guardianTelNumber={selectedEmployeeData?.Documents?.ParentalAuthorityConsent?.GuardianTelNumber}
                  guardianRelations={selectedEmployeeData?.Documents?.ParentalAuthorityConsent?.GuardianRelations}
                />
                <ParentalAuthorityConsentTable
                  type={"teen"}
                  employeeName={selectedEmployeeData?.Name}
                  employeeBirthday={selectedEmployeeData?.Birthday}
                  employeeAddress={selectedEmployeeData?.Address}
                  employeeTelNumber={selectedEmployeeData?.TelNumber}
                />

                <ParentalAuthorityConsentTable
                  type={"company"}
                  inputCompanyName={inputCompanyName}
                  companyAddress={companyAddress}
                  companyRepresentName={companyRepresentName}
                  companyTelNumber={companyTelNumber}
                />
                <div className="ContractInput_Body_Copy" style={{ marginTop: "24px" }}>
                  본인은 위연소근로자{" "}
                  {selectedEmployeeData?.Name && selectedEmployeeData?.Name?.length > 0 ? (
                    <span style={{ color: "rgb(55, 105, 252)" }}>{selectedEmployeeData?.Name}</span>
                  ) : (
                    <span
                      style={{
                        color: "rgb(216, 44, 44)",
                        backgroundColor: "rgb(255, 239, 190)",
                      }}
                    >
                      ______
                    </span>
                  )}{" "}
                  이/가 위 사업장에 근로하는 것에 대하여 동의합니다.
                </div>
                <div className="ContractInput_Date_Copy_CT">
                  <div className="ContractInput_Date_Copy">
                    {selectedEmployeeData?.Documents?.ParentalAuthorityConsent?.ContractDate &&
                    selectedEmployeeData?.Documents?.ParentalAuthorityConsent?.ContractDate?.length === 8 ? (
                      <span>{AddUnitYYYYMMDD(selectedEmployeeData.Documents.ParentalAuthorityConsent.ContractDate)}</span>
                    ) : (
                      <span
                        style={{
                          color: "rgb(216, 44, 44)",
                          backgroundColor: "rgb(255, 239, 190)",
                        }}
                      >
                        ____ 년 __ 월 __ 일
                      </span>
                    )}
                  </div>
                </div>

                <div
                  className="ContractInput_SubBody_CT"
                  style={{
                    minWidth: "240px",
                    maxWidth: "420px",
                    marginTop: "24px",
                    justifyContent: "space-between",
                  }}
                >
                  <div className="ContractInput_Body_Copy" style={{ marginTop: "4px" }}>
                    친권자(후견인) : {selectedEmployeeData?.Documents?.ParentalAuthorityConsent?.GuardianName || ""}
                  </div>
                  <div className="ContractInput_Body_Copy" style={{ marginTop: "4px", marginLeft: "24px" }}>
                    (서명 또는 인)
                  </div>
                </div>
                <div className="ContractInput_Body_Copy" style={{ marginTop: "4px" }}>
                  첨 부 : 가족관계증명서 1부
                </div>
              </div>
            </div>
            <div className="ContractInput_VerticalDivider" />
            <div className="ContractInput_Right_CT">
              <div className="Contract_UserData_CT">
                <div className="Contract_Body_Copy" style={{ marginBottom: "8px" }}>
                  <div className="Contract_Label_Copy">이름</div> | {selectedEmployeeData?.Name}
                </div>
                <div className="Contract_Body_Copy" style={{ marginBottom: "8px" }}>
                  <div className="Contract_Label_Copy">생년월일</div> | {AddUnitYYYYMMDD(selectedEmployeeData?.Birthday || "")}
                </div>
                <div className="Contract_Body_Copy">
                  <div className="Contract_Label_Copy">전화번호</div> | {AddHyphenTelNumber(selectedEmployeeData?.TelNumber)}
                </div>
                <div className="Contract_Divider"> </div>
              </div>

              <div className="ContractInput_InputField">
                <div className="ContractInput_InputField_Label_CT">
                  <div className="Profile_Label_Copy">친권자(후견인) 성명</div>
                  <div
                    className={
                      selectedEmployeeData?.Documents?.ParentalAuthorityConsent?.GuardianName &&
                      selectedEmployeeData?.Documents?.ParentalAuthorityConsent?.GuardianName?.length > 0
                        ? "Required_Dot_Blue"
                        : "Required_Dot_Red"
                    }
                  />
                </div>
                <input
                  className="ContractInput_LongInput_BG"
                  value={selectedEmployeeData?.Documents?.ParentalAuthorityConsent?.GuardianName || ""}
                  onChange={(e) => handleSelectedEmployeeDataChange("Documents", e.target.value, "GuardianName")}
                  placeholder="친권자(후견인) 성명을 입력해주세요."
                  name="GuardianName"
                  required
                />
              </div>
              <div className="ContractInput_InputField">
                <div className="ContractInput_InputField_Label_CT">
                  <div className="Profile_Label_Copy">친권자(후견인) 생년월일</div>
                  <div className={guardianBirthday && guardianBirthday?.length === 8 ? "Required_Dot_Blue" : "Required_Dot_Red"} />
                </div>
                <input
                  className="ContractInput_LongInput_BG"
                  value={guardianBirthday}
                  // onChange={(e) => handleSelectedEmployeeDataChange("Documents", e.target.value, "GuardianBirthday")}
                  onChange={(e) => onChangeStateGuardianBirthdayData(e.target.value)}
                  placeholder="친권자(후견인) 생년월일을 입력해주세요. (YYYYMMDD)"
                  name="GuardianBirthday"
                  required
                />
              </div>
              <div className="ContractInput_InputField">
                <div className="ContractInput_InputField_Label_CT">
                  <div className="Profile_Label_Copy">친권자(후견인) 주소</div>
                  <div
                    className={
                      selectedEmployeeData?.Documents?.ParentalAuthorityConsent?.GuardianAddress &&
                      selectedEmployeeData?.Documents?.ParentalAuthorityConsent?.GuardianAddress?.length > 0
                        ? "Required_Dot_Blue"
                        : "Required_Dot_Red"
                    }
                  />
                </div>
                <input
                  className="ContractInput_LongInput_BG"
                  value={selectedEmployeeData?.Documents?.ParentalAuthorityConsent?.GuardianAddress || ""}
                  onChange={(e) => handleSelectedEmployeeDataChange("Documents", e.target.value, "GuardianAddress")}
                  placeholder="친권자(후견인) 주소를 입력해주세요."
                  name="GuardianAddress"
                  required
                />
              </div>
              <div className="ContractInput_InputField">
                <div className="ContractInput_InputField_Label_CT">
                  <div className="Profile_Label_Copy">친권자(후견인) 연락처</div>
                  <div
                    className={
                      guardianTelNumber && (guardianTelNumber?.length === 10 || guardianTelNumber?.length === 11)
                        ? "Required_Dot_Blue"
                        : "Required_Dot_Red"
                    }
                  />
                </div>
                <input
                  className="ContractInput_LongInput_BG"
                  value={guardianTelNumber}
                  // onChange={(e) => handleSelectedEmployeeDataChange("Documents", e.target.value, "GuardianTelNumber")}
                  onChange={(e) => onChangeStateGuardianTelNumberData(e.target.value)}
                  placeholder="친권자(후견인) 연락처를 입력해주세요."
                  name="GuardianTelNumber"
                  required
                />
              </div>
              <div className="ContractInput_InputField">
                <div className="ContractInput_InputField_Label_CT">
                  <div className="Profile_Label_Copy">연소근로자와의 관계</div>
                  <div
                    className={
                      selectedEmployeeData?.Documents?.ParentalAuthorityConsent?.GuardianRelations &&
                      selectedEmployeeData?.Documents?.ParentalAuthorityConsent?.GuardianRelations?.length > 0
                        ? "Required_Dot_Blue"
                        : "Required_Dot_Red"
                    }
                  />
                </div>
                <input
                  className="ContractInput_LongInput_BG"
                  value={selectedEmployeeData?.Documents?.ParentalAuthorityConsent?.GuardianRelations || ""}
                  onChange={(e) => handleSelectedEmployeeDataChange("Documents", e.target.value, "GuardianRelations")}
                  placeholder="연소근로자와의 관계를 입력해주세요."
                  name="GuardianRelations"
                  required
                />
              </div>
              {selectedEmployeeData?.NameDone ? (
                <div className="ContractInput_InputField">
                  <div className="ContractInput_InputField_Label_CT">
                    <div className="Profile_Label_Copy">{selectedEmployeeData?.Name} 님의 생년월일</div>
                    <div
                      className={
                        (selectedEmployeeData?.Birthday && selectedEmployeeData?.Birthday?.length === 8) ||
                        (selectedEmployeeData?.Documents?.ParentalAuthorityConsent?.Birthday &&
                          selectedEmployeeData?.Documents?.ParentalAuthorityConsent?.Birthday?.length === 8)
                          ? "Required_Dot_Blue"
                          : "Required_Dot_Red"
                      }
                    />
                  </div>
                  <input
                    className="ContractInput_LongInput_BG"
                    value={selectedEmployeeData?.Documents?.ParentalAuthorityConsent?.Birthday || selectedEmployeeData?.Birthday || ""}
                    onChange={(e) => onChangeStateBirthdayData(e.target.value)}
                    placeholder="YYYYMMDD 형식으로 입력해주세요."
                    name="NewEmployeeBirthday"
                    required
                  />
                </div>
              ) : null}
              <div className="ContractInput_InputField">
                <div className="ContractInput_InputField_Label_CT">
                  <div className="Profile_Label_Copy">{selectedEmployeeData?.Name} 님의 주소</div>
                  <div
                    className={
                      (selectedEmployeeData?.Address && selectedEmployeeData?.Address?.length > 0) ||
                      (selectedEmployeeData?.Documents?.ParentalAuthorityConsent?.Address &&
                        selectedEmployeeData?.Documents?.ParentalAuthorityConsent?.Address?.length > 0)
                        ? "Required_Dot_Blue"
                        : "Required_Dot_Red"
                    }
                  />
                </div>
                <input
                  className="ContractInput_LongInput_BG"
                  value={selectedEmployeeData?.Documents?.ParentalAuthorityConsent?.Address || selectedEmployeeData?.Address || ""}
                  onChange={(e) => onChangeStateAddressData(e.target.value)}
                  placeholder="연소근로자의 주소를 입력해주세요."
                  name="NewTeenEmployeeAddress"
                  required
                />
              </div>

              <div className="ContractInput_InputField">
                <div className="ContractInput_InputField_Label_CT">
                  <div className="Profile_Label_Copy">사업장명</div>
                  <div className={inputCompanyName.length > 0 ? "Required_Dot_Blue" : "Required_Dot_Red"} />
                </div>
                <input
                  className="ContractInput_LongInput_BG"
                  value={inputCompanyName}
                  onChange={(e) => onChangeStateCompanyNameData(e.target.value)}
                  placeholder="사업장명을 입력해주세요."
                  name="CompanyName"
                  required
                />
              </div>

              <div className="ContractInput_InputField">
                <div className="ContractInput_InputField_Label_CT">
                  <div className="Profile_Label_Copy">사업장 주소</div>
                  <div className={companyAddress.length > 0 ? "Required_Dot_Blue" : "Required_Dot_Red"} />
                </div>
                <input
                  className="ContractInput_LongInput_BG"
                  value={companyAddress}
                  onChange={(e) => onChangeStateCompanyAddressData(e.target.value)}
                  placeholder="사업장 주소를 입력해주세요."
                  name="CompanyAddress"
                  required
                />
              </div>

              <div className="ContractInput_InputField">
                <div className="ContractInput_InputField_Label_CT">
                  <div className="Profile_Label_Copy">대표자명</div>
                  <div className={companyRepresentName.length > 0 ? "Required_Dot_Blue" : "Required_Dot_Red"} />
                </div>
                <input
                  className="ContractInput_LongInput_BG"
                  value={companyRepresentName}
                  onChange={(e) => onChangeStateCompanyRepresentNameData(e.target.value)}
                  placeholder="대표자명을 입력해주세요."
                  name="CompanyRepresentName"
                  required
                />
              </div>
              <div className="ContractInput_InputField">
                <div className="ContractInput_InputField_Label_CT">
                  <div className="Profile_Label_Copy">사업장 전화번호</div>
                  <div className={companyTelNumber.length > 0 ? "Required_Dot_Blue" : "Required_Dot_Red"} />
                </div>
                <input
                  className="ContractInput_LongInput_BG"
                  value={companyTelNumber}
                  onChange={(e) => onChangeStateCompanyTelNumberData(e.target.value)}
                  placeholder="사업장 전화번호를 입력해주세요."
                  name="CompanyTelNumber"
                  required
                />
              </div>

              <div className="ContractInput_InputField">
                <div className="ContractInput_InputField_Label_CT">
                  <div className="Profile_Label_Copy">계약일</div>
                  <div
                    className={
                      selectedEmployeeData?.Documents?.ParentalAuthorityConsent?.ContractDate &&
                      selectedEmployeeData?.Documents?.ParentalAuthorityConsent?.ContractDate?.length === 8
                        ? "Required_Dot_Blue"
                        : "Required_Dot_Red"
                    }
                  />
                </div>
                <input
                  className="ContractInput_LongInput_BG"
                  value={selectedEmployeeData?.Documents?.ParentalAuthorityConsent?.ContractDate || ""}
                  onChange={(e) => onChangeContractDateData(e.target.value)}
                  placeholder="YYYYMMDD 형식으로 입력해주세요."
                  name="ContractDate"
                  required
                />
              </div>

              <button className="ContractInput_ConfirmButton_BG" type="button" onClick={onClickConfirmInput}>
                작성 완료
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
);

export default ParentalAuthorityConsent;
