import "../GlobalStyle.css";
import "../PCStyle.css";
import React from "react";
import { observer } from "mobx-react";
import Icon from "../Assets/Icons/Icon";
import WidgetNavigation from "../Components/Organism/WidgetNavigation";
import WidgetHeader from "../Components/Organism/WidgetHeader";

const WBd1SendTabSub2 = observer(({ firstTabMoveToPage1, firstTabMoveToPage3, firstTabMoveToPage4, setFirstTabDesignate, setFirstTabLink }) => {
  const handleFirstTabDesignate = () => {
    setFirstTabDesignate(true);
    setFirstTabLink(false);
    firstTabMoveToPage3();
  };

  const handleFirstTabLink = () => {
    setFirstTabDesignate(false);
    setFirstTabLink(true);
    firstTabMoveToPage4();
  };

  return (
    <div className="Widget_Contents_CT">
      <div className="Widget_Contents_Upper">
        <WidgetNavigation previousButtonLabel={"이전"} onClickPreviousButton={firstTabMoveToPage1} />
        <WidgetHeader
          titleText={"필수 서류 요청 방식을 선택해주세요."}
          bodyText={"대상자를 지정하여 필수 서류를 요청할 수 있고 필수 서류 요청을 대상자가\n각자 진행할 수 있는 링크를 생성할 수 도 있습니다."}
          pageNumber={"2 / 5"}
        />
        <div className="Widget_UnScroll_Contents">
          <button className="WidgetList_SubscriptionTextButton" onClick={handleFirstTabDesignate}>
            <div className="WidgetList_SubscriptionTextButton_TextContainer">
              <div className="WidgetList_SubscriptionTextButton_Label">대상자 지정</div>
              <div className="WidgetList_SubscriptionTextButton_Body">필수 서류 요청 메시지를 전송할 대상자의 이름과 전화번호를 직접 입력합니다.</div>
            </div>
            <Icon name={"ArrowForward"} size={32} color={"#aaaaaa"} />
          </button>
          <button className="WidgetList_SubscriptionTextButton" style={{ marginTop: "12px" }} onClick={handleFirstTabLink}>
            <div className="WidgetList_SubscriptionTextButton_TextContainer">
              <div className="WidgetList_SubscriptionTextButton_Label">링크 생성</div>
              <div className="WidgetList_SubscriptionTextButton_Body">접속시 대상자가 이름과 전화번호를 직접 입력할 수 있는 링크를 생성합니다.</div>
            </div>
            <Icon name={"ArrowForward"} size={32} color={"#aaaaaa"} />
          </button>
        </div>
      </div>
    </div>
  );
});

export default WBd1SendTabSub2;
