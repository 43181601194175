import "../GlobalStyle.css";
import "../PCStyle.css";
import React from "react";
import { observer } from "mobx-react";
import CheckListLeft2 from "../Components/CheckListLeft2";
import WidgetNavigation from "../Components/Organism/WidgetNavigation";
import WidgetHeader from "../Components/Organism/WidgetHeader";

const WBd1SendTabSub4 = observer(
  ({
    firstTabMoveToPage2,
    firstTabMoveToPage3,
    firstTabMoveToPage5,
    firstTabMoveToPage6,
    certificate,
    setCertificate,
    resume,
    setResume,
    identityCardImage,
    setIdentityCardImage,
    diploma,
    setDiploma,
    recentTwoYearIncomeReceipt,
    setRecentTwoYearIncomeReceipt,
    recentThreeMonthSalaryStatement,
    setRecentThreeMonthSalaryStatement,
    firstTabDesignate,
    firstTabLink,
    setFirstTabDesignate,
    setFirstTabLink,
  }) => {
    const moveToPage3Designate = () => {
      setCertificate(false);
      setResume(false);
      setIdentityCardImage(false);
      setDiploma(false);
      setRecentTwoYearIncomeReceipt(false);
      setRecentThreeMonthSalaryStatement(false);
      firstTabMoveToPage3();
    };

    const moveToPage2Link = () => {
      setFirstTabDesignate(false);
      setFirstTabLink(false);
      setCertificate(false);
      setResume(false);
      setIdentityCardImage(false);
      setDiploma(false);
      setRecentTwoYearIncomeReceipt(false);
      setRecentThreeMonthSalaryStatement(false);
      firstTabMoveToPage2();
    };

    const moveToPage5Link = () => {
      if (certificate || resume || identityCardImage || diploma || recentTwoYearIncomeReceipt || recentThreeMonthSalaryStatement) {
        firstTabMoveToPage5();
      } else {
        alert("전달 받을 필수 서류를 선택해주세요.");
      }
    };

    const moveToPage6Designate = () => {
      if (certificate || resume || identityCardImage || diploma || recentTwoYearIncomeReceipt || recentThreeMonthSalaryStatement) {
        firstTabMoveToPage6();
      } else {
        alert("전달 받을 필수 서류를 선택해주세요.");
      }
    };

    return (
      <div className="Widget_Contents_CT">
        <div className="Widget_Contents_Upper">
          <WidgetNavigation
            previousButtonLabel={"이전"}
            onClickPreviousButton={firstTabDesignate && !firstTabLink ? moveToPage3Designate : moveToPage2Link}
            nextButtonLabel={"다음"}
            onClickNextButton={firstTabDesignate && !firstTabLink ? moveToPage6Designate : moveToPage5Link}
          />
          <WidgetHeader
            titleText={"대상자로 부터 전달 받을 서류를 선택해주세요."}
            bodyText={
              "대상자에게 필수로 받아야 하는 서류로, 필요하신 서류를 선택하시면\n대상자에게 해당 서류 전달에 대한 안내 문자 메시지가 전송됩니다."
            }
            pageNumber={firstTabDesignate && !firstTabLink ? "4 / 5" : "3 / 5"}
          />
          <div className="Leave_InputField_CT_TBD">
            <div className="CheckList_Divider_TBD" />
            <div className="CheckList_Comp_CT_TBD">
              <CheckListLeft2 label={"경력증명서"} isCheckBox={certificate} setIsCheckBox={setCertificate} />
            </div>
            <div className="CheckList_Divider_TBD" />
            <div className="CheckList_Comp_CT_TBD">
              <CheckListLeft2 label={"이력서"} isCheckBox={resume} setIsCheckBox={setResume} />
            </div>
            <div className="CheckList_Divider_TBD" />
            <div className="CheckList_Comp_CT_TBD">
              <CheckListLeft2 label={"주민등록증 이미지"} isCheckBox={identityCardImage} setIsCheckBox={setIdentityCardImage} />
            </div>
            <div className="CheckList_Divider_TBD" />
            <div className="CheckList_Comp_CT_TBD">
              <CheckListLeft2 label={"졸업증명서"} isCheckBox={diploma} setIsCheckBox={setDiploma} />
            </div>
            <div className="CheckList_Divider_TBD" />
            <div className="CheckList_Comp_CT_TBD">
              <CheckListLeft2
                label={"최근 2년간 근로소득 원천징수 영수증"}
                isCheckBox={recentTwoYearIncomeReceipt}
                setIsCheckBox={setRecentTwoYearIncomeReceipt}
              />
            </div>
            <div className="CheckList_Divider_TBD" />
            <div className="CheckList_Comp_CT_TBD">
              <CheckListLeft2
                label={"최근 3개월간 급여명세서"}
                isCheckBox={recentThreeMonthSalaryStatement}
                setIsCheckBox={setRecentThreeMonthSalaryStatement}
              />
            </div>
            <div className="CheckList_Divider_TBD" />
          </div>
        </div>
      </div>
    );
  }
);

export default WBd1SendTabSub4;
