import "../GlobalStyle.css";
import "../PCStyle.css";
import "../TabletStyle.css";
import "../MobileStyle.css";
import React, { useState } from "react";
import { Select } from "react-dropdown-select";
import { observer } from "mobx-react";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import { LoadComapanyData } from "../Utils/LoadCompanyData";
import Loading from "../Components/Loading";
import NavigationPanel from "../Components/NavigationPanel";
import * as amplitude from "@amplitude/analytics-browser";

const WB31AddEmployeeFileSub3 = observer(({ setEmployee1, setEmployee2, setEmployee3, mappedData }) => {
  const [isLoading, setIsLoading] = useState(false);

  const onClickNext = async () => {
    setIsLoading(true);
    const getSelectArray = JSON.parse(localStorage.getItem("selectArray"));
    let getInputArray = JSON.parse(localStorage.getItem("inputArray"));

    if (getInputArray && getSelectArray) {
      getInputArray = getInputArray.filter((element) => element !== "");

      const updatedArray = [...getSelectArray];
      let inputIndex = 0;
      updatedArray.forEach((element, index) => {
        if (element === "직접 입력") {
          updatedArray[index] = getInputArray[inputIndex];
          inputIndex++;
        }
      });
      let hasName = false;
      let hasTelNumber = false;

      updatedArray.forEach((element) => {
        if (element === "이름") {
          hasName = true;
        } else if (element === "전화번호") {
          hasTelNumber = true;
        }
      });

      const uniqueSet = new Set();
      const duplicates = [];
      for (const item of updatedArray) {
        if (uniqueSet.has(item)) {
          duplicates.push(item);
        } else {
          uniqueSet.add(item);
        }
      }

      const hasNullOrUndefinedOrEmptyString = updatedArray.some((item) => item === null || item === undefined || item === "");
      if (hasNullOrUndefinedOrEmptyString) {
        alert("모든 레이블명을 지정해주세요.");
      } else if (!hasNullOrUndefinedOrEmptyString && duplicates.length === 0 && hasName && hasTelNumber) {
        const stringMap = {
          이름: "Name",
          성별: "Gender",
          생년월일: "Birthday",
          주소: "Address",
          전화번호: "TelNumber",
          이메일: "Email",
          포지션: "Position",
          입사일: "AcqDate",
          부서: "Department",
          "직급/직위": "JobPosition",
          "직무/업무": "Job",
        };

        const UStransformedArray = updatedArray.map((element) => stringMap[element] || element);

        const result = [];
        for (let i = 0; i < mappedData[0].length; i++) {
          const personObj = {};
          for (let j = 0; j < mappedData.length; j++) {
            let value;
            if (mappedData[j][i] === null || mappedData[j][i] === undefined) {
              value = "-";
            } else if (typeof mappedData[j][i] === "number") {
              value = String(mappedData[j][i]);
            } else {
              value = mappedData[j][i];
            }
            const label = UStransformedArray[j];
            personObj[label] = value;
          }
          personObj["ID"] = uuidv4();
          result.push(personObj);
        }

        const userData = LoadComapanyData("userData");

        try {
          const endPoint = "https://dml1u35aa3.execute-api.ap-northeast-2.amazonaws.com/product/employee/multiple";
          const response = await axios.post(endPoint, {
            email: userData.companyEmail,
            data: result,
          });

          if (response.data.status === "직원 정보 저장 성공") {
            amplitude.track("직원 등록 (엑셀파일)3 - 직원 정보 파일을 통해 직원 등록 성공");
            alert("직원 정보 저장에 성공했습니다.");
            localStorage.removeItem("selectArray");
            localStorage.removeItem("inputArray");
            setEmployee1(true);
            setEmployee3(false);
            setIsLoading(false);
          }
        } catch (error) {
          amplitude.track("직원 등록 (엑셀파일)3 - 직원 정보 파일을 통해 직원 등록 실패");
          console.error("Error : ", error);
          localStorage.removeItem("selectArray");
          localStorage.removeItem("inputArray");
          alert(`직원 정보 저장을 실패하였습니다. 다시 시도해주세요. ${error}`);
          setIsLoading(false);
        }
      } else if (!hasNullOrUndefinedOrEmptyString && duplicates.length > 0) {
        setIsLoading(false);
        alert("동일한 레이블이 이미 존재합니다.");
      } else if (!hasNullOrUndefinedOrEmptyString && duplicates.length === 0 && (!hasName || !hasTelNumber)) {
        setIsLoading(false);
        alert("[이름]과 [전화번호]는 필수 레이블입니다.\n[이름] 또는 [전화번호] 정보가 없으시다면 엑셀파일에 추가하여 다시 업로드 해주세요.");
      }
    } else {
      setIsLoading(false);
      alert("모든 레이블명을 지정해주세요.");
    }
  };

  const onClickBack = () => {
    localStorage.removeItem("selectArray");
    localStorage.removeItem("inputArray");
    setEmployee3(false);
    setEmployee2(true);
  };

  const RenderData = () => {
    const labelOptions = [
      {
        value: "이름",
        label: "이름",
      },
      {
        value: "생년월일",
        label: "생년월일",
      },
      {
        value: "성별",
        label: "성별",
      },
      {
        value: "주소",
        label: "주소",
      },
      {
        value: "전화번호",
        label: "전화번호",
      },
      {
        value: "이메일",
        label: "이메일",
      },
      {
        value: "포지션",
        label: "포지션",
      },
      {
        value: "입사일",
        label: "입사일",
      },
      {
        value: "부서",
        label: "부서",
      },
      {
        value: "직급/직위",
        label: "직급/직위",
      },
      {
        value: "직무/업무",
        label: "직무/업무",
      },
      {
        value: "직접 입력",
        label: "직접 입력",
      },
    ];

    const [selectedLabelArray, setSelectedLabelArray] = useState(Array(mappedData.length).fill(""));

    const [etcLabelArray, setEtcLabelArray] = useState(Array(mappedData.length).fill(""));

    const handleSelectChange = (index, selected) => {
      const newSelectedLabelArray = [...selectedLabelArray];

      if (selected && selected.length > 0 && selected[0].hasOwnProperty("label")) {
        const newLabel = selected[0].label;
        if (newLabel !== "직접 입력" && selectedLabelArray.some((option) => option === newLabel)) {
          alert("동일한 레이블이 이미 선택되었습니다.");
          setSelectedLabelArray((prevOptions) => prevOptions.map((option, idx) => (idx === index ? "" : option)));
          return;
        }
        newSelectedLabelArray[index] = newLabel;
        setSelectedLabelArray(newSelectedLabelArray);
        localStorage.setItem("selectArray", JSON.stringify(newSelectedLabelArray));
      }
    };

    const onChangeEtcLabel = (e, index) => {
      const newEtcLabelArray = [...etcLabelArray];
      newEtcLabelArray[index] = e.target.value;
      setEtcLabelArray(newEtcLabelArray);
      localStorage.setItem("inputArray", JSON.stringify(newEtcLabelArray));
    };

    return mappedData.map((column, index) => (
      <div key={index}>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div>
              <Select
                options={labelOptions}
                onChange={(selected) => handleSelectChange(index, selected)}
                value={selectedLabelArray[index]}
                style={{
                  width: 120,
                  height: 40,
                  borderRadius: 8,
                  borderStyle: "solid",
                  borderWidth: 1,
                  borderColor: "#AAAAAA",
                  paddingLeft: 12,
                  paddingRight: 12,
                  marginBottom: 12,
                }}
              />
            </div>

            {selectedLabelArray[index] !== "" && selectedLabelArray[index] !== "직접 입력" ? (
              <div
                className="EmployeeResist_ExcelResult_Copy"
                style={{
                  color: "rgb(55, 105, 252)",
                  fontWeight: "600",
                  marginTop: "32px",
                }}
              >
                {selectedLabelArray[index]}
              </div>
            ) : selectedLabelArray[index] === "직접 입력" ? (
              <div>
                <input
                  className="EmployeeResist_Input_BG"
                  value={etcLabelArray[index]}
                  onChange={(e) => {
                    onChangeEtcLabel(e, index);
                  }}
                  placeholder="직접 입력"
                  name="etcLabelType"
                  style={{
                    borderColor: "rgb(170, 170, 170)",
                    marginBottom: 8,
                    marginTop: 12,
                  }}
                  required
                />
              </div>
            ) : (
              <div
                className="EmployeeResist_ExcelResult_Copy"
                style={{
                  color: "rgb(255, 115, 115)",
                  fontWeight: "600",
                  marginTop: "32px",
                }}
              >
                미정
              </div>
            )}
            <div className="EmployeeResist_Divider" />
            {column.map((value) => (
              <>
                <div className="EmployeeResist_ExcelResult_Copy">{value === null || value === undefined ? "-" : value}</div>
              </>
            ))}
          </div>
          <div className="EmployeeResist_Divider_V" style={{ marginRight: "24px" }} />
        </div>
      </div>
    ));
  };

  return (
    <>
      {isLoading ? <Loading /> : null}
      <div className="AllContent_Default_V2">
        <div className="Leave_LeftArea_CT">
          <div className="Leave_Main_Copy">
            직원 정보가 담긴 엑셀 파일을 <br />
            업로드 해주세요.
          </div>
          <div className="Leave_Sub_Copy">
            .xlsx, .xlsm, .xlsb, .xltx, .xltm, .xls, .xlt, .xls, .xml, .xlam, .xla, .xlw 확장자 파일을 업로드 하실 수 있습니다.
          </div>
          <div className="EmployeeResist_ExcelResult_CT" style={{ marginTop: "48px" }}>
            {mappedData ? <RenderData /> : null}
          </div>
        </div>
        <div className="EmployeeResist_Vertical_Divider" />
        <div className="Leave_RightArea_CT">
          <NavigationPanel backButtonText={"이전"} onClickBack={onClickBack} nextButtonText={"등록"} onClickNext={onClickNext} />
        </div>
      </div>
    </>
  );
});

export default WB31AddEmployeeFileSub3;
