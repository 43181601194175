import "../GlobalStyle.css";
import "../PCStyle.css";
import React from "react";
import { observer } from "mobx-react";
import { v4 as uuidv4 } from "uuid";
import Icon from "../Assets/Icons/Icon";
import WidgetNavigation from "../Components/Organism/WidgetNavigation";
import WidgetHeader from "../Components/Organism/WidgetHeader";

const WBd1SendTabSub3 = observer(
  ({ firstTabMoveToPage2, firstTabMoveToPage4, resistEmployees, setResistEmployees, setFirstTabDesignate, setFirstTabLink }) => {
    const backToPage2 = () => {
      setFirstTabDesignate(false);
      setFirstTabLink(false);
      setResistEmployees([{ name: "", telNumber: "", nameDone: false, telNumberDone: false, ID: uuidv4() }]);
      firstTabMoveToPage2();
    };

    const moveToPage4 = () => {
      const allNamesDone = resistEmployees.every((employee) => employee.nameDone);
      const allTelNumbersDone = resistEmployees.every((employee) => employee.telNumberDone);
      if (allNamesDone && allTelNumbersDone) {
        firstTabMoveToPage4();
      } else {
        const incompleteEmployees = resistEmployees
          .map((employee, index) => {
            const errors = [];
            if (!employee.nameDone) errors.push("이름");
            if (!employee.telNumberDone) errors.push("전화번호");
            return errors.length > 0 ? `입사자 ${index + 1} : ${errors.join(", ")}` : null;
          })
          .filter(Boolean);

        alert(`다음 정보를 입력해주세요:\n- ${incompleteEmployees.join("\n- ")}`);
      }
    };

    const validateEmployee = (employee) => {
      const numberPattern = /^\d+$/;
      return {
        ...employee,
        nameDone: employee.name.length > 0,
        telNumberDone: employee.telNumber.length >= 10 && employee.telNumber.length <= 11 && numberPattern.test(employee.telNumber),
      };
    };

    const removeEmployee = (index) => {
      setResistEmployees((prevEmployees) => prevEmployees.filter((_, i) => i !== index));
    };

    const handleInputChange = (index, key, value) => {
      setResistEmployees((prevEmployees) => {
        const updatedEmployees = prevEmployees.map((employee, i) => (i === index ? validateEmployee({ ...employee, [key]: value }) : employee));
        return updatedEmployees;
      });
    };

    const addEmployee = () => {
      setResistEmployees((prevEmployees) => [...prevEmployees, { name: "", telNumber: "", nameDone: false, telNumberDone: false, ID: uuidv4() }]);
    };

    return (
      <div className="Widget_Contents_CT">
        <div className="Widget_Contents_Upper">
          <WidgetNavigation
            previousButtonLabel={"이전"}
            onClickPreviousButton={backToPage2}
            nextButtonLabel={"다음"}
            onClickNextButton={moveToPage4}
          />
          <WidgetHeader
            titleText={"대상자의 이름과 전화번호를 입력해주세요."}
            bodyText={"대상자에게 필수 서류 전송을 안내하는 문자메시지를 전송해 드립니다."}
            pageNumber={"3 / 5"}
          />
          <div className="Widget_Scroll_Contents">
            {resistEmployees.map((employee, index) => (
              <div key={index} className="Leave_InputField_CT_TBD" style={{ marginTop: "0px" }}>
                <div className="ProfileDetail_Subject_TBD">
                  <div className="Profile_Label_CT">
                    <div className="Input_Label_Copy">입사자 {index + 1} 이름</div>
                    <div className={employee.nameDone ? "Required_Dot_Blue_TBD" : "Required_Dot_Red_TBD"} />
                  </div>
                  <input
                    className="Leave_LongInput_BG_TBD"
                    value={employee.name}
                    onChange={(e) => handleInputChange(index, "name", e.target.value)}
                    placeholder="입사자의 이름을 입력해주세요."
                    name="employeeName"
                    required
                  />
                </div>
                <div className="ProfileDetail_Subject_TBD">
                  <div className="Profile_Label_CT">
                    <div className="Input_Label_Copy">입사자 {index + 1} 전화번호</div>
                    <div className={employee.telNumberDone ? "Required_Dot_Blue_TBD" : "Required_Dot_Red_TBD"} />
                  </div>
                  <input
                    className="Leave_LongInput_BG_TBD"
                    value={employee.telNumber}
                    onChange={(e) => handleInputChange(index, "telNumber", e.target.value)}
                    placeholder="입사자의 전화번호를 입력해주세요. (숫자만 입력해주세요.)"
                    name="telNumber"
                    required
                  />
                </div>
                {resistEmployees.length > 1 && (
                  <button
                    className="Widget_Solid_Button"
                    style={{
                      backgroundColor: "#FFE9E9",
                      marginBottom: index === resistEmployees.length - 1 ? "24px" : "0px",
                      width: "574px",
                    }}
                    type="button"
                    onClick={() => removeEmployee(index)}
                  >
                    <Icon name={"Delete"} size={"20"} color={"rgb(255, 115, 115)"} />
                    <div className="Widget_Solid_Button_Label" style={{ color: "rgb(255, 115, 115)", marginLeft: "4px" }}>
                      삭제
                    </div>
                  </button>
                )}
                {index !== resistEmployees.length - 1 && <div className="NewEmployee_Divider" />}
              </div>
            ))}
          </div>
        </div>
        <div className="Widget_Contents_Lower">
          <button className="Widget_Solid_Button" type="button" onClick={addEmployee}>
            <Icon name={"Add"} size={"20"} color={"#3769fc"} />
            <div className="Widget_Solid_Button_Label" style={{ marginLeft: "4px" }}>
              입사자 추가
            </div>
          </button>
        </div>
      </div>
    );
  }
);

export default WBd1SendTabSub3;
