import "../GlobalStyle.css";
import "../PCStyle.css";
import "../TabletStyle.css";
import "../MobileStyle.css";
import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import DetailTitle from "../Components/DetailTitle";
import Loading from "../Components/Loading";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { LoadComapanyData } from "../Utils/LoadCompanyData";
import WidgetList from "../Components/Organism/WidgetList";

const WB91ReferEmployeeList = observer(() => {
  const [employeeData, setEmployeeData] = useState([]);
  const [companyEmail, setCompanyEmail] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const userData = LoadComapanyData("userData");
    if (userData) {
      setCompanyEmail(userData.companyEmail);
    } else {
      console.log("No userData found.");
    }
  }, []);

  useEffect(() => {
    if (!companyEmail) return;
    setIsLoading(true);
    const loadEmployeeData = async () => {
      const endPoint = "https://dml1u35aa3.execute-api.ap-northeast-2.amazonaws.com/product/employee";
      try {
        const response = await axios.get(endPoint, {
          params: {
            email: companyEmail,
          },
        });
        if (response.data.status === "직원 정보 조회 성공") {
          const rawEmployeeData = response.data.data.Employee;
          rawEmployeeData.sort((a, b) => {
            return a.Name.localeCompare(b.Name);
          });

          setEmployeeData(rawEmployeeData);
          setIsLoading(false);
        } else {
          alert("직원 정보 조회에 실패하였습니다. 다시 시도해주세요.");
          setIsLoading(false);
        }
      } catch (error) {
        console.error("Error : ", error);
        alert("직원 정보 조회 통신을 실패하였습니다. 다시 시도해주세요.");
        setIsLoading(false);
      }
    };

    loadEmployeeData();
  }, [companyEmail]);

  const handleItemClick = (certificateData) => {
    navigate(`/referEmployeeDetail/${certificateData.ID}`, {
      state: {
        item: certificateData,
      },
    });
  };

  return (
    <div className="FlexContent_Other_V" style={{ marginBottom: "192px" }}>
      <DetailTitle title={"직원 조회"} notUseArrowBack />
      <div className="AllContent_Default_C">
        <div className="Wait_CT" style={{ marginTop: "48px", alignItems: "flex-start" }}>
          {isLoading ? (
            <div className="Wait_Lists_CT">
              <Loading />
            </div>
          ) : !isLoading && employeeData.length === 0 ? (
            <div className="Wait_NoneItem_Copy">등록된 직원 정보가 없습니다.</div>
          ) : !isLoading && employeeData.length > 0 ? (
            // <ReferSelectList
            //   labelList={["이름", "생년월일", "전화번호", "부서", "직급/직위", "입사일"]}
            //   handleItemClick={handleItemClick}
            //   listData={employeeData}
            // />
            <WidgetList
              type={"singleSelect"}
              fieldList={["이름", "생년월일", "전화번호", "부서", "직급/직위", "입사일"]}
              listDataArray={employeeData}
              onClickListItem={handleItemClick}
              itemsPerPage={10}
              height={"586px"}
            />
          ) : (
            <div className="Wait_NoneItem_Copy">등록된 직원 정보가 없습니다.</div>
          )}
        </div>
      </div>
    </div>
  );
});

export default WB91ReferEmployeeList;
