import "../GlobalStyle.css";
import "../PCStyle.css";
import "../TabletStyle.css";
import "../MobileStyle.css";
import React from "react";
import { observer } from "mobx-react";
import axios from "axios";
import { MergeData } from "../Utils/MergeData";
import { ConvertKeysToLowercase, ConvertArrayKeysToLowercase } from "../Utils/ConvertStringFormat";
import { AddUnitYYYYMMDD, AddHyphen13DigitNumber, AddHyphenTelNumber } from "../Utils/ConvertNumberFormat";
import Icon from "../Assets/Icons/Icon";

const StockholdersAgreement = observer(
  ({
    setStockholdersAgreementInfo,
    inputCompanyName,
    setInputCompanyName,
    selectedEmployees,
    setSelectedEmployees,
    stockholdersAgreementData,
    setStockholdersAgreementData,
    companyEmail,
    setIsLoading,
  }) => {
    const allInputFilled = () => {
      if (
        inputCompanyName.length > 0 &&
        stockholdersAgreementData.ExecutivesTenure.length > 0 &&
        stockholdersAgreementData.ExecutionTerm.length > 0 &&
        stockholdersAgreementData.RightTransferTerm.length > 0 &&
        stockholdersAgreementData.StockTransferPrice.length > 0 &&
        stockholdersAgreementData.JurisdictionCourt.length > 0 &&
        stockholdersAgreementData.ContractDate.length === 8 &&
        selectedEmployees.every((employee) => employee.ResidentRegistrationNumber.length === 13) &&
        selectedEmployees.every((employee) => employee.Address.length > 0)
      ) {
        return true;
      } else {
        return false;
      }
    };

    const getErrorMessage = () => {
      let errorMessage = "";

      if (!inputCompanyName.length > 0) {
        errorMessage += "- 회사명을 입력해주세요.";
      }
      if (!stockholdersAgreementData?.ExecutivesTenure.length > 0) {
        errorMessage += errorMessage ? "\n- 임직원 의무 재직기간을 입력해주세요." : "- 임직원 의무 재직기간을 입력해주세요.";
      }
      if (!stockholdersAgreementData?.ExecutionTerm.length > 0) {
        errorMessage += errorMessage ? "\n- 이행 최고기간을 입력해주세요." : "- 이행 최고기간을 입력해주세요.";
      }
      if (!stockholdersAgreementData?.RightTransferTerm.length > 0) {
        errorMessage += errorMessage ? "\n- 권리양도 기한을 입력해주세요." : "- 권리양도 기한을 입력해주세요.";
      }
      if (!stockholdersAgreementData?.StockTransferPrice.length > 0) {
        errorMessage += errorMessage ? "\n- 주식 양도 가격을 입력해주세요." : "- 주식 양도 가격을 입력해주세요.";
      }
      if (!stockholdersAgreementData?.JurisdictionCourt.length > 0) {
        errorMessage += errorMessage ? "\n- 분쟁조절 관할 법원을 입력해주세요." : "- 분쟁조절 관할 법원을 입력해주세요.";
      }
      if (!stockholdersAgreementData?.ContractDate.length === 8) {
        errorMessage += errorMessage ? "\n- 계약일을 입력해주세요." : "- 계약일을 입력해주세요.";
      }
      selectedEmployees.forEach((employee, index) => {
        if (!employee?.ResidentRegistrationNumber?.length === 13) {
          errorMessage += errorMessage
            ? `\n- 주주 ${index + 1}의 주민등록번호를 입력해주세요.`
            : `- 주주 ${index + 1}의 주민등록번호를 입력해주세요.`;
        }
      });

      selectedEmployees.forEach((employee, index) => {
        if (!employee?.Address?.length > 0) {
          errorMessage += errorMessage ? `\n- 주주 ${index + 1}의 주소를 입력해주세요.` : `- 주주 ${index + 1}의 주소를 입력해주세요.`;
        }
      });

      return errorMessage;
    };

    const handleSelectedEmployeeDataChange = (index, field, value) => {
      const updatedEmployees = [...selectedEmployees];
      updatedEmployees[index][field] = value;
      setSelectedEmployees(updatedEmployees);
    };

    const handleContractDataChange = (field, value) => {
      setStockholdersAgreementData((prevData) => ({
        ...prevData,
        [field]: value,
      }));
    };

    const onChangeContractDateData = (value) => {
      handleContractDataChange("ContractDate", value);
      handleContractDataChange("InputCompanyName", inputCompanyName);
    };

    const stockholderNamesString = selectedEmployees.map((employee) => employee.Name).join(", ");

    const onChangeCompanyNameData = (value) => {
      setInputCompanyName(value);
      handleContractDataChange("InputCompanyName", inputCompanyName);
    };

    const filterKeys = (arr, keysToKeep) => {
      return arr.map((obj) => {
        const filteredObj = {};
        keysToKeep.forEach((key) => {
          if (obj.hasOwnProperty(key)) {
            filteredObj[key] = obj[key];
          }
        });
        return filteredObj;
      });
    };

    const keysToKeep = ["Name", "ResidentRegistrationNumber", "Address", "TelNumber"];
    const filteredEmployees = filterKeys(selectedEmployees, keysToKeep);

    const applyFunctionsToMemberData = (memberData) => {
      return memberData.map((member) => {
        let updatedMember = { ...member };
        if (updatedMember.residentRegistrationNumber) {
          updatedMember.residentRegistrationNumber = AddHyphen13DigitNumber(updatedMember.residentRegistrationNumber);
        }
        if (updatedMember.telNumber) {
          updatedMember.telNumber = AddHyphenTelNumber(updatedMember.telNumber);
        }
        return updatedMember;
      });
    };

    const onClickConfirmInput = async () => {
      if (allInputFilled()) {
        setIsLoading(true);
        const lowCaseContractData = ConvertKeysToLowercase(stockholdersAgreementData);
        const lowCaseEmployeeData = ConvertArrayKeysToLowercase(filteredEmployees);
        const bodyRequestData = MergeData(lowCaseContractData, lowCaseEmployeeData);
        const bodyRequestDataCopy = { ...bodyRequestData };

        bodyRequestDataCopy.memberData = applyFunctionsToMemberData(bodyRequestDataCopy?.memberData);

        try {
          const endPoint = "https://dml1u35aa3.execute-api.ap-northeast-2.amazonaws.com/product/pdf/general/shareholders-contracts";
          const response = await axios.post(endPoint, {
            email: companyEmail,
            data: bodyRequestDataCopy,
          });
          if (response.data.message === "주주간계약서 부여 계약서 생성 완료") {
            handleContractDataChange("Url", response.data.url);
            setTimeout(async () => {
              setIsLoading(false);
              setStockholdersAgreementInfo(false);
              alert("주주간 계약서 작성을 완료했습니다.");
            }, 100);
          } else {
            setIsLoading(false);
            setStockholdersAgreementInfo(false);
            alert("주주간 계약서 작성에 실패하였습니다. 다시 한번 시도해 주세요.");
          }
        } catch (error) {
          console.log("StockholdersAgreement ERROR : ", error);
          setIsLoading(false);
          setStockholdersAgreementInfo(false);
          alert("주주간계약서 작성에 실패하였습니다. 다시 한번 시도해 주세요.");
        }
      } else {
        alert(`${getErrorMessage()}`);
      }
    };

    return (
      <div className="Signin_CompleteModal_BG">
        <div className="ContractInput_Modal_CT">
          <div className="ContractInput_Close_CT">
            <div className="CheckList_InfoModal_Title_Copy">주주간 계약서</div>
            <div className="CheckList_InfoModal_Icon_CT">
              <button className="CheckList_Close_Button" onClick={() => setStockholdersAgreementInfo(false)}>
                <Icon name={"Close"} size={"24"} />
              </button>
            </div>
          </div>
          <div className="ContractInput_Contents_CT">
            <div className="ContractInput_Left_CT">
              <div className="ContractInput_Left_Content_CT">
                <div className="ContractInput_Body_Copy" style={{ marginTop: "20px" }}>
                  주주 <span style={{ color: "rgb(55, 105, 252)" }}>{stockholderNamesString || "________"}</span> (이하 개별적으로 또는 총칭하여
                  “주주”라 한다)는{" "}
                  {inputCompanyName.length > 0 ? (
                    <span style={{ color: "rgb(55, 105, 252)" }}>{inputCompanyName}</span>
                  ) : (
                    <span
                      style={{
                        color: "rgb(216, 44, 44)",
                        backgroundColor: "rgb(255, 239, 190)",
                      }}
                    >
                      ________
                    </span>
                  )}{" "}
                  (이하 “회사”라 한다)를 공동으로 운영하는 과정에서 상호 신뢰를 바탕으로 아래와 같이 약정하고 이를 준수할 것을 확인한다.
                </div>
                <div className="ContractInput_Label_Copy" style={{ marginTop: "12px" }}>
                  제 1 조 (목적)
                </div>
                <div className="ContractInput_Body_Copy" style={{ marginTop: "4px" }}>
                  본 주주간 계약은 회사 운영에 대한 제반 사항을 규정하고, 주주간 발생할 수 있는 분쟁에 대한 합리적인 해결 방안을 모색하며 향후 회사
                  경영의 효율성을 높이고 주주간의 경제적 이익을 보호하고 도모하는데 그 목적이 있다.
                </div>
                <div className="ContractInput_Label_Copy" style={{ marginTop: "12px" }}>
                  제 2 조 (겸업의 금지)
                </div>
                <div className="ContractInput_Body_Copy" style={{ marginTop: "4px" }}>
                  주주는 다른 주주의 사전 서면 동의가 없는 한, 회사가 수행하고 있는 사업과 동종의 사업을 독자적 또는 제3자와 동업으로 경영 하거나,
                  타인의 동종 사업장에 종사하거나 또는 동종의 사업체에 대한 지분을 본인 명의 또는 타인 명의로 취득하는 등 동종의 사업에 관여하는
                  행위를 일체 행하여서는 아니 된다.
                </div>
                <div className="ContractInput_Label_Copy" style={{ marginTop: "12px" }}>
                  제 3 조 (주식의 처분 제한)
                </div>
                <div className="ContractInput_Body_Copy" style={{ marginTop: "4px" }}>
                  주주는 다른 주주의 사전 서면 동의가 없는 한, 보유하고 있는 회사의 주식을 제3자에게 양도, 매각하거나 담보를 설정하는 등의 처분행위
                  일체를 행할 수 없다.
                </div>

                <div className="ContractInput_Label_Copy" style={{ marginTop: "12px" }}>
                  제 4 조 (근속 의무)
                </div>
                <div className="ContractInput_Body_Copy" style={{ marginTop: "4px" }}>
                  ① 주주는 본 계약체결일로부터{" "}
                  {stockholdersAgreementData?.ExecutivesTenure && stockholdersAgreementData?.ExecutivesTenure.length > 0 ? (
                    <span style={{ color: "rgb(55, 105, 252)" }}>{stockholdersAgreementData.ExecutivesTenure} </span>
                  ) : (
                    <span
                      style={{
                        color: "rgb(216, 44, 44)",
                        backgroundColor: "rgb(255, 239, 190)",
                      }}
                    >
                      ____
                    </span>
                  )}{" "}
                  간 회사의 임직원으로서 재직하여야 한다.
                  <br />② 주주가 위 기간 내에 회사를 퇴직하거나, 회사로부터 해고를 당하는 등 사유를 불문하고 회사의 임직원으로 재직하지 아니하게 될
                  경우, 해당 주주는 회사의 대표이사에게 본인이 보유하고 있는 회사 주식의 전량을 액면가로 매도하여야 한다.
                </div>
                <div className="ContractInput_Label_Copy" style={{ marginTop: "12px" }}>
                  제 5 조 (중도 해지)
                </div>
                <div className="ContractInput_Body_Copy" style={{ marginTop: "4px" }}>
                  ① 주주는 서면합의에 의하지 아니하고는 본 계약의 유효기간 중에 일방적으로 본 계약을 해지할 수 없다.
                  <br />② 주주가 본 계약상의 의무를 위반할 경우 타 주주는{" "}
                  {stockholdersAgreementData?.ExecutionTerm && stockholdersAgreementData?.ExecutionTerm.length > 0 ? (
                    <span style={{ color: "rgb(55, 105, 252)" }}>{stockholdersAgreementData.ExecutionTerm}</span>
                  ) : (
                    <span
                      style={{
                        color: "rgb(216, 44, 44)",
                        backgroundColor: "rgb(255, 239, 190)",
                      }}
                    >
                      ____
                    </span>
                  )}{" "}
                  의 기간을 정하여 이행을 최고할 수 있으며, 최고에도 불구하고 위반 주주가 위 계약상의 의무를 이행하지 아니할 경우 타 주주는 본 계약을
                  해지할 수 있다.
                  <br />③ 본 계약 해지에 대해 귀책사유 있는 주주는 회사에 대해 보유하고 있는 모든 권리(주식을 포함한다)를 포기하여야 하며, 그 사유가
                  있는 날로부터{" "}
                  {stockholdersAgreementData?.RightTransferTerm && stockholdersAgreementData?.RightTransferTerm.length > 0 ? (
                    <span style={{ color: "rgb(55, 105, 252)" }}>{stockholdersAgreementData.RightTransferTerm}</span>
                  ) : (
                    <span
                      style={{
                        color: "rgb(216, 44, 44)",
                        backgroundColor: "rgb(255, 239, 190)",
                      }}
                    >
                      ____
                    </span>
                  )}{" "}
                  이내에 회사의 대표이사에게 모든 권리를 양도하고 제반 비용 일체를 부담한다. 본 조에 의하여 본 계약 해지에 귀책사유 있는 주주가 보유한
                  회사의 주식을 타 주주에게 양도하는 경우, 1주당 양도가격은{" "}
                  {stockholdersAgreementData?.StockTransferPrice && stockholdersAgreementData?.StockTransferPrice.length > 0 ? (
                    <span style={{ color: "rgb(55, 105, 252)" }}>{stockholdersAgreementData.StockTransferPrice}</span>
                  ) : (
                    <span
                      style={{
                        color: "rgb(216, 44, 44)",
                        backgroundColor: "rgb(255, 239, 190)",
                      }}
                    >
                      ______
                    </span>
                  )}{" "}
                  로 한다.
                </div>
                <div className="ContractInput_Label_Copy" style={{ marginTop: "12px" }}>
                  제 6 조 (계약의 변경)
                </div>
                <div className="ContractInput_Body_Copy" style={{ marginTop: "4px" }}>
                  본 계약은 주주 전원의 서면 합의로만 변경될 수 있다.
                </div>

                <div className="ContractInput_Label_Copy" style={{ marginTop: "12px" }}>
                  제 7 조 (계약기간)
                </div>
                <div className="ContractInput_Body_Copy" style={{ marginTop: "4px" }}>
                  본 계약은 계약 체결일로부터 효력이 발생하며 다음 중 하나의 사유가 발생할 때까지 존속한다.
                  <br />
                  1. 주주간에 이 계약을 종료시키기로 하는 서면합의가 있는 경우
                  <br />
                  2. 회사가 해산, 청산을 거치는 등 법인격이 소멸된 경우
                </div>
                <div className="ContractInput_Label_Copy" style={{ marginTop: "12px" }}>
                  제 8 조 (완전 합의)
                </div>
                <div className="ContractInput_Body_Copy" style={{ marginTop: "4px" }}>
                  본 계약은 주주간 완전한 합의를 구성하며, 본 계약에 정해진 사항을 제외한 구두 또는 서면의 명시적인 또는 암시적인 약속, 조건 또는
                  의무는 존재하지 아니한다. 본 계약은 본 계약이 규정하는 사항에 관한 이전의 모든 합의를 대체한다.
                </div>
                <div className="ContractInput_Label_Copy" style={{ marginTop: "12px" }}>
                  제 9 조 (비밀 유지 의무)
                </div>
                <div className="ContractInput_Body_Copy" style={{ marginTop: "4px" }}>
                  주주는 타 주주의 사전 서면 동의가 없는 한 본 계약, 본 계약에 따른 거래 및 회사와 관련된 모든 정보에 대한 비밀을 유지하여야 하고, 본
                  계약상 권리 또는 의무를 이행하기 위한 목적 이외에는 다른 어떠한 목적으로도 이를 사용하여서는 아니 된다.
                </div>
                <div className="ContractInput_Label_Copy" style={{ marginTop: "12px" }}>
                  제 10 조 (권리 및 의무의 양도 금지)
                </div>
                <div className="ContractInput_Body_Copy" style={{ marginTop: "4px" }}>
                  주주는 본 계약에서 명시적으로 달리 정하지 아니하는 한 본 계약에 따른 본인의 권리, 의무, 지위의 전부 또는 일부를 타 주주의 사전
                  서면동의 없이 양도, 이전할 수 없다.
                </div>
                <div className="ContractInput_Label_Copy" style={{ marginTop: "12px" }}>
                  제 11 조 (불가항력)
                </div>
                <div className="ContractInput_Body_Copy" style={{ marginTop: "4px" }}>
                  주주는 화재, 폭풍, 홍수, 지진, 사고, 전쟁, 반란, 폭동, 기타 민란, 전염병, 격리, 천재지변, 정부조치 등 본인의 통제를 벗어난 사유에
                  의해 본 계약 조건을 이행 또는 준수하지 못하는 경우 이에 대한 책임을 지지 아니한다.
                </div>
                <div className="ContractInput_Label_Copy" style={{ marginTop: "12px" }}>
                  제 12 조 (관할)
                </div>
                <div className="ContractInput_Body_Copy" style={{ marginTop: "4px" }}>
                  이 계약은 대한민국법을 그 준거법으로 하며 이에 따라 해석되고, 협의에 의하여 이 계약과 관련된 분쟁을 해결할 수 없는 경우에는 그
                  분쟁은{" "}
                  {stockholdersAgreementData?.JurisdictionCourt && stockholdersAgreementData?.JurisdictionCourt?.length > 0 ? (
                    <span style={{ color: "rgb(55, 105, 252)" }}>{stockholdersAgreementData.JurisdictionCourt}</span>
                  ) : (
                    <span
                      style={{
                        color: "rgb(216, 44, 44)",
                        backgroundColor: "rgb(255, 239, 190)",
                      }}
                    >
                      ________
                    </span>
                  )}{" "}
                  을 관할법원으로 하여 해결한다.
                </div>
                <div className="ContractInput_Body_Copy" style={{ marginTop: "12px" }}>
                  당사자들은 이상의 내용을 증명하기 위하여 첫머리에 기재된 일자에 이 계약서 2부를 작성하여 각각 서명 또는 기명 날인한 후 1부씩
                  보관한다.
                </div>

                <div className="ContractInput_Date_Copy_CT" style={{ marginBottom: "24px" }}>
                  <div className="ContractInput_Date_Copy">
                    {stockholdersAgreementData?.ContractDate && stockholdersAgreementData?.ContractDate?.length === 8 ? (
                      <span>{AddUnitYYYYMMDD(stockholdersAgreementData.ContractDate)}</span>
                    ) : (
                      <span
                        style={{
                          color: "rgb(216, 44, 44)",
                          backgroundColor: "rgb(255, 239, 190)",
                        }}
                      >
                        ____ 년 __ 월 __ 일
                      </span>
                    )}
                  </div>
                </div>
                {selectedEmployees.map((employee, index) => (
                  <div key={employee.ID}>
                    <div className="ContractInput_Label_Copy" style={{ marginTop: "32px" }}>
                      주주 {index + 1}
                    </div>
                    <div
                      className="ContractInput_SubBody_CT"
                      style={{
                        minWidth: "240px",
                        maxWidth: "420px",
                        justifyContent: "space-between",
                      }}
                    >
                      <div className="ContractInput_Body_Copy" style={{ marginTop: "4px" }}>
                        이름 : {employee.Name}
                      </div>
                      <div className="ContractInput_Body_Copy" style={{ marginTop: "4px", marginLeft: "24px" }}>
                        (서명 또는 인)
                      </div>
                    </div>
                    <div className="ContractInput_Body_Copy" style={{ marginTop: "4px" }}>
                      주민등록번호 : {AddHyphen13DigitNumber(employee.ResidentRegistrationNumber)}
                    </div>
                    <div className="ContractInput_Body_Copy" style={{ marginTop: "4px" }}>
                      주소 : {employee.Address}
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="ContractInput_VerticalDivider" />
            <div className="ContractInput_Right_CT">
              {selectedEmployees.map((employee, index) => (
                <div className="Contract_UserData_CT" key={employee.ID}>
                  <div className="Contract_Body_Copy" style={{ marginBottom: "8px" }}>
                    <div className="Contract_Label_Copy"> 주주 {index + 1}</div> | {employee.Name}
                  </div>
                  <div className="Contract_Body_Copy" style={{ marginBottom: "8px" }}>
                    <div className="Contract_Label_Copy">생년월일</div> | {AddUnitYYYYMMDD(employee.Birthday)}
                  </div>
                  <div className="Contract_Body_Copy">
                    <div className="Contract_Label_Copy">전화번호</div> | {AddHyphenTelNumber(employee.TelNumber)}
                  </div>
                  <div className="Contract_Divider"> </div>
                </div>
              ))}
              <div className="ContractInput_InputField">
                <div className="ContractInput_InputField_Label_CT">
                  <div className="Profile_Label_Copy">회사명</div>
                  <div className={inputCompanyName.length > 0 ? "Required_Dot_Blue" : "Required_Dot_Red"} />
                </div>
                <input
                  className="ContractInput_LongInput_BG"
                  value={inputCompanyName}
                  onChange={(e) => onChangeCompanyNameData(e.target.value)}
                  placeholder="회사명을 입력해주세요."
                  name="CompanyName"
                  required
                />
              </div>
              <div className="ContractInput_InputField">
                <div className="ContractInput_InputField_Label_CT">
                  <div className="Profile_Label_Copy">[제4조 - ①] 임직원 의무 재직기간</div>
                  <div
                    className={
                      stockholdersAgreementData?.ExecutivesTenure && stockholdersAgreementData?.ExecutivesTenure?.length > 0
                        ? "Required_Dot_Blue"
                        : "Required_Dot_Red"
                    }
                  />
                </div>
                <input
                  className="ContractInput_LongInput_BG"
                  value={stockholdersAgreementData?.ExecutivesTenure || ""}
                  onChange={(e) => handleContractDataChange("ExecutivesTenure", e.target.value)}
                  placeholder="예시) 3년"
                  name="ExecutivesTenure"
                  required
                />
              </div>
              <div className="ContractInput_InputField">
                <div className="ContractInput_InputField_Label_CT">
                  <div className="Profile_Label_Copy">[제5조 - ②] 이행 최고 기간</div>
                  <div
                    className={
                      stockholdersAgreementData?.ExecutionTerm && stockholdersAgreementData?.ExecutionTerm?.length > 0
                        ? "Required_Dot_Blue"
                        : "Required_Dot_Red"
                    }
                  />
                </div>
                <input
                  className="ContractInput_LongInput_BG"
                  value={stockholdersAgreementData?.ExecutionTerm || ""}
                  onChange={(e) => handleContractDataChange("ExecutionTerm", e.target.value)}
                  placeholder="예시) 2주"
                  name="ExecutionTerm"
                  required
                />
              </div>
              <div className="ContractInput_InputField">
                <div className="ContractInput_InputField_Label_CT">
                  <div className="Profile_Label_Copy">[제5조 - ③] 권리 양도 기한</div>
                  <div
                    className={
                      stockholdersAgreementData?.RightTransferTerm && stockholdersAgreementData?.RightTransferTerm?.length > 0
                        ? "Required_Dot_Blue"
                        : "Required_Dot_Red"
                    }
                  />
                </div>
                <input
                  className="ContractInput_LongInput_BG"
                  value={stockholdersAgreementData?.RightTransferTerm || ""}
                  onChange={(e) => handleContractDataChange("RightTransferTerm", e.target.value)}
                  placeholder="예시) 30일"
                  name="RightTransferTerm"
                  required
                />
              </div>
              <div className="ContractInput_InputField">
                <div className="ContractInput_InputField_Label_CT">
                  <div className="Profile_Label_Copy">[제5조 - ③] 주식 양도 가격</div>
                  <div
                    className={
                      stockholdersAgreementData?.StockTransferPrice && stockholdersAgreementData?.StockTransferPrice?.length > 0
                        ? "Required_Dot_Blue"
                        : "Required_Dot_Red"
                    }
                  />
                </div>
                <input
                  className="ContractInput_LongInput_BG"
                  value={stockholdersAgreementData?.StockTransferPrice || ""}
                  onChange={(e) => handleContractDataChange("StockTransferPrice", e.target.value)}
                  placeholder="예시) 액면가"
                  name="StockTransferPrice"
                  required
                />
              </div>
              <div className="ContractInput_InputField">
                <div className="ContractInput_InputField_Label_CT">
                  <div className="Profile_Label_Copy">[제12조] 분쟁조절 관할 법원</div>
                  <div
                    className={
                      stockholdersAgreementData?.JurisdictionCourt && stockholdersAgreementData?.JurisdictionCourt?.length > 0
                        ? "Required_Dot_Blue"
                        : "Required_Dot_Red"
                    }
                  />
                </div>
                <input
                  className="ContractInput_LongInput_BG"
                  value={stockholdersAgreementData?.JurisdictionCourt || ""}
                  onChange={(e) => handleContractDataChange("JurisdictionCourt", e.target.value)}
                  placeholder="예시) 서울 중앙지방 법원"
                  name="JurisdictionCourt"
                  required
                />
              </div>
              <div className="ContractInput_InputField">
                <div className="ContractInput_InputField_Label_CT">
                  <div className="Profile_Label_Copy">계약일</div>
                  <div
                    className={
                      stockholdersAgreementData?.ContractDate && stockholdersAgreementData?.ContractDate?.length === 8
                        ? "Required_Dot_Blue"
                        : "Required_Dot_Red"
                    }
                  />
                </div>
                <input
                  className="ContractInput_LongInput_BG"
                  value={stockholdersAgreementData?.ContractDate || ""}
                  onChange={(e) => onChangeContractDateData(e.target.value)}
                  placeholder="YYYYMMDD 형식으로 입력해주세요."
                  name="ContractDate"
                  required
                />
              </div>
              {selectedEmployees.map((employee, index) => (
                <div key={employee.ID}>
                  <div className="ContractInput_InputField">
                    <div className="ContractInput_InputField_Label_CT">
                      <div className="Profile_Label_Copy">주주 {index + 1} 주민등록번호</div>
                      <div className={employee?.ResidentRegistrationNumber?.length === 13 ? "Required_Dot_Blue" : "Required_Dot_Red"} />
                    </div>
                    <input
                      className="ContractInput_LongInput_BG"
                      value={employee?.ResidentRegistrationNumber || ""}
                      onChange={(e) => handleSelectedEmployeeDataChange(index, "ResidentRegistrationNumber", e.target.value)}
                      placeholder="주민등록번호를 입력해주세요."
                      name="StockholderResidentRegistrationNumber"
                      required
                    />
                  </div>
                </div>
              ))}

              {selectedEmployees.map((employee, index) => (
                <div key={employee.ID}>
                  <div className="ContractInput_InputField">
                    <div className="ContractInput_InputField_Label_CT">
                      <div className="Profile_Label_Copy">주주 {index + 1} 주소</div>
                      <div className={employee?.Address?.length > 0 ? "Required_Dot_Blue" : "Required_Dot_Red"} />
                    </div>
                    <input
                      className="ContractInput_LongInput_BG"
                      value={employee?.Address || ""}
                      onChange={(e) => handleSelectedEmployeeDataChange(index, "Address", e.target.value)}
                      placeholder="주소를 입력해주세요."
                      name="StockholderAddress"
                      required
                    />
                  </div>
                </div>
              ))}

              <button className="ContractInput_ConfirmButton_BG" type="button" onClick={onClickConfirmInput}>
                작성 완료
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
);

export default StockholdersAgreement;
