import "../GlobalStyle.css";
import "../PCStyle.css";
import "../TabletStyle.css";
import "../MobileStyle.css";
import React from "react";
import { observer } from "mobx-react";
import axios from "axios";
import { ConvertKeysToLowercase } from "../Utils/ConvertStringFormat";
import { AddUnitYYYYMMDD, AddDotYYYYMMDD, AddHyphenTelNumber, AddCommaAmountNumber } from "../Utils/ConvertNumberFormat";
import { RemoveEmptyObjects } from "../Utils/OnChangeDocumentData";
import ContractDataTable from "../ChartTable/ContractDataTable";
import ContractSignTable from "../ChartTable/ContractSignTable";
import WorkTimeTable from "../ChartTable/WorkTimeTable";
import SalaryCompositionTable from "../ChartTable/SalaryCompositionTable";
import Icon from "../Assets/Icons/Icon";

const JuvenileContract = observer(
  ({
    setJuvenileContractInfo,
    inputCompanyName,
    companyRepresentName,
    companyAddress,
    setInputCompanyName,
    setCompanyRepresentName,
    setCompanyAddress,
    selectedEmployeeData,
    setSelectedEmployeeData,
    setSelectedEmployees,
    companyEmail,
    setIsLoading,
  }) => {
    const allInputFilled = () => {
      if (
        inputCompanyName.length > 0 &&
        companyRepresentName.length > 0 &&
        companyAddress.length > 0 &&
        selectedEmployeeData?.Birthday?.length === 8 &&
        selectedEmployeeData?.Address?.length > 0 &&
        selectedEmployeeData?.Documents?.JuvenileContract?.EmployeeContractStartDate?.length === 8 &&
        selectedEmployeeData?.Documents?.JuvenileContract?.EmployeeContractEndDate?.length === 8 &&
        selectedEmployeeData?.Documents?.JuvenileContract?.WorkingPlace?.length > 0 &&
        selectedEmployeeData?.Documents?.JuvenileContract?.JobTask?.length > 0 &&
        selectedEmployeeData?.Documents?.JuvenileContract?.Salary?.length > 0 &&
        selectedEmployeeData?.Documents?.JuvenileContract?.SalaryDay?.length > 0 &&
        selectedEmployeeData?.Documents?.JuvenileContract?.ContractDate?.length === 8 &&
        selectedEmployeeData?.Documents?.JuvenileContract?.SalaryInformation[0]?.SalaryCompositionTableTitle?.length > 0 &&
        selectedEmployeeData?.Documents?.JuvenileContract?.SalaryInformation[0]?.SalaryCompositionTablePaymentAmount?.length > 0 &&
        selectedEmployeeData?.Documents?.JuvenileContract?.SalaryInformation[0]?.SalaryCompositionTablePaymentStandard?.length > 0
      ) {
        return true;
      } else {
        return false;
      }
    };

    const getErrorMessage = () => {
      let errorMessage = "";
      if (!inputCompanyName.length > 0) {
        errorMessage += "- 상호명을 입력해주세요.";
      }
      if (!companyRepresentName.length > 0) {
        errorMessage += errorMessage ? "\n- 대표자명을 입력해주세요." : "- 대표자명을 입력해주세요.";
      }
      if (!companyAddress.length > 0) {
        errorMessage += errorMessage ? "\n- 기업/기관 주소를 입력해주세요." : "- 기업/기관 주소를 입력해주세요.";
      }
      if (selectedEmployeeData.Birthday.length !== 8) {
        errorMessage += errorMessage
          ? `\n- ${selectedEmployeeData?.Name} 님의 생년월일을 입력해주세요.`
          : `- ${selectedEmployeeData?.Name} 님의 생년월일을 입력해주세요.`;
      }
      if (!selectedEmployeeData?.Address?.length > 0) {
        errorMessage += errorMessage
          ? `\n- ${selectedEmployeeData?.Name} 님의 주소를 입력해주세요.`
          : `- ${selectedEmployeeData?.Name} 님의 주소를 입력해주세요.`;
      }
      if (selectedEmployeeData?.Documents?.JuvenileContract?.EmployeeContractStartDate?.length !== 8) {
        errorMessage += errorMessage ? "\n- 근로계약 시작일을 입력해주세요." : "- 근로계약 시작일을 입력해주세요.";
      }
      if (selectedEmployeeData?.Documents?.JuvenileContract?.EmployeeContractEndDate?.length !== 8) {
        errorMessage += errorMessage ? "\n- 근로계약 종료일을 입력해주세요." : "- 근로계약 종료일을 입력해주세요.";
      }
      if (!selectedEmployeeData?.Documents?.JuvenileContract?.WorkingPlace?.length > 0) {
        errorMessage += errorMessage ? "\n- 근무 장소를 입력해주세요." : "- 근무 장소를 입력해주세요.";
      }
      if (!selectedEmployeeData?.Documents?.JuvenileContract?.JobTask?.length > 0) {
        errorMessage += errorMessage ? "\n- 업무 내용을 입력해주세요." : "- 업무 내용을 입력해주세요.";
      }
      if (!selectedEmployeeData?.Documents?.JuvenileContract?.Salary?.length > 0) {
        errorMessage += errorMessage ? "\n- 월 급여를 입력해주세요." : "- 월 급여를 입력해주세요.";
      }
      if (!selectedEmployeeData?.Documents?.JuvenileContract?.SalaryDay?.length > 0) {
        errorMessage += errorMessage ? "\n- 급여지급일을 입력해주세요." : "- 급여지급일을 입력해주세요.";
      }
      if (selectedEmployeeData?.Documents?.JuvenileContract?.ContractDate?.length !== 8) {
        errorMessage += errorMessage ? "\n- 계약일을 입력해주세요." : "- 계약일을 입력해주세요.";
      }
      if (!selectedEmployeeData?.Documents?.JuvenileContract?.SalaryInformation[0]?.SalaryCompositionTableTitle?.length > 0) {
        errorMessage += errorMessage
          ? "\n- 제 6 조의 임금구성표 '구분' 필수값을 입력해주세요. (표에 직접 입력)"
          : "- 제 6 조의 임금구성표 '구분' 필수값을 입력해주세요. (표에 직접 입력)";
      }
      if (!selectedEmployeeData?.Documents?.JuvenileContract?.SalaryInformation[0]?.SalaryCompositionTablePaymentAmount?.length > 0) {
        errorMessage += errorMessage
          ? "\n- 제 6 조의 임금구성표 '월 지급액' 필수값을 입력해주세요. (표에 직접 입력)"
          : "- 제 6 조의 임금구성표 '월 지급액' 필수값을 입력해주세요. (표에 직접 입력)";
      }
      if (!selectedEmployeeData?.Documents?.JuvenileContract?.SalaryInformation[0]?.SalaryCompositionTablePaymentStandard?.length > 0) {
        errorMessage += errorMessage
          ? "\n- 제 6 조의 임금구성표 '지급기준' 필수값을 입력해주세요. (표에 직접 입력)"
          : "- 제 6 조의 임금구성표 '지급기준' 필수값을 입력해주세요. (표에 직접 입력)";
      }
      return errorMessage;
    };

    const handleSelectedEmployeeDataChange = (field, value, nestedField) => {
      setSelectedEmployeeData((prevData) => {
        const updatedData = { ...prevData };

        if (nestedField) {
          if (!updatedData.Documents) {
            updatedData.Documents = {};
          }
          if (!updatedData.Documents.JuvenileContract) {
            updatedData.Documents.JuvenileContract = {};
          }
          if (!updatedData.Documents.JuvenileContract.SalaryInformation) {
            updatedData.Documents.JuvenileContract.SalaryInformation = [];
          }

          const match = nestedField.match(/SalaryCompositionTable(?:Title|PaymentAmount|PaymentStandard|Note)(\d*)/);
          const removeLastCharacter = (str) => str.slice(0, -1);
          if (match) {
            const index = parseInt(match[1], 10) - 1; // Convert to zero-based index

            // Ensure the array has enough length
            while (updatedData.Documents.JuvenileContract.SalaryInformation.length <= index) {
              updatedData.Documents.JuvenileContract.SalaryInformation.push({});
            }

            // Update the specific field in the specific object
            updatedData.Documents.JuvenileContract.SalaryInformation[index][removeLastCharacter(nestedField)] = value;
          } else {
            updatedData.Documents.JuvenileContract[nestedField] = value;
          }
        } else {
          updatedData[field] = value;
        }

        // selectedEmployees 배열을 업데이트
        setSelectedEmployees((prevEmployees) => prevEmployees.map((employee) => (employee.ID === updatedData.ID ? updatedData : employee)));

        return updatedData;
      });
    };

    const onChangeStateCompanyNameData = (value) => {
      setInputCompanyName(value);
      handleSelectedEmployeeDataChange("Documents", value, "InputCompanyName");
    };

    const onChangeStateCompanyAddressData = (value) => {
      setCompanyAddress(value);
      handleSelectedEmployeeDataChange("Documents", value, "CompanyAddress");
    };

    const onChangeStateCompanyRepresentNameData = (value) => {
      setCompanyRepresentName(value);
      handleSelectedEmployeeDataChange("Documents", value, "CompanyRepresentName");
    };

    const onChangeStateAddressData = (value) => {
      handleSelectedEmployeeDataChange("Documents", value, "Address");
      handleSelectedEmployeeDataChange("Address", value);
    };

    const onChangeStateBirthdayData = (value) => {
      handleSelectedEmployeeDataChange("Documents", value, "Birthday");
      handleSelectedEmployeeDataChange("Birthday", value);
    };

    const onChangeContractDateData = (value) => {
      handleSelectedEmployeeDataChange("Documents", value, "ContractDate");
      handleSelectedEmployeeDataChange("Documents", inputCompanyName, "InputCompanyName");
      handleSelectedEmployeeDataChange("Documents", companyRepresentName, "CompanyRepresentName");
      handleSelectedEmployeeDataChange("Documents", companyAddress, "CompanyAddress");
      handleSelectedEmployeeDataChange("Documents", selectedEmployeeData?.Name, "Name");
      handleSelectedEmployeeDataChange("Documents", selectedEmployeeData?.Birthday, "Birthday");
      handleSelectedEmployeeDataChange("Documents", selectedEmployeeData?.Address, "Address");
      handleSelectedEmployeeDataChange("Documents", selectedEmployeeData?.TelNumber, "TelNumber");
    };

    const onClickConfirmInput = async () => {
      if (allInputFilled()) {
        setIsLoading(true);
        const selectedEmployeeDataCopy = { ...selectedEmployeeData };

        selectedEmployeeDataCopy.TelNumber = AddHyphenTelNumber(selectedEmployeeDataCopy?.TelNumber);
        selectedEmployeeDataCopy.Documents.JuvenileContract.TelNumber = AddHyphenTelNumber(
          selectedEmployeeDataCopy?.Documents?.JuvenileContract?.TelNumber
        );
        selectedEmployeeDataCopy.Documents.JuvenileContract.Birthday = AddDotYYYYMMDD(
          selectedEmployeeDataCopy?.Documents?.JuvenileContract?.Birthday
        );
        selectedEmployeeDataCopy.Documents.JuvenileContract.Salary = AddCommaAmountNumber(
          selectedEmployeeDataCopy?.Documents?.JuvenileContract?.Salary
        );
        selectedEmployeeDataCopy.Documents.JuvenileContract.SalaryInformation[0].SalaryCompositionTablePaymentAmount = AddCommaAmountNumber(
          selectedEmployeeDataCopy?.Documents?.JuvenileContract?.SalaryInformation[0]?.SalaryCompositionTablePaymentAmount
        );
        if (
          selectedEmployeeDataCopy?.Documents?.JuvenileContract?.SalaryInformation[1]?.SalaryCompositionTablePaymentAmount &&
          selectedEmployeeDataCopy?.Documents?.JuvenileContract?.SalaryInformation[1]?.SalaryCompositionTablePaymentAmount.length > 0
        ) {
          selectedEmployeeDataCopy.Documents.JuvenileContract.SalaryInformation[1].SalaryCompositionTablePaymentAmount = AddCommaAmountNumber(
            selectedEmployeeDataCopy?.Documents?.JuvenileContract?.SalaryInformation[1]?.SalaryCompositionTablePaymentAmount
          );
        }
        if (
          selectedEmployeeDataCopy?.Documents?.JuvenileContract?.SalaryInformation[2]?.SalaryCompositionTablePaymentAmount &&
          selectedEmployeeDataCopy?.Documents?.JuvenileContract?.SalaryInformation[2]?.SalaryCompositionTablePaymentAmount.length > 0
        ) {
          selectedEmployeeDataCopy.Documents.JuvenileContract.SalaryInformation[2].SalaryCompositionTablePaymentAmount = AddCommaAmountNumber(
            selectedEmployeeDataCopy?.Documents?.JuvenileContract?.SalaryInformation[2]?.SalaryCompositionTablePaymentAmount
          );
        }
        if (
          selectedEmployeeDataCopy?.Documents?.JuvenileContract?.SalaryInformation[3]?.SalaryCompositionTablePaymentAmount &&
          selectedEmployeeDataCopy?.Documents?.JuvenileContract?.SalaryInformation[3]?.SalaryCompositionTablePaymentAmount.length > 0
        ) {
          selectedEmployeeDataCopy.Documents.JuvenileContract.SalaryInformation[3].SalaryCompositionTablePaymentAmount = AddCommaAmountNumber(
            selectedEmployeeDataCopy?.Documents?.JuvenileContract?.SalaryInformation[3]?.SalaryCompositionTablePaymentAmount
          );
        }
        if (
          selectedEmployeeDataCopy?.Documents?.JuvenileContract?.SalaryInformation[4]?.SalaryCompositionTablePaymentAmount &&
          selectedEmployeeDataCopy?.Documents?.JuvenileContract?.SalaryInformation[4]?.SalaryCompositionTablePaymentAmount.length > 0
        ) {
          selectedEmployeeDataCopy.Documents.JuvenileContract.SalaryInformation[4].SalaryCompositionTablePaymentAmount = AddCommaAmountNumber(
            selectedEmployeeDataCopy?.Documents?.JuvenileContract?.SalaryInformation[4]?.SalaryCompositionTablePaymentAmount
          );
        }
        selectedEmployeeDataCopy.Documents.JuvenileContract.SalaryInformation = RemoveEmptyObjects(
          selectedEmployeeDataCopy.Documents.JuvenileContract.SalaryInformation
        );

        try {
          const endPoint = "https://dml1u35aa3.execute-api.ap-northeast-2.amazonaws.com/product/pdf/juvenile-contract";
          const response = await axios.post(endPoint, {
            email: companyEmail,
            telNumber: selectedEmployeeDataCopy?.TelNumber,
            name: selectedEmployeeData?.Name,
            data: ConvertKeysToLowercase(selectedEmployeeDataCopy?.Documents?.JuvenileContract),
            ...(selectedEmployeeData.NameDone ? {} : { generalDocument: true }),
          });
          if (response.data.message === "청소년근로계약서 생성 완료") {
            handleSelectedEmployeeDataChange("Documents", response.data.url, "Url");
            setTimeout(async () => {
              setIsLoading(false);
              setJuvenileContractInfo(false);
              alert("청소년(연소자) 근로계약서 작성을 완료했습니다.");
            }, 100);
          } else {
            setIsLoading(false);
            setJuvenileContractInfo(false);
            alert("청소년(연소자) 근로계약서 작성에 실패하였습니다. 다시 한번 시도해 주세요.");
          }
        } catch (error) {
          console.log("JuvenileContract ERROR : ", error);
          setIsLoading(false);
          setJuvenileContractInfo(false);
          alert("청소년(연소자) 근로계약서 작성에 실패하였습니다. 다시 한번 시도해 주세요.");
        }
      } else {
        alert(`${getErrorMessage()}`);
      }
    };

    return (
      <div className="Signin_CompleteModal_BG">
        <div className="ContractInput_Modal_CT">
          <div className="ContractInput_Close_CT">
            <div className="CheckList_InfoModal_Title_Copy">청소년(연소자) 근로계약서</div>
            <div className="CheckList_InfoModal_Icon_CT">
              <button className="CheckList_Close_Button" onClick={() => setJuvenileContractInfo(false)}>
                <Icon name={"Close"} size={"24"} />
              </button>
            </div>
          </div>
          <div className="ContractInput_Contents_CT">
            <div className="ContractInput_Left_CT">
              <div className="ContractInput_Left_Content_CT">
                <ContractDataTable
                  inputCompanyName={inputCompanyName}
                  companyRepresentName={companyRepresentName}
                  companyAddress={companyAddress}
                  employeeName={selectedEmployeeData?.Name}
                  employeeBirthday={selectedEmployeeData?.Birthday}
                  employeeAddress={selectedEmployeeData?.Address}
                  employeeTelNumber={selectedEmployeeData?.TelNumber}
                />

                <div className="ContractInput_Body_Copy" style={{ marginTop: "20px" }}>
                  당사자는 다음과 같이 근로계약을 체결하고, 성실히 이행할 것을 약정한다.
                </div>
                <div className="ContractInput_Label_Copy" style={{ marginTop: "12px" }}>
                  제 1 조 (근로개시일과 근로자의 의무)
                </div>
                <div className="ContractInput_Body_Copy" style={{ marginTop: "4px" }}>
                  ① 근로계약기간은{" "}
                  {selectedEmployeeData?.Documents?.JuvenileContract?.EmployeeContractStartDate &&
                  selectedEmployeeData?.Documents?.JuvenileContract?.EmployeeContractStartDate?.length === 8 ? (
                    <span style={{ color: "rgb(55, 105, 252)" }}>
                      {AddUnitYYYYMMDD(selectedEmployeeData.Documents.JuvenileContract.EmployeeContractStartDate)}
                    </span>
                  ) : (
                    <span
                      style={{
                        color: "rgb(216, 44, 44)",
                        backgroundColor: "rgb(255, 239, 190)",
                      }}
                    >
                      ____ 년 __ 월 __ 일
                    </span>
                  )}{" "}
                  부터{" "}
                  {selectedEmployeeData?.Documents?.JuvenileContract?.EmployeeContractEndDate &&
                  selectedEmployeeData?.Documents?.JuvenileContract?.EmployeeContractEndDate?.length === 8 ? (
                    <span style={{ color: "rgb(55, 105, 252)" }}>
                      {AddUnitYYYYMMDD(selectedEmployeeData.Documents.JuvenileContract.EmployeeContractEndDate)}
                    </span>
                  ) : (
                    <span
                      style={{
                        color: "rgb(216, 44, 44)",
                        backgroundColor: "rgb(255, 239, 190)",
                      }}
                    >
                      ____ 년 __ 월 __ 일
                    </span>
                  )}{" "}
                  까지로 한다.
                  <br />② 근로자는 계약기간 동안 회사의 제규정 및 관계법령을 준수하며 회사가 지시한 업무를 신의에 따라 성실히 수행하여야 한다.
                  <br />③ 제1항의 계약기간 만료 시 본 근로계약은 자동적으로 종료한다. 단, 사용자와 근로자의 별도 합의가 있는 경우 본 제1항의
                  계약기간을 연장 또는 갱신할 수 있다.
                </div>
                <div className="ContractInput_Label_Copy" style={{ marginTop: "12px" }}>
                  제 2 조 (근무장소 및 업무내용)
                </div>
                <div className="ContractInput_Body_Copy" style={{ marginTop: "4px" }}>
                  ① 근무장소는{" "}
                  {selectedEmployeeData?.Documents?.JuvenileContract?.WorkingPlace &&
                  selectedEmployeeData?.Documents?.JuvenileContract?.WorkingPlace?.length > 0 ? (
                    <span style={{ color: "rgb(55, 105, 252)" }}>{selectedEmployeeData.Documents.JuvenileContract.WorkingPlace}</span>
                  ) : (
                    <span
                      style={{
                        color: "rgb(216, 44, 44)",
                        backgroundColor: "rgb(255, 239, 190)",
                      }}
                    >
                      ____________
                    </span>
                  )}{" "}
                  (으)로 한다.
                  <br />② 업무내용은{" "}
                  {selectedEmployeeData?.Documents?.JuvenileContract?.JobTask &&
                  selectedEmployeeData?.Documents?.JuvenileContract?.JobTask?.length > 0 ? (
                    <span style={{ color: "rgb(55, 105, 252)" }}>{selectedEmployeeData.Documents.JuvenileContract.JobTask}</span>
                  ) : (
                    <span
                      style={{
                        color: "rgb(216, 44, 44)",
                        backgroundColor: "rgb(255, 239, 190)",
                      }}
                    >
                      ____________
                    </span>
                  )}{" "}
                  (으)로 한다.
                  <br />③ 사용자는 업무상 또는 경영상 필요하다고 인정되는 경우 제1항과 제2항의 내용을 변경할 수 있다. 이 경우 근로자는 정당한 사유
                  없이 이를 거부할 수 없다.
                </div>
                <div className="ContractInput_Label_Copy" style={{ marginTop: "12px" }}>
                  제 3 조 (근로시간 및 휴게시간)
                </div>
                <div className="ContractInput_Body_Copy" style={{ marginTop: "4px" }}>
                  ① 소정근로일과 소정근로시간은 1일 7시간, 1주 35시간을 초과하지 않는 범위 내에서 다음과 같이 정한다.
                </div>
                <WorkTimeTable type={"teen"} />
                <div className="ContractInput_Body_Copy" style={{ marginTop: "4px" }}>
                  ② 사용자와 근로자는 제1항의 소정근로시간 외에 1일 1시간, 1주 5시간 한도에서 근로시간을 연장할 수 있다.
                </div>
                <div className="ContractInput_Label_Copy" style={{ marginTop: "12px" }}>
                  제 4 조 (휴일)
                </div>
                <div className="ContractInput_Body_Copy" style={{ marginTop: "4px" }}>
                  ① 주휴일과 근로자의 날(5월 1일)은 유급휴일로 한다.
                  <br />② 주휴일은 일요일로 한다. 단, 근로자가 1주 동안 제4조 제1항의 소정근로일을 개근한 경우에 한하여 주휴일을 유급으로 부여한다.
                  <br />③ 제1항의 주휴일은 회사의 운영상황 및 업무스케쥴을 고려하여 조정할 수 있으며, 조정 시에는 사전에 공지한다.
                  <br />④ 주휴일과 근로자의 날이 중복되는 경우 1일의 휴일로 처리한다.
                  <br />⑤ 기타 직장이 직종별로 업무사정을 고려하여 휴일을 부여할 수 있다.
                </div>
                <div className="ContractInput_Label_Copy" style={{ marginTop: "12px" }}>
                  제 5 조 (연차유급휴가)
                </div>
                <div className="ContractInput_Body_Copy" style={{ marginTop: "4px" }}>
                  ① 연차유급휴가는 근로기준법에 따라 부여한다.
                </div>
                <div className="ContractInput_Label_Copy" style={{ marginTop: "12px" }}>
                  제 6 조 (임금)
                </div>
                <div className="ContractInput_Body_Copy" style={{ marginTop: "4px" }}>
                  ① 월 급여 총액은 금{" "}
                  {selectedEmployeeData?.Documents?.JuvenileContract?.Salary &&
                  selectedEmployeeData?.Documents?.JuvenileContract?.Salary?.length > 0 ? (
                    <span style={{ color: "rgb(55, 105, 252)" }}>{AddCommaAmountNumber(selectedEmployeeData.Documents.JuvenileContract.Salary)}</span>
                  ) : (
                    <span
                      style={{
                        color: "rgb(216, 44, 44)",
                        backgroundColor: "rgb(255, 239, 190)",
                      }}
                    >
                      ____________
                    </span>
                  )}{" "}
                  원으로 하며 임금구성은 다음과 같다.
                </div>
                <div
                  className="ContractInput_Body_Copy"
                  style={{
                    marginBottom: "0px",
                    marginTop: "4px",
                    color: "rgb(55, 105, 252)",
                  }}
                >
                  ※ 아래 임금 구성표에 정보를 직접 입력해 주세요. <span style={{ color: "red" }}>붉은 글자</span>는 필수 입력값 입니다.
                </div>
                <SalaryCompositionTable
                  selectedEmployeeData={selectedEmployeeData}
                  setSelectedEmployeeData={setSelectedEmployeeData}
                  setSelectedEmployees={setSelectedEmployees}
                  documentName={"JuvenileContract"}
                />
                <div className="ContractInput_Body_Copy" style={{ marginTop: "4px" }}>
                  ② 사용자는 근로자에게 지급하는 위 임금에서 국민연금, 건강보험, 고용보험, 소득세 및 주민세 등 법령이 정하는 세금을 공제하고 지급한다.
                  <br />③ 임금의 지급은 매월 1일부터 말일까지 기산하여{" "}
                  {selectedEmployeeData?.Documents?.JuvenileContract?.SalaryDay &&
                  selectedEmployeeData?.Documents?.JuvenileContract?.SalaryDay?.length > 0 ? (
                    <span style={{ color: "rgb(55, 105, 252)" }}>{selectedEmployeeData.Documents.JuvenileContract.SalaryDay}</span>
                  ) : (
                    <span
                      style={{
                        color: "rgb(216, 44, 44)",
                        backgroundColor: "rgb(255, 239, 190)",
                      }}
                    >
                      ____
                    </span>
                  )}{" "}
                  일에 근로자 명의의 금융계좌로 지급하며 지급일이 휴일인 경우에는 순차적으로 그 전일에 지급함을 원칙으로 한다. 단, 근로자가 원하는
                  경우 직접 지급할 수 있다.
                  <br />④ 근로자는 본인 및 타인의 임금에 관한 사항에 대하여 동료 등 타인에게 누설하여서는 아니된다.
                </div>
                <div className="ContractInput_Label_Copy" style={{ marginTop: "12px" }}>
                  제 7 조 (가족관계증명서 및 동의서)
                </div>
                <div className="ContractInput_Body_Copy" style={{ marginTop: "4px" }}>
                  ① 근로자는 근로계약체결 전 또는 근로가 반드시 ‘가족관계기록사항에 관한 증명서’와 ‘친권자 또는 후견인의 동의서’를 각각 1부씩
                  제출하여야 한다.
                  <br />② 근로자가 제1항에 따라 서류를 제출하지 않는 경우 사용자는 근로계약 체결을 거부할 수 있다.
                  <br />③ 근로자가 고의 또는 과실로 제1항 내지 제2항을 위반하여 사용자에게 손해를 끼친 경우에는 민ㆍ형사상의 책임을 진다.
                </div>
                <div className="ContractInput_Label_Copy" style={{ marginTop: "12px" }}>
                  제 8 조 (비밀유지의무)
                </div>
                <div className="ContractInput_Body_Copy" style={{ marginTop: "4px" }}>
                  ① 근로자는 재직 중이나 퇴직 후라도 업무상 지득한 비밀을 누설하여서는 아니된다.
                  <br />② 근로자는 재직 중이나 퇴직 후라도 업무상 지득한 회사 및 고객에 대한 정보 및 비밀을 누설하거나 개인적인 용도로 사용하여서는
                  아니된다.
                  <br />③ 근로자가 고의 또는 과실로 제1항 내지 제2항을 위반하여 사용자에게 손해를 끼친 경우에는 민ㆍ형사상의 책임을 진다.
                </div>
                <div className="ContractInput_Label_Copy" style={{ marginTop: "12px" }}>
                  제 9 조 (근로계약 해지사유)
                </div>
                <div className="ContractInput_Body_Copy" style={{ marginTop: "4px" }}>
                  사용자는 다음 각 호의 사유가 있을 때에는 근로자와의 근로계약을 해지할 수 있다.
                  <br />
                  1. 취업규칙 또는 정당한 업무명령을 위반하여 해고된 경우 사용하여서는 아니된다.
                  <br />
                  2. 학력, 경력을 위조하여 허위로 입사한 경우
                  <br />
                  3. 정당한 사유 없이 무단으로 조퇴 또는 외출, 결근을 지속적으로 반복하는 경우
                  <br />
                  4. 근로자의 일신상의 사유로 정상적인 근로제공이 불가능한 경우
                  <br />
                  5. 기타 법령 및 사회상규를 위반하여 사회통념상 계속적으로 근로관계를 유지하기 어려운 경우
                </div>
                <div className="ContractInput_Label_Copy" style={{ marginTop: "12px" }}>
                  제 10 조 (기타)
                </div>
                <div className="ContractInput_Body_Copy" style={{ marginTop: "4px" }}>
                  기타 본 계약에 명시되지 않은 사항은 근로기준법 등 노동관계법령, 취업규칙, 기타 제규정이 정하는 바에 따른다.
                </div>
                <div className="ContractInput_Label_Copy" style={{ marginTop: "12px" }}>
                  제 11 조 (근로계약서의 교부)
                </div>
                <div className="ContractInput_Body_Copy" style={{ marginTop: "4px" }}>
                  사용자는 근로자와 본 근로계약을 체결함과 동시에 근로계약서 2부를 작성하여 1부를 근로자에게 교부한다.
                </div>
                <div className="ContractInput_Label_Copy" style={{ marginTop: "12px", color: "rgb(170,170,170)" }}>
                  (근로자{" "}
                  {selectedEmployeeData?.Name && selectedEmployeeData?.Name?.length > 0 ? (
                    <span>{selectedEmployeeData.Name}</span>
                  ) : (
                    <span
                      style={{
                        color: "rgb(216, 44, 44)",
                        backgroundColor: "rgb(255, 239, 190)",
                      }}
                    >
                      ______
                    </span>
                  )}{" "}
                  은(는) 본 근로계약서를 교부받았음을 확인합니다.)
                </div>
                <div className="ContractInput_Date_Copy_CT">
                  <div className="ContractInput_Date_Copy">
                    {selectedEmployeeData?.Documents?.JuvenileContract?.ContractDate &&
                    selectedEmployeeData?.Documents?.JuvenileContract?.ContractDate?.length === 8 ? (
                      <span>{AddUnitYYYYMMDD(selectedEmployeeData.Documents.JuvenileContract.ContractDate)}</span>
                    ) : (
                      <span
                        style={{
                          color: "rgb(216, 44, 44)",
                          backgroundColor: "rgb(255, 239, 190)",
                        }}
                      >
                        ____ 년 __ 월 __ 일
                      </span>
                    )}
                  </div>
                </div>
                <ContractSignTable
                  inputCompanyName={inputCompanyName}
                  companyRepresentName={companyRepresentName}
                  employeeName={selectedEmployeeData?.Name}
                />
              </div>
            </div>
            <div className="ContractInput_VerticalDivider" />
            <div className="ContractInput_Right_CT">
              <div className="Contract_UserData_CT">
                <div className="Contract_Body_Copy" style={{ marginBottom: "8px" }}>
                  <div className="Contract_Label_Copy">이름</div> | {selectedEmployeeData?.Name}
                </div>
                <div className="Contract_Body_Copy" style={{ marginBottom: "8px" }}>
                  <div className="Contract_Label_Copy">생년월일</div> | {AddUnitYYYYMMDD(selectedEmployeeData?.Birthday || "")}
                </div>
                <div className="Contract_Body_Copy">
                  <div className="Contract_Label_Copy">전화번호</div> | {AddHyphenTelNumber(selectedEmployeeData?.TelNumber)}
                </div>
                <div className="Contract_Divider"> </div>
              </div>
              <div className="ContractInput_InputField">
                <div className="ContractInput_InputField_Label_CT">
                  <div className="Profile_Label_Copy">상호명</div>
                  <div className={inputCompanyName.length > 0 ? "Required_Dot_Blue" : "Required_Dot_Red"} />
                </div>
                <input
                  className="ContractInput_LongInput_BG"
                  value={inputCompanyName}
                  onChange={(e) => onChangeStateCompanyNameData(e.target.value)}
                  placeholder="상호명을 입력해주세요."
                  name="CompanyName"
                  required
                />
              </div>
              <div className="ContractInput_InputField">
                <div className="ContractInput_InputField_Label_CT">
                  <div className="Profile_Label_Copy">대표자명</div>
                  <div className={companyRepresentName.length > 0 ? "Required_Dot_Blue" : "Required_Dot_Red"} />
                </div>
                <input
                  className="ContractInput_LongInput_BG"
                  value={companyRepresentName}
                  onChange={(e) => onChangeStateCompanyRepresentNameData(e.target.value)}
                  placeholder="대표자명을 입력해주세요."
                  name="CompanyRepresentName"
                  required
                />
              </div>
              <div className="ContractInput_InputField">
                <div className="ContractInput_InputField_Label_CT">
                  <div className="Profile_Label_Copy">기업/기관 주소</div>
                  <div className={companyAddress.length > 0 ? "Required_Dot_Blue" : "Required_Dot_Red"} />
                </div>
                <input
                  className="ContractInput_LongInput_BG"
                  value={companyAddress}
                  onChange={(e) => onChangeStateCompanyAddressData(e.target.value)}
                  placeholder="주소를 입력해주세요."
                  name="CompanyAddress"
                  required
                />
              </div>
              {selectedEmployeeData?.NameDone ? (
                <div className="ContractInput_InputField">
                  <div className="ContractInput_InputField_Label_CT">
                    <div className="Profile_Label_Copy">{selectedEmployeeData?.Name} 님의 생년월일</div>
                    <div
                      className={
                        (selectedEmployeeData?.Birthday && selectedEmployeeData?.Birthday?.length === 8) ||
                        (selectedEmployeeData?.Documents?.JuvenileContract?.Birthday &&
                          selectedEmployeeData?.Documents?.JuvenileContract?.Birthday?.length === 8)
                          ? "Required_Dot_Blue"
                          : "Required_Dot_Red"
                      }
                    />
                  </div>
                  <input
                    className="ContractInput_LongInput_BG"
                    value={selectedEmployeeData?.Documents?.JuvenileContract?.Birthday || selectedEmployeeData?.Birthday || ""}
                    onChange={(e) => onChangeStateBirthdayData(e.target.value)}
                    placeholder="YYYYMMDD 형식으로 입력해주세요."
                    name="NewEmployeeBirthday"
                    required
                  />
                </div>
              ) : null}
              <div className="ContractInput_InputField">
                <div className="ContractInput_InputField_Label_CT">
                  <div className="Profile_Label_Copy">{selectedEmployeeData?.Name} 님의 주소</div>
                  <div
                    className={
                      (selectedEmployeeData?.Address && selectedEmployeeData?.Address?.length > 0) ||
                      (selectedEmployeeData?.Documents?.JuvenileContract?.Address &&
                        selectedEmployeeData?.Documents?.JuvenileContract?.Address?.length > 0)
                        ? "Required_Dot_Blue"
                        : "Required_Dot_Red"
                    }
                  />
                </div>
                <input
                  className="ContractInput_LongInput_BG"
                  value={selectedEmployeeData?.Documents?.JuvenileContract?.Address || selectedEmployeeData?.Address || ""}
                  onChange={(e) => onChangeStateAddressData(e.target.value)}
                  placeholder="주소를 입력해주세요."
                  name="NewEmployeeAddress"
                  required
                />
              </div>
              <div className="ContractInput_InputField">
                <div className="ContractInput_InputField_Label_CT">
                  <div className="Profile_Label_Copy">근로계약기간</div>

                  <div
                    className={
                      selectedEmployeeData?.Documents?.JuvenileContract?.EmployeeContractStartDate &&
                      selectedEmployeeData?.Documents?.JuvenileContract?.EmployeeContractStartDate?.length === 8 &&
                      selectedEmployeeData?.Documents?.JuvenileContract?.EmployeeContractEndDate &&
                      selectedEmployeeData?.Documents?.JuvenileContract?.EmployeeContractEndDate?.length === 8
                        ? "Required_Dot_Blue"
                        : "Required_Dot_Red"
                    }
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    width: "420px",
                  }}
                >
                  <input
                    className="ContractInput_ShortInput_BG"
                    value={selectedEmployeeData?.Documents?.JuvenileContract?.EmployeeContractStartDate || ""}
                    onChange={(e) => handleSelectedEmployeeDataChange("Documents", e.target.value, "EmployeeContractStartDate")}
                    placeholder="YYYYMMDD"
                    name="EmployeeContractStartDate"
                    required
                  />
                  <div className="ContractInput_Guide_Copy" style={{ marginRight: "12px" }}>
                    부터
                  </div>
                  <input
                    className="ContractInput_ShortInput_BG"
                    value={selectedEmployeeData?.Documents?.JuvenileContract?.EmployeeContractEndDate || ""}
                    onChange={(e) => handleSelectedEmployeeDataChange("Documents", e.target.value, "EmployeeContractEndDate")}
                    placeholder="YYYYMMDD"
                    name="EmployeeContractEndDate"
                    required
                  />
                  <div className="ContractInput_Guide_Copy">까지</div>
                </div>
              </div>
              <div className="ContractInput_InputField">
                <div className="ContractInput_InputField_Label_CT">
                  <div className="Profile_Label_Copy">근무 장소</div>
                  <div
                    className={
                      selectedEmployeeData?.Documents?.JuvenileContract?.WorkingPlace &&
                      selectedEmployeeData?.Documents?.JuvenileContract?.WorkingPlace?.length > 0
                        ? "Required_Dot_Blue"
                        : "Required_Dot_Red"
                    }
                  />
                </div>
                <input
                  className="ContractInput_LongInput_BG"
                  value={selectedEmployeeData?.Documents?.JuvenileContract?.WorkingPlace || ""}
                  onChange={(e) => handleSelectedEmployeeDataChange("Documents", e.target.value, "WorkingPlace")}
                  placeholder="근무 장소를 입력해주세요."
                  name="WorkingPlace"
                  required
                />
              </div>
              <div className="ContractInput_InputField">
                <div className="ContractInput_InputField_Label_CT">
                  <div className="Profile_Label_Copy">업무 내용</div>
                  <div
                    className={
                      selectedEmployeeData?.Documents?.JuvenileContract?.JobTask &&
                      selectedEmployeeData?.Documents?.JuvenileContract?.JobTask?.length > 0
                        ? "Required_Dot_Blue"
                        : "Required_Dot_Red"
                    }
                  />
                </div>
                <input
                  className="ContractInput_LongInput_BG"
                  value={selectedEmployeeData?.Documents?.JuvenileContract?.JobTask || ""}
                  onChange={(e) => handleSelectedEmployeeDataChange("Documents", e.target.value, "JobTask")}
                  placeholder="업무 내용을 입력해주세요."
                  name="JobTask"
                  required
                />
              </div>
              <div className="ContractInput_InputField">
                <div className="ContractInput_InputField_Label_CT">
                  <div className="Profile_Label_Copy">월 급여</div>
                  <div
                    className={
                      selectedEmployeeData?.Documents?.JuvenileContract?.Salary &&
                      selectedEmployeeData?.Documents?.JuvenileContract?.Salary?.length > 0
                        ? "Required_Dot_Blue"
                        : "Required_Dot_Red"
                    }
                  />
                </div>
                <input
                  className="ContractInput_LongInput_BG"
                  value={selectedEmployeeData?.Documents?.JuvenileContract?.Salary || ""}
                  onChange={(e) => handleSelectedEmployeeDataChange("Documents", e.target.value, "Salary")}
                  placeholder="숫자만 입력해주세요."
                  name="Salary"
                  required
                />
              </div>
              <div className="ContractInput_InputField">
                <div className="ContractInput_InputField_Label_CT">
                  <div className="Profile_Label_Copy">급여지급일</div>
                  <div
                    className={
                      selectedEmployeeData?.Documents?.JuvenileContract?.SalaryDay &&
                      selectedEmployeeData?.Documents?.JuvenileContract?.SalaryDay?.length > 0
                        ? "Required_Dot_Blue"
                        : "Required_Dot_Red"
                    }
                  />
                </div>
                <input
                  className="ContractInput_LongInput_BG"
                  value={selectedEmployeeData?.Documents?.JuvenileContract?.SalaryDay || ""}
                  onChange={(e) => handleSelectedEmployeeDataChange("Documents", e.target.value, "SalaryDay")}
                  placeholder="숫자만 입력해주세요."
                  name="SalaryDay"
                  required
                />
              </div>
              <div className="ContractInput_InputField">
                <div className="ContractInput_InputField_Label_CT">
                  <div className="Profile_Label_Copy">계약일</div>
                  <div
                    className={
                      selectedEmployeeData?.Documents?.JuvenileContract?.ContractDate &&
                      selectedEmployeeData?.Documents?.JuvenileContract?.ContractDate?.length === 8
                        ? "Required_Dot_Blue"
                        : "Required_Dot_Red"
                    }
                  />
                </div>
                <input
                  className="ContractInput_LongInput_BG"
                  value={selectedEmployeeData?.Documents?.JuvenileContract?.ContractDate || ""}
                  onChange={(e) => onChangeContractDateData(e.target.value)}
                  placeholder="YYYYMMDD 형식으로 입력해주세요."
                  name="ContractDate"
                  required
                />
              </div>

              <button className="ContractInput_ConfirmButton_BG" type="button" onClick={onClickConfirmInput}>
                작성 완료
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
);

export default JuvenileContract;
