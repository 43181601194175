import "../GlobalStyle.css";
import "../PCStyle.css";
import "../TabletStyle.css";
import "../MobileStyle.css";
import React from "react";
import { observer } from "mobx-react";
import axios from "axios";
import { ConvertKeysToLowercase } from "../Utils/ConvertStringFormat";
import { AddUnitYYYYMMDD, AddDotYYYYMMDD, AddHyphenTelNumber, AddCommaAmountNumber } from "../Utils/ConvertNumberFormat";
import { RemoveEmptyObjects } from "../Utils/OnChangeDocumentData";
import ContractDataTable from "../ChartTable/ContractDataTable";
import ContractSignTable from "../ChartTable/ContractSignTable";
import WorkScheduleTable from "../ChartTable/WorkScheduleTable";
import Icon from "../Assets/Icons/Icon";

const DailyContract = observer(
  ({
    setDailyContractInfo,
    inputCompanyName,
    companyRepresentName,
    companyAddress,
    setInputCompanyName,
    setCompanyRepresentName,
    setCompanyAddress,
    selectedEmployeeData,
    setSelectedEmployeeData,
    setSelectedEmployees,
    companyEmail,
    setIsLoading,
  }) => {
    const allInputFilled = () => {
      if (
        inputCompanyName.length > 0 &&
        companyRepresentName.length > 0 &&
        companyAddress.length > 0 &&
        selectedEmployeeData?.Birthday?.length === 8 &&
        selectedEmployeeData?.Address?.length > 0 &&
        selectedEmployeeData?.Documents?.DailyContract?.EmployeeContractStartDate?.length === 8 &&
        selectedEmployeeData?.Documents?.DailyContract?.EmployeeContractEndDate?.length === 8 &&
        selectedEmployeeData?.Documents?.DailyContract?.WorkingPlace?.length > 0 &&
        selectedEmployeeData?.Documents?.DailyContract?.JobTask?.length > 0 &&
        selectedEmployeeData?.Documents?.DailyContract?.Wage?.length > 0 &&
        selectedEmployeeData?.Documents?.DailyContract?.SalaryDay?.length > 0 &&
        selectedEmployeeData?.Documents?.DailyContract?.ContractDate?.length === 8 &&
        selectedEmployeeData?.Documents?.DailyContract?.UserWorkInformation[0]?.WorkScheduleTableDay?.length > 0 &&
        selectedEmployeeData?.Documents?.DailyContract?.UserWorkInformation[0]?.WorkScheduleTableWorkingHour?.length > 0 &&
        selectedEmployeeData?.Documents?.DailyContract?.UserWorkInformation[0]?.WorkScheduleTableWorkStartTime?.length > 0 &&
        selectedEmployeeData?.Documents?.DailyContract?.UserWorkInformation[0]?.WorkScheduleTableWorkEndTime?.length > 0 &&
        selectedEmployeeData?.Documents?.DailyContract?.UserWorkInformation[0]?.WorkScheduleTableRestStartTime?.length > 0 &&
        selectedEmployeeData?.Documents?.DailyContract?.UserWorkInformation[0]?.WorkScheduleTableRestEndTime?.length > 0
      ) {
        return true;
      } else {
        return false;
      }
    };

    const getErrorMessage = () => {
      let errorMessage = "";
      if (!inputCompanyName.length > 0) {
        errorMessage += "- 상호명을 입력해주세요.";
      }
      if (!companyRepresentName.length > 0) {
        errorMessage += errorMessage ? "\n- 대표자명을 입력해주세요." : "- 대표자명을 입력해주세요.";
      }
      if (!companyAddress.length > 0) {
        errorMessage += errorMessage ? "\n- 기업/기관 주소를 입력해주세요." : "- 기업/기관 주소를 입력해주세요.";
      }
      if (selectedEmployeeData.Birthday.length !== 8) {
        errorMessage += errorMessage
          ? `\n- ${selectedEmployeeData?.Name} 님의 생년월일을 입력해주세요.`
          : `- ${selectedEmployeeData?.Name} 님의 생년월일을 입력해주세요.`;
      }
      if (!selectedEmployeeData?.Address?.length > 0) {
        errorMessage += errorMessage
          ? `\n- ${selectedEmployeeData?.Name} 님의 주소를 입력해주세요.`
          : `- ${selectedEmployeeData?.Name} 님의 주소를 입력해주세요.`;
      }
      if (selectedEmployeeData?.Documents?.DailyContract?.EmployeeContractStartDate?.length !== 8) {
        errorMessage += errorMessage ? "\n- 근로계약 시작일을 입력해주세요." : "- 근로계약 시작일을 입력해주세요.";
      }
      if (selectedEmployeeData?.Documents?.DailyContract?.EmployeeContractEndDate?.length !== 8) {
        errorMessage += errorMessage ? "\n- 근로계약 종료일을 입력해주세요." : "- 근로계약 종료일을 입력해주세요.";
      }
      if (!selectedEmployeeData?.Documents?.DailyContract?.WorkingPlace?.length > 0) {
        errorMessage += errorMessage ? "\n- 근무 장소를 입력해주세요." : "- 근무 장소를 입력해주세요.";
      }
      if (!selectedEmployeeData?.Documents?.DailyContract?.JobTask?.length > 0) {
        errorMessage += errorMessage ? "\n- 업무 내용을 입력해주세요." : "- 업무 내용을 입력해주세요.";
      }
      if (!selectedEmployeeData?.Documents?.DailyContract?.Wage?.length > 0) {
        errorMessage += errorMessage ? "\n- 시급을 입력해주세요." : "- 시급을 입력해주세요.";
      }
      if (!selectedEmployeeData?.Documents?.DailyContract?.SalaryDay?.length > 0) {
        errorMessage += errorMessage ? "\n- 급여지급일을 입력해주세요." : "- 급여지급일을 입력해주세요.";
      }
      if (!selectedEmployeeData?.Documents?.DailyContract?.ContractDate?.length === 8) {
        errorMessage += errorMessage ? "\n- 계약일을 입력해주세요." : "- 계약일을 입력해주세요.";
      }
      if (!selectedEmployeeData?.Documents?.DailyContract?.UserWorkInformation[0]?.WorkScheduleTableDay?.length > 0) {
        errorMessage += errorMessage
          ? "\n- 제 3 조의 근무표 '요일' 필수값을 입력해주세요. (표에 직접 입력)"
          : "- 제 3 조의 근무표 '요일' 필수값을 입력해주세요. (표에 직접 입력)";
      }
      if (!selectedEmployeeData?.Documents?.DailyContract?.UserWorkInformation[0]?.WorkScheduleTableWorkingHour?.length > 0) {
        errorMessage += errorMessage
          ? "\n- 제 3 조의 근무표 '근무시간' 필수값을 입력해주세요. (표에 직접 입력)"
          : "- 제 3 조의 근무표 '근무시간' 필수값을 입력해주세요. (표에 직접 입력)";
      }
      if (!selectedEmployeeData?.Documents?.DailyContract?.UserWorkInformation[0]?.WorkScheduleTableWorkStartTime?.length > 0) {
        errorMessage += errorMessage
          ? "\n- 제 3 조의 근무표 '출근시각' 필수값을 입력해주세요. (표에 직접 입력)"
          : "- 제 3 조의 근무표 '출근시각' 필수값을 입력해주세요. (표에 직접 입력)";
      }

      if (!selectedEmployeeData?.Documents?.DailyContract?.UserWorkInformation[0]?.WorkScheduleTableWorkEndTime?.length > 0) {
        errorMessage += errorMessage
          ? "\n- 제 3 조의 근무표 '퇴근시각' 필수값을 입력해주세요. (표에 직접 입력)"
          : "- 제 3 조의 근무표 '퇴근시각' 필수값을 입력해주세요. (표에 직접 입력)";
      }

      if (!selectedEmployeeData?.Documents?.DailyContract?.UserWorkInformation[0]?.WorkScheduleTableRestStartTime?.length > 0) {
        errorMessage += errorMessage
          ? "\n- 제 3 조의 근무표 '휴게시간 시작시각' 필수값을 입력해주세요. (표에 직접 입력)"
          : "- 제 3 조의 근무표 '휴게시간 시작시각' 필수값을 입력해주세요. (표에 직접 입력)";
      }

      if (!selectedEmployeeData?.Documents?.DailyContract?.UserWorkInformation[0]?.WorkScheduleTableRestEndTime?.length > 0) {
        errorMessage += errorMessage
          ? "\n- 제 3 조의 근무표 '휴게시간 종료시각' 필수값을 입력해주세요. (표에 직접 입력)"
          : "- 제 3 조의 근무표 '휴게시간 종료시각' 필수값을 입력해주세요. (표에 직접 입력)";
      }
      return errorMessage;
    };

    const handleSelectedEmployeeDataChange = (field, value, nestedField) => {
      setSelectedEmployeeData((prevData) => {
        const updatedData = { ...prevData };

        if (nestedField) {
          if (!updatedData.Documents) {
            updatedData.Documents = {};
          }
          if (!updatedData.Documents.DailyContract) {
            updatedData.Documents.DailyContract = {};
          }
          if (!updatedData.Documents.DailyContract.UserWorkInformation) {
            updatedData.Documents.DailyContract.UserWorkInformation = [];
          }

          const match = nestedField.match(/WorkScheduleTable(?:Day|WorkingHour|WorkStartTime|WorkEndTime|RestStartTime|RestEndTime)(\d*)/);
          const removeLastCharacter = (str) => str.slice(0, -1);
          if (match) {
            const index = parseInt(match[1], 10) - 1; // Convert to zero-based index

            // Ensure the array has enough length
            while (updatedData.Documents.DailyContract.UserWorkInformation.length <= index) {
              updatedData.Documents.DailyContract.UserWorkInformation.push({});
            }

            // Update the specific field in the specific object
            updatedData.Documents.DailyContract.UserWorkInformation[index][removeLastCharacter(nestedField)] = value;
          } else {
            updatedData.Documents.DailyContract[nestedField] = value;
          }
        } else {
          updatedData[field] = value;
        }

        // selectedEmployees 배열을 업데이트
        setSelectedEmployees((prevEmployees) => prevEmployees.map((employee) => (employee.ID === updatedData.ID ? updatedData : employee)));

        return updatedData;
      });
    };

    const onChangeStateCompanyNameData = (value) => {
      setInputCompanyName(value);
      handleSelectedEmployeeDataChange("Documents", value, "InputCompanyName");
    };

    const onChangeStateCompanyAddressData = (value) => {
      setCompanyAddress(value);
      handleSelectedEmployeeDataChange("Documents", value, "CompanyAddress");
    };

    const onChangeStateCompanyRepresentNameData = (value) => {
      setCompanyRepresentName(value);
      handleSelectedEmployeeDataChange("Documents", value, "CompanyRepresentName");
    };

    const onChangeStateAddressData = (value) => {
      handleSelectedEmployeeDataChange("Documents", value, "Address");
      handleSelectedEmployeeDataChange("Address", value);
    };

    const onChangeStateBirthdayData = (value) => {
      handleSelectedEmployeeDataChange("Documents", value, "Birthday");
      handleSelectedEmployeeDataChange("Birthday", value);
    };

    const onChangeContractDateData = (value) => {
      handleSelectedEmployeeDataChange("Documents", value, "ContractDate");
      handleSelectedEmployeeDataChange("Documents", inputCompanyName, "InputCompanyName");
      handleSelectedEmployeeDataChange("Documents", companyRepresentName, "CompanyRepresentName");
      handleSelectedEmployeeDataChange("Documents", companyAddress, "CompanyAddress");
      handleSelectedEmployeeDataChange("Documents", selectedEmployeeData?.Name, "Name");
      handleSelectedEmployeeDataChange("Documents", selectedEmployeeData?.Birthday, "Birthday");
      handleSelectedEmployeeDataChange("Documents", selectedEmployeeData?.Address, "Address");
      handleSelectedEmployeeDataChange("Documents", selectedEmployeeData?.TelNumber, "TelNumber");
    };

    const onClickConfirmInput = async () => {
      if (allInputFilled()) {
        setIsLoading(true);
        const selectedEmployeeDataCopy = { ...selectedEmployeeData };

        selectedEmployeeDataCopy.TelNumber = AddHyphenTelNumber(selectedEmployeeDataCopy?.TelNumber);
        selectedEmployeeDataCopy.Documents.DailyContract.TelNumber = AddHyphenTelNumber(
          selectedEmployeeDataCopy?.Documents?.DailyContract?.TelNumber
        );
        selectedEmployeeDataCopy.Documents.DailyContract.Birthday = AddDotYYYYMMDD(selectedEmployeeDataCopy?.Documents?.DailyContract?.Birthday);
        selectedEmployeeDataCopy.Documents.DailyContract.Wage = AddCommaAmountNumber(selectedEmployeeDataCopy?.Documents?.DailyContract?.Wage);
        selectedEmployeeDataCopy.Documents.DailyContract.UserWorkInformation = RemoveEmptyObjects(
          selectedEmployeeDataCopy.Documents.DailyContract.UserWorkInformation
        );
        setTimeout(async () => {
          try {
            const endPoint = "https://dml1u35aa3.execute-api.ap-northeast-2.amazonaws.com/product/pdf/daily-contract";
            const response = await axios.post(endPoint, {
              email: companyEmail,
              telNumber: selectedEmployeeDataCopy?.TelNumber,
              name: selectedEmployeeData?.Name,
              data: ConvertKeysToLowercase(selectedEmployeeDataCopy?.Documents?.DailyContract),
              ...(selectedEmployeeData.NameDone ? {} : { generalDocument: true }),
            });
            if (response.data.message === "일용직근로계약서 생성 완료") {
              handleSelectedEmployeeDataChange("Documents", response.data.url, "Url");
              setTimeout(async () => {
                setIsLoading(false);
                setDailyContractInfo(false);
                alert("일용직 근로계약서 작성을 완료했습니다.");
              }, 100);
            } else {
              setIsLoading(false);
              setDailyContractInfo(false);
              alert("일용직 근로계약서 작성에 실패하였습니다. 다시 한번 시도해 주세요.");
            }
          } catch (error) {
            console.log("DailyContract ERROR : ", error);
            setIsLoading(false);
            setDailyContractInfo(false);
            alert("일용직 근로계약서 작성에 실패하였습니다. 다시 한번 시도해 주세요.");
          }
        }, 100);
      } else {
        alert(`${getErrorMessage()}`);
      }
    };

    return (
      <div className="Signin_CompleteModal_BG">
        <div className="ContractInput_Modal_CT">
          <div className="ContractInput_Close_CT">
            <div className="CheckList_InfoModal_Title_Copy">일용직 근로계약서</div>
            <div className="CheckList_InfoModal_Icon_CT">
              <button className="CheckList_Close_Button" onClick={() => setDailyContractInfo(false)}>
                <Icon name={"Close"} size={"24"} />
              </button>
            </div>
          </div>
          <div className="ContractInput_Contents_CT">
            <div className="ContractInput_Left_CT">
              <div className="ContractInput_Left_Content_CT">
                <ContractDataTable
                  inputCompanyName={inputCompanyName}
                  companyRepresentName={companyRepresentName}
                  companyAddress={companyAddress}
                  employeeName={selectedEmployeeData?.Name}
                  employeeBirthday={selectedEmployeeData?.Birthday}
                  employeeAddress={selectedEmployeeData?.Address}
                  employeeTelNumber={selectedEmployeeData?.TelNumber}
                />

                <div className="ContractInput_Body_Copy" style={{ marginTop: "20px" }}>
                  당사자는 다음과 같이 근로계약을 체결하고, 성실히 이행할 것을 약정한다.
                </div>
                <div className="ContractInput_Label_Copy" style={{ marginTop: "12px" }}>
                  제 1 조 (근로개시일과 근로자의 의무)
                </div>
                <div className="ContractInput_Body_Copy" style={{ marginTop: "4px" }}>
                  ① 근로계약기간은{" "}
                  {selectedEmployeeData?.Documents?.DailyContract?.EmployeeContractStartDate &&
                  selectedEmployeeData?.Documents?.DailyContract?.EmployeeContractStartDate?.length === 8 ? (
                    <span style={{ color: "rgb(55, 105, 252)" }}>
                      {AddUnitYYYYMMDD(selectedEmployeeData.Documents.DailyContract.EmployeeContractStartDate)}
                    </span>
                  ) : (
                    <span
                      style={{
                        color: "rgb(216, 44, 44)",
                        backgroundColor: "rgb(255, 239, 190)",
                      }}
                    >
                      ____ 년 __ 월 __ 일
                    </span>
                  )}{" "}
                  부터{" "}
                  {selectedEmployeeData?.Documents?.DailyContract?.EmployeeContractEndDate &&
                  selectedEmployeeData?.Documents?.DailyContract?.EmployeeContractEndDate?.length === 8 ? (
                    <span style={{ color: "rgb(55, 105, 252)" }}>
                      {AddUnitYYYYMMDD(selectedEmployeeData.Documents.DailyContract.EmployeeContractEndDate)}
                    </span>
                  ) : (
                    <span
                      style={{
                        color: "rgb(216, 44, 44)",
                        backgroundColor: "rgb(255, 239, 190)",
                      }}
                    >
                      ____ 년 __ 월 __ 일
                    </span>
                  )}{" "}
                  까지로 한다.
                  <br />② 본 계약은 1일 단위로 근로계약이 체결되고 만료되는 일용직 근로계약이나 실무적 편의를 위해 제1항과 같이 상당한 기간을 정한
                  것으로 한다. 단, 상황에 따라 사용자와 근로자의 합의하여 제1항의 기간을 연장 및 단축할 수 있다.
                  <br />③ 근로자는 계약기간 동안 회사의 제규정 및 관계법령을 준수하며 회사가 지시한 업무를 신의에 따라 성실히 수행하여야 한다.
                </div>
                <div className="ContractInput_Label_Copy" style={{ marginTop: "12px" }}>
                  제 2 조 (근무장소 및 업무내용)
                </div>
                <div className="ContractInput_Body_Copy" style={{ marginTop: "4px" }}>
                  ① 근무장소는{" "}
                  {selectedEmployeeData?.Documents?.DailyContract?.WorkingPlace &&
                  selectedEmployeeData?.Documents?.DailyContract?.WorkingPlace?.length > 0 ? (
                    <span style={{ color: "rgb(55, 105, 252)" }}>{selectedEmployeeData.Documents.DailyContract.WorkingPlace}</span>
                  ) : (
                    <span
                      style={{
                        color: "rgb(216, 44, 44)",
                        backgroundColor: "rgb(255, 239, 190)",
                      }}
                    >
                      ____________
                    </span>
                  )}{" "}
                  (으)로 한다.
                  <br />② 업무내용은{" "}
                  {selectedEmployeeData?.Documents?.DailyContract?.JobTask && selectedEmployeeData?.Documents?.DailyContract?.JobTask?.length > 0 ? (
                    <span style={{ color: "rgb(55, 105, 252)" }}>{selectedEmployeeData.Documents.DailyContract.JobTask}</span>
                  ) : (
                    <span
                      style={{
                        color: "rgb(216, 44, 44)",
                        backgroundColor: "rgb(255, 239, 190)",
                      }}
                    >
                      ____________
                    </span>
                  )}{" "}
                  (으)로 한다.
                  <br />③ 사용자는 업무상 또는 경영상 필요하다고 인정되는 경우 제1항과 제2항의 내용을 변경할 수 있다. 이 경우 근로자는 정당한 사유
                  없이 이를 거부할 수 없다.
                </div>
                <div className="ContractInput_Label_Copy" style={{ marginTop: "12px" }}>
                  제 3 조 (근로시간 및 휴게시간)
                </div>
                <div className="ContractInput_Body_Copy" style={{ marginTop: "4px" }}>
                  ① 근로일 및 근로일별 근로시간, 휴게시간은 다음과 같이 적용한다. 단, 사용자는 업무의 특성 또는 사업장 사정에 따라 필요한 경우 이를
                  변경할 수 있다.
                </div>
                <div
                  className="ContractInput_Body_Copy"
                  style={{
                    marginBottom: "0px",
                    marginTop: "4px",
                    color: "rgb(55, 105, 252)",
                  }}
                >
                  ※ 아래 근무표에 정보를 직접 입력할 수 있습니다. <span style={{ color: "red" }}>붉은 글자</span>는 필수 입력값 입니다.
                </div>
                <WorkScheduleTable
                  selectedEmployeeData={selectedEmployeeData}
                  documentName={"DailyContract"}
                  handleSelectedEmployeeDataChange={handleSelectedEmployeeDataChange}
                />
                <div className="ContractInput_Body_Copy" style={{ marginTop: "4px" }}>
                  ② 제1항의 소정근로시간을 초과하여 근로하는 경우 사용자는 초과근로에 대하여 통상임금의 100분의 50이상을 가산하여 지급한다.
                </div>
                <div className="ContractInput_Label_Copy" style={{ marginTop: "12px" }}>
                  제 4 조 (휴일)
                </div>
                <div className="ContractInput_Body_Copy" style={{ marginTop: "4px" }}>
                  ① 주휴일과 근로자의 날(5월 1일)은 유급휴일로 한다.
                  <br />② 제3조의 근로시간이 주 15시간 이상인 근로자에 한하여 주휴일은 일요일로 한다. 단, 근로자가 1주 동안 제4조 제1항의 소정근로일을
                  개근한 경우에 한하여 주휴일을 유급으로 부여한다.
                  <br />③ 제1항의 주휴일은 회사의 운영상황 및 업무스케쥴을 고려하여 조정할 수 있으며, 조정 시에는 사전에 공지한다
                  <br />④ 주휴일과 근로자의 날이 중복되는 경우 1일의 휴일로 처리한다.
                  <br />⑤ 기타 직장이 직종별로 업무사정을 고려하여 휴일을 부여할 수 있다.
                </div>
                <div className="ContractInput_Label_Copy" style={{ marginTop: "12px" }}>
                  제 5 조 (연차유급휴가)
                </div>
                <div className="ContractInput_Body_Copy" style={{ marginTop: "4px" }}>
                  ① 제3조의 근로시간이 주 15시간 이상인 근로자에 한하여 연차유급휴가는 근로기준법에 따라 부여한다.
                  <br />② 제1항에 따라 연차유급휴가가 발생하는 경우 통상 근로자의 근로시간에 비례하여 부여한다.
                </div>
                <div className="ContractInput_Label_Copy" style={{ marginTop: "12px" }}>
                  제 6 조 (임금)
                </div>
                <div className="ContractInput_Body_Copy" style={{ marginTop: "4px" }}>
                  ① 시급은 금{" "}
                  {selectedEmployeeData?.Documents?.DailyContract?.Wage && selectedEmployeeData?.Documents?.DailyContract?.Wage?.length > 0 ? (
                    <span style={{ color: "rgb(55, 105, 252)" }}>{AddCommaAmountNumber(selectedEmployeeData.Documents.DailyContract.Wage)}</span>
                  ) : (
                    <span
                      style={{
                        color: "rgb(216, 44, 44)",
                        backgroundColor: "rgb(255, 239, 190)",
                      }}
                    >
                      ____________
                    </span>
                  )}{" "}
                  원으로 한다.
                  <br />② 사용자는 근로자에게 지급하는 위 임금에서 국민연금, 건강보험, 고용보험, 소득세 및 주민세 등 법령이 정하는 세금을 공제하고
                  지급한다.
                  <br />③ 임금의 지급은 매월 1일부터 말일까지 기산하여{" "}
                  {selectedEmployeeData?.Documents?.DailyContract?.SalaryDay &&
                  selectedEmployeeData?.Documents?.DailyContract?.SalaryDay?.length > 0 ? (
                    <span style={{ color: "rgb(55, 105, 252)" }}>{selectedEmployeeData.Documents.DailyContract.SalaryDay}</span>
                  ) : (
                    <span
                      style={{
                        color: "rgb(216, 44, 44)",
                        backgroundColor: "rgb(255, 239, 190)",
                      }}
                    >
                      ____
                    </span>
                  )}{" "}
                  일에 근로자 명의의 금융계좌로 지급하며 지급일이 휴일인 경우에는 순차적으로 그 전일에 지급함을 원칙으로 한다. 단, 근로자가 원하는
                  경우 직접 지급할 수 있다.
                  <br />④ 근로자는 본인 및 타인의 임금에 관한 사항에 대하여 동료 등 타인에게 누설하여서는 아니된다.
                </div>

                <div className="ContractInput_Label_Copy" style={{ marginTop: "12px" }}>
                  제 7 조 (비밀유지의무)
                </div>
                <div className="ContractInput_Body_Copy" style={{ marginTop: "4px" }}>
                  ① 근로자는 재직 중이나 퇴직 후라도 업무상 지득한 비밀을 누설하여서는 아니된다.
                  <br />② 근로자는 재직 중이나 퇴직 후라도 업무상 지득한 회사 및 고객에 대한 정보 및 비밀을 누설하거나 개인적인 용도로 사용하여서는
                  아니된다.
                  <br />③ 근로자가 고의 또는 과실로 제1항 내지 제2항을 위반하여 사용자에게 손해를 끼친 경우에는 민ㆍ형사상의 책임을 진다.
                </div>
                <div className="ContractInput_Label_Copy" style={{ marginTop: "12px" }}>
                  제 8 조 (근로계약 해지사유)
                </div>
                <div className="ContractInput_Body_Copy" style={{ marginTop: "4px" }}>
                  사용자는 다음 각 호의 사유가 있을 때에는 근로자와의 근로계약을 해지할 수 있다.
                  <br />
                  1. 취업규칙 또는 정당한 업무명령을 위반하여 해고된 경우 사용하여서는 아니된다.
                  <br />
                  2. 학력, 경력을 위조하여 허위로 입사한 경우
                  <br />
                  3. 정당한 사유 없이 무단으로 조퇴 또는 외출, 결근을 지속적으로 반복하는 경우
                  <br />
                  4. 근로자의 일신상의 사유로 정상적인 근로제공이 불가능한 경우
                  <br />
                  5. 기타 법령 및 사회상규를 위반하여 사회통념상 계속적으로 근로관계를 유지하기 어려운 경우
                </div>
                <div className="ContractInput_Label_Copy" style={{ marginTop: "12px" }}>
                  제 9 조 (기타)
                </div>
                <div className="ContractInput_Body_Copy" style={{ marginTop: "4px" }}>
                  기타 본 계약에 명시되지 않은 사항은 근로기준법 등 노동관계법령, 취업규칙, 기타 제규정이 정하는 바에 따른다.
                </div>
                <div className="ContractInput_Label_Copy" style={{ marginTop: "12px" }}>
                  제 10 조 (근로계약서의 교부)
                </div>
                <div className="ContractInput_Body_Copy" style={{ marginTop: "4px" }}>
                  사용자는 근로자와 본 근로계약을 체결함과 동시에 근로계약서 2부를 작성하여 1부를 근로자에게 교부한다.
                </div>
                <div className="ContractInput_Label_Copy" style={{ marginTop: "12px", color: "rgb(170,170,170)" }}>
                  (근로자{" "}
                  {selectedEmployeeData?.Name && selectedEmployeeData?.Name?.length > 0 ? (
                    <span>{selectedEmployeeData.Name}</span>
                  ) : (
                    <span
                      style={{
                        color: "rgb(216, 44, 44)",
                        backgroundColor: "rgb(255, 239, 190)",
                      }}
                    >
                      ______
                    </span>
                  )}{" "}
                  은(는) 본 근로계약서를 교부받았음을 확인합니다.)
                </div>
                <div className="ContractInput_Date_Copy_CT">
                  <div className="ContractInput_Date_Copy">
                    {selectedEmployeeData?.Documents?.DailyContract?.ContractDate &&
                    selectedEmployeeData?.Documents?.DailyContract?.ContractDate?.length === 8 ? (
                      <span>{AddUnitYYYYMMDD(selectedEmployeeData.Documents.DailyContract.ContractDate)}</span>
                    ) : (
                      <span
                        style={{
                          color: "rgb(216, 44, 44)",
                          backgroundColor: "rgb(255, 239, 190)",
                        }}
                      >
                        ____ 년 __ 월 __ 일
                      </span>
                    )}
                  </div>
                </div>
                <ContractSignTable
                  inputCompanyName={inputCompanyName}
                  companyRepresentName={companyRepresentName}
                  employeeName={selectedEmployeeData?.Name}
                />
              </div>
            </div>
            <div className="ContractInput_VerticalDivider" />
            <div className="ContractInput_Right_CT">
              <div className="Contract_UserData_CT">
                <div className="Contract_Body_Copy" style={{ marginBottom: "8px" }}>
                  <div className="Contract_Label_Copy">이름</div> | {selectedEmployeeData?.Name}
                </div>
                <div className="Contract_Body_Copy" style={{ marginBottom: "8px" }}>
                  <div className="Contract_Label_Copy">생년월일</div> | {AddUnitYYYYMMDD(selectedEmployeeData?.Birthday || "")}
                </div>
                <div className="Contract_Body_Copy">
                  <div className="Contract_Label_Copy">전화번호</div> | {AddHyphenTelNumber(selectedEmployeeData?.TelNumber)}
                </div>
                <div className="Contract_Divider"> </div>
              </div>
              <div className="ContractInput_InputField">
                <div className="ContractInput_InputField_Label_CT">
                  <div className="Profile_Label_Copy">상호명</div>
                  <div className={inputCompanyName.length > 0 ? "Required_Dot_Blue" : "Required_Dot_Red"} />
                </div>
                <input
                  className="ContractInput_LongInput_BG"
                  value={inputCompanyName}
                  onChange={(e) => onChangeStateCompanyNameData(e.target.value)}
                  placeholder="상호명을 입력해주세요."
                  name="CompanyName"
                  required
                />
              </div>
              <div className="ContractInput_InputField">
                <div className="ContractInput_InputField_Label_CT">
                  <div className="Profile_Label_Copy">대표자명</div>
                  <div className={companyRepresentName.length > 0 ? "Required_Dot_Blue" : "Required_Dot_Red"} />
                </div>
                <input
                  className="ContractInput_LongInput_BG"
                  value={companyRepresentName}
                  onChange={(e) => onChangeStateCompanyRepresentNameData(e.target.value)}
                  placeholder="대표자명을 입력해주세요."
                  name="CompanyRepresentName"
                  required
                />
              </div>
              <div className="ContractInput_InputField">
                <div className="ContractInput_InputField_Label_CT">
                  <div className="Profile_Label_Copy">기업/기관 주소</div>
                  <div className={companyAddress.length > 0 ? "Required_Dot_Blue" : "Required_Dot_Red"} />
                </div>
                <input
                  className="ContractInput_LongInput_BG"
                  value={companyAddress}
                  onChange={(e) => onChangeStateCompanyAddressData(e.target.value)}
                  placeholder="주소를 입력해주세요."
                  name="CompanyAddress"
                  required
                />
              </div>
              {selectedEmployeeData?.NameDone ? (
                <div className="ContractInput_InputField">
                  <div className="ContractInput_InputField_Label_CT">
                    <div className="Profile_Label_Copy">{selectedEmployeeData?.Name} 님의 생년월일</div>
                    <div
                      className={
                        (selectedEmployeeData?.Birthday && selectedEmployeeData?.Birthday?.length === 8) ||
                        (selectedEmployeeData?.Documents?.DailyContract?.Birthday &&
                          selectedEmployeeData?.Documents?.DailyContract?.Birthday?.length === 8)
                          ? "Required_Dot_Blue"
                          : "Required_Dot_Red"
                      }
                    />
                  </div>
                  <input
                    className="ContractInput_LongInput_BG"
                    value={selectedEmployeeData?.Documents?.DailyContract?.Birthday || selectedEmployeeData?.Birthday || ""}
                    onChange={(e) => onChangeStateBirthdayData(e.target.value)}
                    placeholder="YYYYMMDD 형식으로 입력해주세요."
                    name="NewEmployeeBirthday"
                    required
                  />
                </div>
              ) : null}
              <div className="ContractInput_InputField">
                <div className="ContractInput_InputField_Label_CT">
                  <div className="Profile_Label_Copy">{selectedEmployeeData?.Name} 님의 주소</div>
                  <div
                    className={
                      (selectedEmployeeData?.Address && selectedEmployeeData?.Address?.length > 0) ||
                      (selectedEmployeeData?.Documents?.DailyContract?.Address && selectedEmployeeData?.Documents?.DailyContract?.Address?.length > 0)
                        ? "Required_Dot_Blue"
                        : "Required_Dot_Red"
                    }
                  />
                </div>
                <input
                  className="ContractInput_LongInput_BG"
                  value={selectedEmployeeData?.Documents?.DailyContract?.Address || selectedEmployeeData?.Address || ""}
                  onChange={(e) => onChangeStateAddressData(e.target.value)}
                  placeholder="주소를 입력해주세요."
                  name="NewEmployeeAddress"
                  required
                />
              </div>

              <div className="ContractInput_InputField">
                <div className="ContractInput_InputField_Label_CT">
                  <div className="Profile_Label_Copy">근로계약기간</div>

                  <div
                    className={
                      selectedEmployeeData?.Documents?.DailyContract?.EmployeeContractStartDate &&
                      selectedEmployeeData?.Documents?.DailyContract?.EmployeeContractStartDate?.length === 8 &&
                      selectedEmployeeData?.Documents?.DailyContract?.EmployeeContractEndDate &&
                      selectedEmployeeData?.Documents?.DailyContract?.EmployeeContractEndDate?.length === 8
                        ? "Required_Dot_Blue"
                        : "Required_Dot_Red"
                    }
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    width: "420px",
                  }}
                >
                  <input
                    className="ContractInput_ShortInput_BG"
                    value={selectedEmployeeData?.Documents?.DailyContract?.EmployeeContractStartDate || ""}
                    onChange={(e) => handleSelectedEmployeeDataChange("Documents", e.target.value, "EmployeeContractStartDate")}
                    placeholder="YYYYMMDD"
                    name="EmployeeContractStartDate"
                    required
                  />
                  <div className="ContractInput_Guide_Copy" style={{ marginRight: "12px" }}>
                    부터
                  </div>
                  <input
                    className="ContractInput_ShortInput_BG"
                    value={selectedEmployeeData?.Documents?.DailyContract?.EmployeeContractEndDate || ""}
                    onChange={(e) => handleSelectedEmployeeDataChange("Documents", e.target.value, "EmployeeContractEndDate")}
                    placeholder="YYYYMMDD"
                    name="EmployeeContractEndDate"
                    required
                  />
                  <div className="ContractInput_Guide_Copy">까지</div>
                </div>
              </div>
              <div className="ContractInput_InputField">
                <div className="ContractInput_InputField_Label_CT">
                  <div className="Profile_Label_Copy">근무 장소</div>
                  <div
                    className={
                      selectedEmployeeData?.Documents?.DailyContract?.WorkingPlace &&
                      selectedEmployeeData?.Documents?.DailyContract?.WorkingPlace?.length > 0
                        ? "Required_Dot_Blue"
                        : "Required_Dot_Red"
                    }
                  />
                </div>
                <input
                  className="ContractInput_LongInput_BG"
                  value={selectedEmployeeData?.Documents?.DailyContract?.WorkingPlace || ""}
                  onChange={(e) => handleSelectedEmployeeDataChange("Documents", e.target.value, "WorkingPlace")}
                  placeholder="근무 장소를 입력해주세요."
                  name="WorkingPlace"
                  required
                />
              </div>
              <div className="ContractInput_InputField">
                <div className="ContractInput_InputField_Label_CT">
                  <div className="Profile_Label_Copy">업무 내용</div>
                  <div
                    className={
                      selectedEmployeeData?.Documents?.DailyContract?.JobTask && selectedEmployeeData?.Documents?.DailyContract?.JobTask?.length > 0
                        ? "Required_Dot_Blue"
                        : "Required_Dot_Red"
                    }
                  />
                </div>
                <input
                  className="ContractInput_LongInput_BG"
                  value={selectedEmployeeData?.Documents?.DailyContract?.JobTask || ""}
                  onChange={(e) => handleSelectedEmployeeDataChange("Documents", e.target.value, "JobTask")}
                  placeholder="업무 내용을 입력해주세요."
                  name="JobTask"
                  required
                />
              </div>
              <div className="ContractInput_InputField">
                <div className="ContractInput_InputField_Label_CT">
                  <div className="Profile_Label_Copy">시급</div>
                  <div
                    className={
                      selectedEmployeeData?.Documents?.DailyContract?.Wage && selectedEmployeeData?.Documents?.DailyContract?.Wage?.length > 0
                        ? "Required_Dot_Blue"
                        : "Required_Dot_Red"
                    }
                  />
                </div>
                <input
                  className="ContractInput_LongInput_BG"
                  value={selectedEmployeeData?.Documents?.DailyContract?.Wage || ""}
                  onChange={(e) => handleSelectedEmployeeDataChange("Documents", e.target.value, "Wage")}
                  placeholder="숫자만 입력해주세요."
                  name="Wage"
                  required
                />
              </div>
              <div className="ContractInput_InputField">
                <div className="ContractInput_InputField_Label_CT">
                  <div className="Profile_Label_Copy">급여지급일</div>
                  <div
                    className={
                      selectedEmployeeData?.Documents?.DailyContract?.SalaryDay &&
                      selectedEmployeeData?.Documents?.DailyContract?.SalaryDay?.length > 0
                        ? "Required_Dot_Blue"
                        : "Required_Dot_Red"
                    }
                  />
                </div>
                <input
                  className="ContractInput_LongInput_BG"
                  value={selectedEmployeeData?.Documents?.DailyContract?.SalaryDay || ""}
                  onChange={(e) => handleSelectedEmployeeDataChange("Documents", e.target.value, "SalaryDay")}
                  placeholder="숫자만 입력해주세요."
                  name="SalaryDay"
                  required
                />
              </div>
              <div className="ContractInput_InputField">
                <div className="ContractInput_InputField_Label_CT">
                  <div className="Profile_Label_Copy">계약일</div>
                  <div
                    className={
                      selectedEmployeeData?.Documents?.DailyContract?.ContractDate &&
                      selectedEmployeeData?.Documents?.DailyContract?.ContractDate?.length === 8
                        ? "Required_Dot_Blue"
                        : "Required_Dot_Red"
                    }
                  />
                </div>
                <input
                  className="ContractInput_LongInput_BG"
                  value={selectedEmployeeData?.Documents?.DailyContract?.ContractDate || ""}
                  onChange={(e) => onChangeContractDateData(e.target.value)}
                  placeholder="YYYYMMDD 형식으로 입력해주세요."
                  name="ContractDate"
                  required
                />
              </div>
              <button className="ContractInput_ConfirmButton_BG" type="button" onClick={onClickConfirmInput}>
                작성 완료
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
);

export default DailyContract;
