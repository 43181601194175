import "../GlobalStyle.css";
import "../PCStyle.css";
import "../TabletStyle.css";
import "../MobileStyle.css";
import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import * as amplitude from "@amplitude/analytics-browser";
import axios from "axios";
import { LoadComapanyData } from "../Utils/LoadCompanyData";
import Loading from "../Components/Loading";
// import ReferCheckList from "../Components/ReferCheckList";
// import NavigationPanel from "../Components/NavigationPanel";
import WidgetList from "../Components/Organism/WidgetList";
import PageTitle from "../Components/PageTitle";
import WidgetNavigation from "../Components/Organism/WidgetNavigation";
import WidgetHeader from "../Components/Organism/WidgetHeader";

const WB41IssueDocumentSub1 = observer(({ setDocument1, setDocument2, selectedEmployees, setSelectedEmployees }) => {
  const [employeeData, setEmployeeData] = useState([]);
  const [companyEmail, setCompanyEmail] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const userData = LoadComapanyData("userData");
    if (userData) {
      setCompanyEmail(userData.companyEmail);
    } else {
      console.log("No userData found.");
    }
  }, []);

  const onClickNext = () => {
    amplitude.track("문서 발급2 - 문서 선택 페이지 진입");
    if (selectedEmployees.length > 0) {
      setDocument1(false);
      setDocument2(true);
    } else {
      alert("직원을 선택해주세요.");
    }
  };

  useEffect(() => {
    if (!companyEmail) return;
    setIsLoading(true);
    const loadEmployeeData = async () => {
      const endPoint = "https://dml1u35aa3.execute-api.ap-northeast-2.amazonaws.com/product/employee";
      try {
        const response = await axios.get(endPoint, {
          params: {
            email: companyEmail,
          },
        });
        if (response.data.status === "직원 정보 조회 성공") {
          const rawEmployeeData = response.data.data.Employee;
          rawEmployeeData.sort((a, b) => {
            return a.Name.localeCompare(b.Name);
          });
          const filteredEmployeeData = rawEmployeeData.filter((employee) => employee.Birthday);

          setEmployeeData(filteredEmployeeData);
          setIsLoading(false);
        } else {
          alert("직원 정보 조회에 실패하였습니다. 다시 시도해주세요.");
          setIsLoading(false);
        }
        setIsLoading(false);
      } catch (error) {
        console.error("Error : ", error);
        alert("직원 정보 조회 통신에 실패하였습니다. 다시 시도해주세요.");
        setIsLoading(false);
      }
    };

    loadEmployeeData();
  }, [companyEmail]);

  const handleCheckBoxChange = (employee) => {
    setSelectedEmployees((prevSelected) => {
      if (prevSelected.includes(employee)) {
        return prevSelected.filter((e) => e !== employee);
      } else {
        return [...prevSelected, employee];
      }
    });
  };

  return (
    <>
      {isLoading ? (
        <div className="Wait_Lists_CT">
          <Loading />
        </div>
      ) : !isLoading && employeeData.length === 0 ? (
        <div className="Wait_NoneItem_Copy">
          등록된 직원 정보가 없습니다.
          <br />
          직원 등록을 먼저 진행해주세요.
        </div>
      ) : !isLoading && employeeData.length > 0 ? (
        <>
          <PageTitle icon={"Certificate"} title={"문서 작성"} />
          <div className="Widget_CT_TBD">
            <div className="Widget_Contents_CT" style={{ justifyContent: "flex-start" }}>
              <WidgetNavigation nextButtonLabel={"다음"} onClickNextButton={onClickNext} />
              <WidgetHeader
                titleText={"문서를 수령할 직원을 선택해주세요."}
                bodyText={"직원이 등록되어 있지않다면 직원 등록을 먼저 진행해주세요.\n필수정보가 모두 등록된 직원에게 문서를 작성할 수 있습니다."}
                pageNumber={"1 / 4"}
              />
              <WidgetList
                type={"multiCheckbox"}
                selectedListItemArray={selectedEmployees}
                setSelectedListItemArray={setSelectedEmployees}
                listDataArray={employeeData}
                onClickCheckbox={handleCheckBoxChange}
                itemsPerPage={12}
                height={"480px"}
                fieldList={["이름", "생년월일", "부서", "입사일"]}
              />
            </div>
          </div>
        </>
      ) : (
        <div className="Wait_NoneItem_Copy">
          등록된 직원 정보가 없습니다. <br />
          직원 등록을 먼저 진행해주세요.
        </div>
      )}
    </>
  );
});

export default WB41IssueDocumentSub1;
