import "../GlobalStyle.css";
import "../PCStyle.css";
import "../TabletStyle.css";
import "../MobileStyle.css";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { observer } from "mobx-react";
import axios from "axios";
import { AddDotYYYYMMDD, AddHyphenTelNumber } from "../Utils/ConvertNumberFormat";
import { HideMemberDataKeys } from "../Utils/KeyArray";
import { LoadComapanyData } from "../Utils/LoadCompanyData";
import Loading from "../Components/Loading";
import DetailTitle from "../Components/DetailTitle";
import DetailList from "../Components/DetailList";
import PDFInputField from "../Components/PDFInputField";

const WB92ReferEmployeeDetail = observer(() => {
  const location = useLocation();
  const navigate = useNavigate();
  const itemData = location.state.item;
  const from = location.state.from;
  const [isLoading, setIsLoading] = useState(false);
  const [isExpandMore, setIsExpandMore] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const userData = LoadComapanyData("userData");

  const onClickModify = (certificateData) => {
    navigate(`/referEmployeeModify/${certificateData.ID}`, {
      state: {
        item: certificateData,
      },
    });
  };

  const onClickDelete = () => {
    setIsDelete(true);
  };

  const onClickDeleteConfirm = async () => {
    setIsDelete(false);
    setIsLoading(true);

    try {
      const endPoint = "https://dml1u35aa3.execute-api.ap-northeast-2.amazonaws.com/product/employee";
      const response = await axios.delete(endPoint, {
        data: { email: userData.companyEmail, name: itemData.Name, telNumber: itemData.TelNumber },
      });

      if (response.data.status === "삭제 성공") {
        alert("직원 정보 삭제에 성공했습니다.");
        setIsLoading(false);

        navigate("/referEmployeeList");
      } else {
        alert("직원 정보 저장을 실패하였습니다. 다시 시도해주세요.");
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error : ", error);
      alert("직원 정보 저장 통신을 실패하였습니다. 다시 시도해주세요.");
      setIsLoading(false);
    }
  };

  const onClickClose = () => {
    setIsDelete(false);
  };

  const secondBodyText = (itemData?.Job || [])
    .slice(1, 5)
    .map((job) => job?.Job)
    .filter((job) => job)
    .join("\n");

  return (
    <>
      {isLoading ? <Loading /> : null}
      {isDelete ? (
        <>
          <div className="Signin_CompleteModal_BG">
            <div className="Issue_Seal_Modal_CT">
              <div className="Issue_Error_BG">
                <div className="Issue_Error_Text_CT">
                  <div className="EmployeeDetail_Delete_Copy">
                    <span style={{ fontWeight: "600" }}>{itemData.Name}</span> 님의 정보를 삭제하시면
                    <br />
                    <span style={{ fontWeight: "600" }}>{itemData.Name}</span> 님의 <span style={{ fontWeight: "600" }}>경력 정보</span> 및{" "}
                    <span style={{ fontWeight: "600" }}>발급 문서</span>
                    가 <br />
                    삭제되며 복구할 수 없습니다.
                    <br /> <br />
                    삭제하시겠습니까?
                  </div>
                </div>
                <div className="Email_RightArea_Buttons_CT">
                  <button className="Email_Button" style={{ marginTop: "0px" }} onClick={onClickClose}>
                    <div
                      className="Email_Left_Button_BG"
                      style={{
                        borderColor: "rgb(231, 239, 255)",
                        backgroundColor: "rgb(231, 239, 255)",
                        color: "rgb(55, 105, 252)",
                      }}
                    >
                      취소
                    </div>
                  </button>
                  <button className="Email_Button" style={{ marginTop: "0px" }} onClick={onClickDeleteConfirm}>
                    <div
                      className="Email_Right_Button_BG"
                      style={{
                        borderColor: "rgb(255, 233, 233)",
                        backgroundColor: "rgb(255, 233, 233)",
                        color: "rgb(216, 44, 44)",
                      }}
                    >
                      확인
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}

      <div className="FlexContent_Other_V">
        <DetailTitle title={`${itemData.Name} 님의 상세 정보`} onClick={from === "Modify" ? () => navigate("/referEmployeeList") : null} />
        <div className="AllContent_Default_C" style={{ alignItems: "center" }}>
          {itemData && (
            <>
              <div className="Detail_Title_BG">
                <div className="Detail_Title_Contents">
                  <div className="Detail_Title_LeftContents"></div>
                  <div className="Detail_Title_RightContents">
                    <button
                      type="button"
                      className="TitleBG_Button"
                      onClick={() => {
                        onClickModify(itemData);
                      }}
                    >
                      수정
                    </button>
                    <button
                      type="button"
                      className="TitleBG_Delete_Button"
                      onClick={() => {
                        onClickDelete();
                      }}
                    >
                      삭제
                    </button>
                  </div>
                </div>
              </div>
              <div className="DetailTitle_Line" style={{ marginTop: "0px" }} />
              <div className="ResignerDetail_Contents_CT">
                <div className="CertificateCard_BG_ResignerDetail">
                  {itemData.Name && <DetailList label={"이름"} bodyText={itemData.Name} />}
                  {itemData.Gender && <DetailList label={"성별"} bodyText={itemData.Gender} />}
                  {itemData.Birthday && <DetailList label={"생년월일"} bodyText={AddDotYYYYMMDD(itemData.Birthday)} />}
                  {itemData.TelNumber && <DetailList label={"전화번호"} bodyText={AddHyphenTelNumber(itemData.TelNumber)} />}
                  {itemData.Email && <DetailList label={"이메일"} bodyText={itemData.Email} />}
                  {(itemData.Address || itemData.주소) && <DetailList label={"주소"} bodyText={itemData.Address || itemData.주소} />}
                  {itemData.AcqDate && <DetailList label={"입사일"} bodyText={AddDotYYYYMMDD(itemData.AcqDate)} />}
                  {itemData.LoseDate && <DetailList label={"퇴사일"} bodyText={AddDotYYYYMMDD(itemData.LoseDate)} />}
                  {itemData.Position && <DetailList label={"포지션"} bodyText={itemData.Position} />}
                  {itemData.Department && <DetailList label={"부서"} bodyText={itemData.Department} />}
                  {itemData.JobPosition && <DetailList label={"직위/직급"} bodyText={itemData.JobPosition} />}
                  {itemData.Job && itemData.Job.length > 0 ? (
                    <>
                      {secondBodyText && (
                        <>
                          <DetailList
                            label={"직무/업무"}
                            bodyText={itemData.Job[0].Job}
                            state={"extendable"}
                            isExpandMore={isExpandMore}
                            setIsExpandMore={setIsExpandMore}
                            secondBodyText={secondBodyText}
                          />
                        </>
                      )}
                      {!secondBodyText && itemData.Job && (
                        <>
                          <DetailList label={"직무/업무"} bodyText={itemData.Job[0].Job} />
                        </>
                      )}
                    </>
                  ) : null}
                  {Object.keys(itemData)
                    .filter((key) => !HideMemberDataKeys.includes(key))
                    .map((key) => {
                      const value = itemData[key];
                      const displayValue = typeof value === "object" ? JSON.stringify(value) : value;
                      return <DetailList key={key} label={key} bodyText={displayValue} />;
                    })}
                </div>
                <div className="ResignerDetail_Vertical_Divider"></div>
                <div className="ResignerDetail_Contents_Right_CT">
                  <div className="Profile_Label_CT" style={{ marginBottom: "12px" }}>
                    <div className="Profile_Label_Copy">발급된 문서</div>
                  </div>
                  {itemData.OfferLetter && <PDFInputField name={"입사확약서"} link={itemData.OfferLetter.Url} />}
                  {itemData.ConfidentialityAgreement && <PDFInputField name={"보안유지 서약서"} link={itemData.ConfidentialityAgreement.Url} />}
                  {itemData.SalaryContract && <PDFInputField name={"연봉 계약서"} link={itemData.SalaryContract.Url} />}
                  {itemData.PrivacyConsent && <PDFInputField name={"개인정보 제공 및 수집 이용 동의서"} link={itemData.PrivacyConsent.Url} />}
                  {itemData.FulltimeContract && <PDFInputField name={"정규직 근로계약서"} link={itemData.FulltimeContract.Url} />}
                  {itemData.TemporaryContract && <PDFInputField name={"계약직(기간제) 근로계약서"} link={itemData.TemporaryContract.Url} />}
                  {itemData.ParttimeContract && <PDFInputField name={"단시간(아르바이트) 근로계약서"} link={itemData.ParttimeContract.Url} />}
                  {itemData.DailyContract && <PDFInputField name={"일용직 근로계약서"} link={itemData.DailyContract.Url} />}
                  {itemData.JuvenileContract && <PDFInputField name={"청소년(연소자) 근로계약서"} link={itemData.JuvenileContract.Url} />}
                  {itemData.ParentalConsentForm && <PDFInputField name={"친권자(후견인) 동의서"} link={itemData.ParentalConsentForm.Url} />}
                  {itemData.StockOptionContract && (
                    <PDFInputField name={"주식매수선택권 부여계약서(스톡옵션 계약서)"} link={itemData.StockOptionContract.Url} />
                  )}
                  {itemData.StockholdersAgreement && <PDFInputField name={"주주간 계약서"} link={itemData.StockholdersAgreement.Url} />}
                  {itemData.PartnershipAgreement && <PDFInputField name={"주주간 계약서 (동업계약서)"} link={itemData.PartnershipAgreement.Url} />}
                  {itemData.CareerCertificatePDF && <PDFInputField name={"경력증명서"} link={itemData.CareerCertificatePDF.Url} />}
                  {itemData.EarnedIncomeWithholdingTaxReceipt && (
                    <PDFInputField name={"근로소득 원천징수 영수증"} link={itemData.EarnedIncomeWithholdingTaxReceipt.Url} />
                  )}
                  {itemData.PayStub && <PDFInputField name={"급여명세서"} link={itemData.PayStub.Url} />}
                  {itemData.SeverancePaySettlementDetails && (
                    <PDFInputField name={"퇴직금 정산 내역서"} link={itemData.SeverancePaySettlementDetails.Url} />
                  )}
                  {!itemData.OfferLetter &&
                  !itemData.ConfidentialityAgreement &&
                  !itemData.SalaryContract &&
                  !itemData.PrivacyConsent &&
                  !itemData.FulltimeContract &&
                  !itemData.TemporaryContract &&
                  !itemData.ParttimeContract &&
                  !itemData.DailyContract &&
                  !itemData.JuvenileContract &&
                  !itemData.ParentalConsentForm &&
                  !itemData.StockOptionContract &&
                  !itemData.StockholdersAgreement &&
                  !itemData.PartnershipAgreement &&
                  !itemData.EarnedIncomeWithholdingTaxReceipt &&
                  !itemData.PayStub &&
                  !itemData.SeverancePaySettlementDetails &&
                  !itemData.CareerCertificatePDF ? (
                    <div className="Wait_NoneItem_Copy">발급된 문서가 없습니다.</div>
                  ) : null}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
});

export default WB92ReferEmployeeDetail;
